import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IEvent } from "../../interface";
import { AppState } from "../../store";
import { Article } from "../../components";
import { SigneesForm } from "./SigneesForm";
import {
  frontActionCreators,
  FrontThunkDispatch,
} from "../../store/Front/front.actions";

interface ISigneesProps {
  ingress: string;
  content: string;
  title: string;
}

export const TicketSignees: FC<ISigneesProps> = ({
  ingress,
  content,
  title,
}) => {
  return (
    <div>
      <Article title={title} preamble={ingress}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
        <SigneesForm />
      </Article>
    </div>
  );
};
