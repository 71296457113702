import { CoreActionTypes, coreActionTypes, ICoreState } from "./core.types";
import axios from "axios";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { Occurrence } from "../../interface";

type MyExtraArg = undefined;
type ThunkResult<R> = ThunkAction<R, ICoreState, MyExtraArg, CoreActionTypes>;
export type CoreThunkDispatch = ThunkDispatch<
  ICoreState,
  MyExtraArg,
  CoreActionTypes
>;

export const coreActionCreators = {
  setCoreValue: (key: any[], value: any): ThunkResult<void> => (dispatch) => {
    dispatch({
      type: coreActionTypes.CORE_SET_VALUE,
      payload: value,
      key: key,
    });
  },
  requestLoadEvents: (): ThunkResult<Promise<void>> => {
    return async (dispatch) => {
      dispatch({ type: coreActionTypes.SET_APP_STATE, appState: "loading" });
      axios
        .get(`${API_BASE_URL}/events`)
        .then((response) => {
          console.log(response);
          dispatch({
            type: coreActionTypes.SET_LOADED_EVENTS,
            payload: response.data,
            appState: "loadingSucceeded",
          });
        })
        .catch((err) => {
          dispatch({
            type: coreActionTypes.SET_APP_STATE,
            appState: "loadingFailed",
          });
        });
    };
  },
  loadCurrentEventSteps: (eventId: string): ThunkResult<Promise<void>> => {
    return async (dispatch) => {
      dispatch({ type: coreActionTypes.SET_APP_STATE, appState: "loading" });
      axios
        .get(`${API_BASE_URL}/events/${eventId}/steps`)
        .then((res) => {
          dispatch({
            type: coreActionTypes.SET_CURRENT_EVENT_STEPS,
            payload: res.data,
            appState: "loadingSucceeded",
          });
        })
        .catch((err) => {
          dispatch({
            type: coreActionTypes.SET_APP_STATE,
            appState: "loadingFailed",
          });
        });
    };
  },
  setCurrentEvent: (eventId: string): ThunkResult<Promise<void>> => {
    return async (dispatch) => {
      dispatch({ type: coreActionTypes.SET_APP_STATE, appState: "loading" });
      axios
        .get(`${API_BASE_URL}/events/${eventId}`)
        .then((res) => {
          dispatch({
            type: coreActionTypes.SET_CURRENT_EVENT,
            payload: res.data,
            appState: "loadingSucceeded",
          });
        })
        .catch((err) => {
          dispatch({
            type: coreActionTypes.SET_APP_STATE,
            appState: "loadingFailed",
          });
        });
    };
  },
  setCurrentEventWithSlug: (slug: string): ThunkResult<Promise<void>> => {
    return async (dispatch) => {
      dispatch({ type: coreActionTypes.SET_APP_STATE, appState: "loading" });
      axios
        .get(`${API_BASE_URL}/filter?slug=${slug}`)
        .then((res) => {
          dispatch({
            type: coreActionTypes.SET_CURRENT_EVENT,
            payload: res.data,
            appState: "loadingSucceeded",
          });
        })
        .catch((err) => {
          dispatch({
            type: coreActionTypes.SET_APP_STATE,
            appState: "loadingFailed",
          });
        });
    };
  },
  setCurrentEventOccurrences: (eventId: string): ThunkResult<Promise<void>> => {
    return async (dispatch) => {
      dispatch({ type: coreActionTypes.SET_APP_STATE, appState: "loading" });
      axios
        .get(`${API_BASE_URL}/events/${eventId}/occurrences`)
        .then((res) => {
          dispatch({
            type: coreActionTypes.SET_CURRENT_EVENT_OCCURRENCES,
            payload: res.data,
            appState: "loadingSucceeded",
          });
        })
        .catch((err) => {
          dispatch({
            type: coreActionTypes.SET_APP_STATE,
            appState: "loadingFailed",
          });
        });
    };
  },
  setCurrentEventSubEvents: (eventId: string): ThunkResult<Promise<void>> => {
    return async (dispatch) => {
      dispatch({ type: coreActionTypes.SET_APP_STATE, appState: "loading" });
      axios
        .get(`${API_BASE_URL}/events/${eventId}/sub`)
        .then((res) => {
          dispatch({
            type: coreActionTypes.SET_CURRENT_EVENT_SUBEVENTS,
            payload: res.data,
            appState: "loadingSucceeded",
          });
        })
        .catch((err) => {
          dispatch({
            type: coreActionTypes.SET_APP_STATE,
            appState: "loadingFailed",
          });
        });
    };
  },
  setAllOccurrencesForSubEvent: (
    eventId: string
  ): ThunkResult<Promise<void>> => {
    return async (dispatch) => {
      dispatch({ type: coreActionTypes.SET_APP_STATE, appState: "loading" });
      axios
        .get(`${API_BASE_URL}/events/${eventId}/sub`)
        .then((res) => {
          let eventIds: string[] = res.data.map((e: Occurrence) => e.id);
          let promises: any = [];
          eventIds.forEach((e) => {
            promises.push(axios.get(`${API_BASE_URL}/events/${e}/occurrences`));
          });

          Promise.all(promises)
            .then(function (values: any) {
              let occurrences: Occurrence[] = [];
              values.forEach((v: any) => {
                v.data.forEach((d: any) => {
                  occurrences.push(d);
                });
              });

              dispatch({
                type: coreActionTypes.SET_CURRENT_EVENT_SUBEVENTS_OCCURRENCES,
                payload: occurrences,
                appState: "loadingSucceeded",
              });
            })
            .catch((err) => {
              dispatch({
                type: coreActionTypes.SET_APP_STATE,
                appState: "loadingFailed",
              });
            });
        })
        .catch((err) => {
          dispatch({
            type: coreActionTypes.SET_APP_STATE,
            appState: "loadingFailed",
          });
        });
    };
  },
  setApplicantDetails: (
    eventType: string,
    eventId: string,
    occurrenceId: string,
    applicantId: string
  ): ThunkResult<Promise<void>> => {
    return async (dispatch) => {
      dispatch({ type: coreActionTypes.SET_APP_STATE, appState: "loading" });
      axios
        .get(
          `${API_BASE_URL}/events/${eventType}/${eventId}/${occurrenceId}/applications/${applicantId}/details`
        )
        .then((res) => {
          dispatch({
            type: coreActionTypes.SET_APPLICANT_DETAILS,
            payload: res.data,
            appState: "loadingSucceeded",
          });
        })
        .catch((err) => {
          dispatch({
            type: coreActionTypes.SET_APP_STATE,
            appState: "loadingFailed",
          });
        });
    };
  },
};
