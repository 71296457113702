import React, { FC, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  coreActionCreators,
  CoreThunkDispatch,
} from "../store/Core/core.actions";
import { AppState } from "../store";
import { ApplicantDetails, ApplicantStatus } from "../interface";
import { Article } from "../components";

import "./Confirm.scss";
import axios from "axios";

interface MatchParams {
  eventType: string;
  eventId: string;
  occurrenceId: string;
  applicantId: string;
}

const Confirm: FC<RouteComponentProps<MatchParams>> = ({ match }) => {
  const coreDispatch = useDispatch<CoreThunkDispatch>();

  const appState: string = useSelector<AppState, string>((s) => {
    return s.core.appState;
  });

  const applicantDetails: ApplicantDetails = useSelector<
    AppState,
    ApplicantDetails
  >((s) => {
    return s.core.applicantDetails;
  });

  let [appStatus, setAppStatus] = useState<ApplicantStatus>();

  let [buttonLoadingAccept, setButtonLoadingAccept] = useState<string>("");
  let [buttonLoadingReject, setButtonLoadingReject] = useState<string>("");
  let [buttonLoading, setButtonLoading] = useState<boolean>(false);

  useEffect(() => {
    coreDispatch(
      coreActionCreators.setApplicantDetails(
        match.params.eventType,
        match.params.eventId,
        match.params.occurrenceId,
        match.params.applicantId
      )
    )
  }, []);

  useEffect(() => {
    setAppStatus(applicantDetails.application.status);
  }, [applicantDetails]);

  function updateStatus(status: number, accept: boolean) {
    setButtonLoading(true);
    if (accept) {
      setButtonLoadingAccept("button--loading");
    } else {
      setButtonLoadingReject("button--loading");
    }

    axios
      .post(
        `${API_BASE_URL}/registration/${match.params.eventType}/${match.params.eventId}/${match.params.occurrenceId}/${match.params.applicantId}/confirm`,
        accept, { headers: { 'Content-Type': 'application/json' }}
      )
      .then((response) => {
          coreDispatch(
            coreActionCreators.setApplicantDetails(
              match.params.eventType,
              match.params.eventId,
              match.params.occurrenceId,
              match.params.applicantId
            )
          )
        setButtonLoading(false);
        setButtonLoadingAccept("");
        setButtonLoadingReject("");
      }).catch((response) => {
        alert("Feil: " + response.data);
      });
  }
  
  function dateFormat(date: Date) {
    if(date === undefined) {
      date = new Date();
    } else {
      date = new Date(date);
    }
    date.setDate(date.getDate() + 7);

    var options: any = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };

    let dateFormat = date.toLocaleDateString("nb-NO", options);
    return dateFormat;
  }

  switch (applicantDetails.application.status) {
    case 5:
      return (
        <>
          {appState !== "loadingSucceeded" && (
            <lk-spinner text="Laster inn siden..."></lk-spinner>
          )}
          {applicantDetails.event.type === 4 ? (
            <Article
              title={`Tilbud om plass på ${applicantDetails.event.name}`}
              preamble={`Vi har gleden av å tilby ${applicantDetails.application.firstName} ${applicantDetails.application.lastName} plass på ${applicantDetails.sub.name} i ${applicantDetails.occurrence.name}.`}
            >
              <p>{`Frist for å bekrefte om dere takker ja eller nei til plassen er ${dateFormat(applicantDetails.application.lastSentEmail)}. Hører vi ikke fra dere innen fristen går plassen automatisk videre til en annen.`}</p>
              <hr></hr>
              <div className="buttonGroup">
                <button
                  className={
                    "button button--primary confirmButton " +
                    buttonLoadingAccept
                  }
                  onClick={() => updateStatus(1, true)}
                  disabled={buttonLoading}
                >
                  <span className="button__text">
                    Ja takk, vi bekrefter at vi tar imot plassen
                  </span>
                </button>
                <button
                  className={
                    "button button--secondary confirmButton " +
                    buttonLoadingReject
                  }
                  onClick={() => updateStatus(2, false)}
                  disabled={buttonLoading}
                >
                  <span className="button__text">
                    Nei takk, vi ønsker ikke å ta imot plassen
                  </span>
                </button>
              </div>
            </Article>
          ) : (
            <Article
              title={`Tilbud om plass på ${applicantDetails.event.name}`}
              preamble={`Vi har gleden av å tilby ${applicantDetails.application.firstName} ${applicantDetails.application.lastName} plass på ${applicantDetails.event.name} i ${applicantDetails.occurrence.name}.`}
            >
              <p>{`Frist for å bekrefte om dere takker ja eller nei til plassen er ${dateFormat(applicantDetails.application.lastSentEmail)}. Hører vi ikke fra dere innen fristen går plassen automatisk videre til en annen.`}</p>
              <hr></hr>
              <div className="buttonGroup">
                <button
                  className={
                    "button button--primary confirmButton " +
                    buttonLoadingAccept
                  }
                  onClick={() => updateStatus(1, true)}
                  disabled={buttonLoading}
                >
                  <span className="button__text">
                    Ja takk, vi bekrefter at vi tar imot plassen
                  </span>
                </button>
                <button
                  className={
                    "button button--secondary confirmButton " +
                    buttonLoadingReject
                  }
                  onClick={() => updateStatus(2, false)}
                  disabled={buttonLoading}
                >
                  <span className="button__text">
                    Nei takk, vi ønsker ikke å ta imot plassen
                  </span>
                </button>
              </div>
            </Article>
          )}
        </>
      );
    case 6:
      return (
        <>
          {appState !== "loadingSucceeded" && (
            <lk-spinner text="Laster inn siden..."></lk-spinner>
          )}
          {applicantDetails.event.type === 4 ? (
            <Article
              title={`Tilbud om venteliste på ${applicantDetails.event.name}`}
              preamble={`Det var dessverre flere søkere enn plasser til ${applicantDetails.event.name}, men vi ønsker å tilby ${applicantDetails.application.firstName} ${applicantDetails.application.lastName} plass på venteliste.`}
            >
              <p>{`Frist for å bekrefte om dere takker ja eller nei til venteliste-plassen er ${dateFormat(applicantDetails.application.lastSentEmail)}. Hører vi ikke fra dere innen fristen slettes søknaden automatisk fra ${applicantDetails.event.name}.`}</p>
              <hr></hr>
              <div className="buttonGroup">
                <button
                  className={
                    "button button--primary confirmButton " +
                    buttonLoadingAccept
                  }
                  onClick={() => updateStatus(1, true)}
                  disabled={buttonLoading}
                >
                  <span className="button__text">
                    Ja takk, vi bekrefter at vi tar imot plass på venteliste
                  </span>
                </button>
                <button
                  className={
                    "button button--secondary confirmButton " +
                    buttonLoadingReject
                  }
                  onClick={() => updateStatus(2, false)}
                  disabled={buttonLoading}
                >
                  <span className="button__text">
                  Nei takk, vi ønsker ikke å ta imot plassen på venteliste og søknaden slettes
                  </span>
                </button>
              </div>
            </Article>
          ) : (
            <Article
              title={`Tilbud om venteliste på ${applicantDetails.event.name}`}
              preamble={`Det var dessverre flere søkere enn plasser til ${applicantDetails.event.name}, men vi ønsker å tilby ${applicantDetails.application.firstName} ${applicantDetails.application.lastName} plass på venteliste.`}
            >
              <p>{`Frist for å bekrefte om dere takker ja eller nei til venteliste-plassen er ${dateFormat(applicantDetails.application.lastSentEmail)}. Hører vi ikke fra dere innen fristen slettes søknaden automatisk fra ${applicantDetails.event.name}.`}</p>
              <hr></hr>
              <div className="buttonGroup">
                <button
                  className={
                    "button button--primary confirmButton " +
                    buttonLoadingAccept
                  }
                  onClick={() => updateStatus(1, true)}
                  disabled={buttonLoading}
                >
                  <span className="button__text">
                    Ja takk, vi bekrefter at vi tar imot plass på venteliste
                  </span>
                </button>
                <button
                  className={
                    "button button--secondary confirmButton " +
                    buttonLoadingReject
                  }
                  onClick={() => updateStatus(2, false)}
                  disabled={buttonLoading}
                >
                  <span className="button__text">
                  Nei takk, vi ønsker ikke å ta imot plassen på venteliste og søknaden slettes
                  </span>
                </button>
              </div>
            </Article>
          )}
        </>
      );
    case 7:
      return (
        <>
          {appState !== "loadingSucceeded" && (
            <lk-spinner text="Laster inn siden..."></lk-spinner>
          )}
          {applicantDetails.event.type === 4 ? (
            <Article
              title={`Velkommen til ${applicantDetails.event.name}, ${applicantDetails.application.firstName} ${applicantDetails.application.lastName}`}
              preamble={`Dere har nå bekreftet at ${applicantDetails.application.firstName} ${applicantDetails.application.lastName} skal delta på ${applicantDetails.sub.name} i ${applicantDetails.occurrence.name}.`}
            >
              <p
                style={{ whiteSpace: "pre-line" }}
              >{`Mer informasjon om kurset vil bli sendt ut før oppstart. \n\nPåmeldingen er bindende. Skulle det oppstå noe som gjør at påmeldte ikke får deltatt, er det viktig at vi får beskjed så snart som mulig.\n\nHar dere spørsmål, ta gjerne kontakt med oss på sommerskolen@lillestrom.kommune.no. \n\nVi sees!`}</p>
            </Article>
          ) : (
            <Article
              title={`Velkommen på ${applicantDetails.event.name}, ${applicantDetails.application.firstName} ${applicantDetails.application.lastName}`}
              preamble={`Dere har nå bekreftet at dere tar imot tilbudet om at ${applicantDetails.application.firstName} ${applicantDetails.application.lastName} kan delta på ${applicantDetails.event.name} i ${applicantDetails.occurrence.name}.`}
            >
              <p
                style={{ whiteSpace: "pre-line" }}
              >{`Dere får mer informasjon når arrangementet starter.\n\nPåmeldingen er bindende. Skulle det oppstå noe som gjør at påmeldte ikke får deltatt, er det viktig at vi får beskjed så snart som mulig.\n\nLurer dere på noe, ta gjerne kontakt med oss. Denne bekreftelsen er også sendt dere som epost.\n\nVi sees!`}</p>
            </Article>
          )}
        </>
      );
    case 8:
      return (
        <>
          {appState !== "loadingSucceeded" && (
            <lk-spinner text="Laster inn siden..."></lk-spinner>
          )}
          {applicantDetails.event.type === 4 ? (
            <Article
              title={`Takket nei til ${applicantDetails.event.name}`}
              preamble={`Dere har nå takket nei til plass på ${applicantDetails.sub.name} i ${applicantDetails.occurrence.name} for ${applicantDetails.application.firstName} ${applicantDetails.application.lastName}.`}
            >
              <p
                style={{ whiteSpace: "pre-line" }}
              >{`Søknaden til ${applicantDetails.event.name} er nå slettet. \n\nHar du spørsmål, ta gjerne kontakt med oss på sommerskolen@lillestrom.kommune.no. \n\nVi sees ved en senere anledning!`}</p>
            </Article>
          ) : (
            <Article
              title={`Takket nei på ${applicantDetails.event.name}`}
              preamble={`Dere har nå bekreftet at dere ønsker ikke tilbudet om at ${applicantDetails.application.firstName} ${applicantDetails.application.lastName} kan delta på ${applicantDetails.event.name} i ${applicantDetails.occurrence.name}.`}
            >
              <p
                style={{ whiteSpace: "pre-line" }}
              >{`Du har takket nei for å delta på arrangementet. \n\nVi sees på en senere anledning!`}</p>
            </Article>
          )}
        </>
      );
    case 2:
      return (
        <>
          {appState !== "loadingSucceeded" && (
            <lk-spinner text="Laster inn siden..."></lk-spinner>
          )}
          {applicantDetails.event.type === 4 ? (
            <Article
              title={`Venteliste ${applicantDetails.event.name}, ${applicantDetails.application.firstName} ${applicantDetails.application.lastName}`}
              preamble={`Dere har nå bekreftet at dere tar imot tilbudet om at ${applicantDetails.application.firstName} ${applicantDetails.application.lastName} skal stå på venteliste til ${applicantDetails.event.name}.`}
            >
              <p
                style={{ whiteSpace: "pre-line" }}
              >{`Restplasser vil bli fordelt fortløpende frem mot kursstart. Skulle dere blir trukket ut til en plass vil dere bli kontaktet på e-post. \n\nHar du spørsmål, ta gjerne kontakt med oss på sommerskolen@lillestrom.kommune.no.`}</p>
            </Article>
          ) : (
            <Article
              title={`Venteliste ${applicantDetails.event.name}, ${applicantDetails.application.firstName} ${applicantDetails.application.lastName}`}
              preamble={`Dere har nå bekreftet at dere tar imot tilbudet om at ${applicantDetails.application.firstName} ${applicantDetails.application.lastName} er satt på venteliste på ${applicantDetails.event.name} i ${applicantDetails.occurrence.name}.`}
            >
              <p
                style={{ whiteSpace: "pre-line" }}
              >{`Dere får mer informasjon om dere får tilbud om påmelding eller ikke.\n\nPåmeldingen er bindende. Skulle det oppstå noe som gjør at påmeldte ikke får deltatt, er det viktig at vi får beskjed så snart som mulig.\n\nLurer dere på noe, ta gjerne kontakt med oss. Denne bekreftelsen er også sendt dere som epost.\n\nVi sees!`}</p>
            </Article>
          )}
        </>
      );
    case 10:
      return (
        <>
          {appState !== "loadingSucceeded" && (
            <lk-spinner text="Laster inn siden..."></lk-spinner>
          )}
          {applicantDetails.event.type === 4 ? (
            <Article
              title={`Takket nei til ${applicantDetails.event.name}`}
              preamble={`Dere har nå takket nei til at ${applicantDetails.application.firstName} ${applicantDetails.application.lastName} skal stå på ventelisten til ${applicantDetails.event.name}.`}
            >
              <p
                style={{ whiteSpace: "pre-line" }}
              >{`Søknaden til ${applicantDetails.event.name} er nå slettet. \n\nHar du spørsmål, ta gjerne kontakt med oss på sommerskolen@lillestrom.kommune.no. \n\nVi sees ved en senere anledning!`}</p>
            </Article>
          ) : (
            <Article
              title={`Takket nei på ${applicantDetails.event.name}`}
              preamble={`Dere har nå bekreftet at dere ønsker ikke tilbudet om at ${applicantDetails.application.firstName} ${applicantDetails.application.lastName} kan bli med på ventelisten til ${applicantDetails.event.name} i ${applicantDetails.occurrence.name}.`}
            >
              <p
                style={{ whiteSpace: "pre-line" }}
              >{`Du har takket nei for å delta på arrangementet. \n\nVi sees på en senere anledning!`}</p>
            </Article>
          )}
        </>
      );
    default:
      return (
        <>
          {appState !== "loadingSucceeded" && (
            <lk-spinner text="Laster inn siden..."></lk-spinner>
          )}
          <Article
            title={`Lenken finnes ikke.`}
            preamble={`Lenken er ikke eksisterende/utgått`}
          >
            <p>{`Denne lenken er enten utgått eller så eksisterer den ikke. ${appStatus} ` }</p>
          </Article>
        </>
      );
  }
};

export default Confirm;
