import { Signee } from "../../interface";

export default function checkFormTicketSignees(signees: Signee[]) {

  const regexPhone = new RegExp(/^((0047)?|(\+47)?)[4|9]\d{7}$/);
  const regexEmail = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  const regexZip = new RegExp(/^[0-9]{4}$/);

  let bool: Boolean = true;
  signees.forEach((s: any) => {
    Object.keys(s).map(function (v) {
      if (s[v] === "" && v !== "notes") {
        bool = false;
      }
      if(v === "telephone" && !regexPhone.test(s[v])) {
        bool = false;
      }

      if(v === "email" && !regexEmail.test(s[v])) {
        bool = false;
      }

      if(v === "zip" && !regexZip.test(s[v])) {
        bool = false;
      }
    });
  });
  return bool;
}
