import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Article } from "../../components/Article";
import { EventList } from "../../components/EventList";
import {
  IEvent,
  EventSteps,
  SubEvent,
  Occurrence,
  WizardStep,
} from "../../interface";
import { AppState } from "../../store";
import { CourseAbout } from "./CourseAbout";
import { Confirmation } from "../shared/Confirmation";
import { Parents } from "../shared/Parents";
import { CourseConfirm } from "./CourseConfirm";

interface IWizardProps {
  event: IEvent;
  subEvents: SubEvent[];
  occurrences: Occurrence[];
  steps: EventSteps;
}

export const WizardCourse: FC<IWizardProps> = ({
  event,
  subEvents,
  occurrences,
  steps,
}) => {
  const wizardStep = useSelector<AppState, number>((s) => {
    return s.front.wizardStep;
  });

  const selectedOccurrences = useSelector<AppState, any>((s) => {
    return s.front.storeOccurrences;
  });

  return (
    <div>
      {wizardStep === WizardStep.ChooseEventOccurrence && (
        <Article title={event.name} preamble={event.ingress}>
          {event.photo !== null && event.photo !== "" && (
            <img src={event.photo} style={{ marginBottom: "1rem" }} />
          )}
          <div dangerouslySetInnerHTML={{ __html: event.about }} />

          <h2>
            {event.title}
          </h2>

          <div style={{ marginBottom: "1em" }}>
            <p>{event.description}</p>
          </div>

          <EventList
            event={event}
            subEvents={subEvents}
            occurrences={occurrences}
          />
        </Article>
      )}
      {wizardStep === WizardStep.About && (
        <CourseAbout
          title={event.name}
          content={event.about}
          ingress={event.ingress}
          applicantTitle={steps.applicant.title}
          applicantDescription={steps.applicant.description}
          applicantNotice={steps.applicant.notice}
          occurrences={selectedOccurrences}
        />
      )}
      {wizardStep === WizardStep.Parents && (
        <Parents
          title={event.name}
          content={event.about}
          ingress={event.ingress}
        />
      )}
      {wizardStep === WizardStep.Confirm && (
        <CourseConfirm
          title={event.name}
          content={event.about}
          ingress={event.ingress}
        />
      )}
      {wizardStep === WizardStep.Confirmation && (
        <Confirmation
          title={steps.confirmation.title}
          ingress={steps.confirmation.ingress}
          content={steps.confirmation.content}
        />
      )}
    </div>
  );
};
