import { Parent } from "../../interface";

export default function checkFormStudentParents(parents: Parent[]) {
  const regexPhone = new RegExp(/^((0047)?|(\+47)?)[4|9]\d{7}$/);
  const regexEmail = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  let bool: Boolean = true;
  parents.forEach((p: Parent) => {
    if (
      !regexEmail.test(p.email) ||
      p.firstName === "" ||
      p.lastName === "" ||
      !regexPhone.test(p.telephone)
    ) {
      bool = false;
    }
  });
  return bool;
}
