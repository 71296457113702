import React, { FC } from "react";
import { IOccurrenceProps } from "./IOccurenceList";
import {
  frontActionCreators,
  FrontThunkDispatch,
} from "../../store/Front/front.actions";
import "./OccurrenceList.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { Occurrence, WizardStep } from "../../interface";
const OccurrenceList: FC<IOccurrenceProps> = ({ occurrences, event }) => {
  const frontDispatch = useDispatch<FrontThunkDispatch>();

  var color = -1;

  function randomColor() {
    var colors = ["yellow", "green", "pink"];
    color++;
    if (color === 3) {
      color = 0;
    }
    return colors[color];
  }

  function waitingList(occurrence: Occurrence) {
    return (occurrence.capacity - occurrence.booked) <= 0 && event.hasWaitingList;
  }

  function available(occurrence: Occurrence) {
    return occurrence.capacity - occurrence.booked;
  }

  function dateFormat(date: Date) {
    console.log(date);
    date = new Date(date);
    var options: any = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    let dateFormat = date.toLocaleDateString("nb-NO", options);
    return dateFormat.charAt(0).toUpperCase() + dateFormat.slice(1);
  }

  function goToOccurrence(occurrence: Occurrence) {
    let occurrences = [];
    occurrences.push(occurrence);
    frontDispatch(
      frontActionCreators.setFrontValue(["storeOccurrences"], occurrences)
    );

    frontDispatch(
      frontActionCreators.setFrontValue(["wizardStep"], WizardStep.About)
    );
  }

  return (
    <ul className="article-list grid">
      {occurrences
        .sort(
          (a: Occurrence, b: Occurrence) =>
            new Date(a.date).getTime() - new Date(b.date).getTime()
        )
        .map((o) => {
          return (
            <>
              { (available(o) > 0 || waitingList(o))  && (
                <li
                  key={o.id}
                  className="span-whole"
                  style={{ marginBottom: "2rem" }}
                >
                  <div
                    className={"article-teaser--highlighted " + randomColor()}
                  >
                    <h3 className="occurrenceTitle">{dateFormat(o.date)}</h3>
                    <h4>
                      Antall ledige plasser: { available(o)}/{o.capacity}{" "}
                      { waitingList(o) && <i> - venteliste</i>  }
                    </h4>
                    <button
                      className="button button--secondary custom-button"
                      onClick={() => goToOccurrence(o)}
                    >
                      <span className="button__text">Meld deg på</span>
                    </button>
                  </div>
                </li>
              )}
            </>
          );
        })}
    </ul>
  );
};

export default OccurrenceList;
