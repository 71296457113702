import { Reducer, Action } from "redux";
import {
  ICoreState,
  CoreActionTypes,
  coreActionTypes,
  setCoreValue,
  setLoadedEvents,
  setAppState,
  setCurrentEvent,
  setCurrentEventSteps,
  setCurrentEventOccurrences,
  setCurrentEventSubEvents,
  setCurrentSubEventsOccurrences,
  setApplicantDetails,
} from "./core.types";
import { ApplicantType, EventType } from "../../interface";

const initialState: ICoreState = {
  events: [],
  appState: "loading",
  orgId: "",
  selectedEvent: {
    id: "",
    name: "",
    groupFilters: false,
    slug: "",
    type: EventType.Simple,
    applicantType: ApplicantType.Basic,
    registrationFields: [""],
    ingress: "",
    about: "",
    closed: false,
    automaticApproval: false,
    hasSubEvents: true,
    minimumOccurrencesRegistration: 1,
    maximumOccurrencesRegistration: 3,
    title: "",
    description: "",
    filters: [],
    registrationFrom: new Date(),
    registrationTo: new Date(),
    photo: "",
    hasWaitingList: false,
    dateView: false,
    shownOccurrences: 0,
    fullyBookedMessage: "",
    controlField: "",
    notesTitle: "",
  },
  selectedEventSteps: {
    applicant: {
      title: "",
      description: "",
      notice: "",
    },
    summary: {
      title: "",
      description: "",
      action: "",
    },
    confirmation: {
      title: "",
      ingress: "",
      content: "",
    },
    tickets: {
      maximumTicketsPerApplication: 10,
      pricingGroups: {},
      paymentType: 0,
      paymentNotRequired: false,
      applicantsFormTitle: "",
      pricingGroupsTitle: "",
      contactsRegistrationFields: []
    },
    parents: {
      max: 0,
      terms: "",
      description: "",
      addToButtonText: "",
      formOverview: "",
      formConfirmation: "",
      title: "",
    },
    consent: {
      consentType: "",
      title: "",
    },
    finish: {
      title: "",
      ingress: "",
      body: "",
    },
  },
  occurrences: [],
  SubEvents: [],
  subEventOccurrences: [],
  applicantDetails: {
    application: {
      firstName: "",
      lastName: "",
      email: "",
      telephone: "",
      status: 0,
      ssn: "",
      lastSentEmail: new Date(),
    },
    occurrence: {
      parentEventID: "",
      date: new Date(),
      capacity: 0,
      booked: 0,
      id: "",
      name: "",
      closed: false,
      isInternal: false,
      hideRegisteredAmount: false,
    },
    event: {
      id: "",
      name: "",
      slug: "",
      type: EventType.Simple,
      groupFilters: false,
      applicantType: ApplicantType.Basic,
      registrationFields: [""],
      ingress: "",
      about: "",
      closed: false,
      automaticApproval: false,
      hasSubEvents: true,
      minimumOccurrencesRegistration: 1,
      maximumOccurrencesRegistration: 3,
      title: "",
      description: "",
      filters: [],
      registrationFrom: new Date(),
      registrationTo: new Date(),
      photo: "",
      hasWaitingList: false,
      dateView: false,
      shownOccurrences: 0,
      fullyBookedMessage: "",
      controlField: "",
      notesTitle: ""
    },
    sub: {
      id: "",
      name: "",
      about: "",
      order: 1,
      capacity: 0,
      type: 3,
      parentEventID: "",
      filters: [],
    },
  },
};

export const reducer: Reducer<ICoreState, CoreActionTypes> = (
  state: ICoreState = initialState,
  incomingAction: Action
): ICoreState => {
  let action;

  switch (incomingAction.type) {
    case coreActionTypes.SET_LOADED_EVENTS: {
      action = incomingAction as setLoadedEvents;
      return {
        ...state,
        events: action.payload,
        appState: action.appState,
      };
    }
    case coreActionTypes.SET_APP_STATE: {
      action = incomingAction as setAppState;
      return {
        ...state,
        appState: action.payload,
      };
    }
    case coreActionTypes.CORE_SET_VALUE: {
      action = incomingAction as setCoreValue;

      const key = typeof action.key === "string" ? [action.key] : action.key;

      if (key.length > 3 || key.length < 1) {
        return state;
      }

      const getKeyValue =
        <T extends object, U extends keyof T>(obj: T) =>
        (key: U) =>
          obj[key];
      let prevState: any = getKeyValue(state)(key[0]);

      if (key.length > 1) {
        return {
          ...state,
          [key[0]]: {
            ...prevState,
            [key[1]]:
              typeof action.payload === "object" &&
              !Array.isArray(action.payload)
                ? { ...prevState[key[1]], ...action.payload }
                : action.payload,
          },
        };
      }

      return {
        ...state,
        [key[0]]:
          typeof action.payload === "object" && !Array.isArray(action.payload)
            ? { ...prevState, ...action.payload }
            : action.payload,
      };
    }
    case coreActionTypes.SET_CURRENT_EVENT: {
      action = incomingAction as setCurrentEvent;
      return {
        ...state,
        selectedEvent: action.payload,
        appState: action.appState,
      };
    }
    case coreActionTypes.SET_CURRENT_EVENT_STEPS: {
      action = incomingAction as setCurrentEventSteps;
      return {
        ...state,
        selectedEventSteps: action.payload,
        appState: action.appState,
      };
    }

    case coreActionTypes.SET_CURRENT_EVENT_OCCURRENCES: {
      action = incomingAction as setCurrentEventOccurrences;
      return {
        ...state,
        occurrences: action.payload,
        appState: action.appState,
      };
    }

    case coreActionTypes.SET_CURRENT_EVENT_SUBEVENTS: {
      action = incomingAction as setCurrentEventSubEvents;
      return {
        ...state,
        SubEvents: action.payload,
        appState: action.appState,
      };
    }

    case coreActionTypes.SET_CURRENT_EVENT_SUBEVENTS_OCCURRENCES: {
      action = incomingAction as setCurrentSubEventsOccurrences;
      return {
        ...state,
        subEventOccurrences: action.payload,
        appState: action.appState,
      };
    }

    case coreActionTypes.SET_APPLICANT_DETAILS: {
      action = incomingAction as setApplicantDetails;
      return {
        ...state,
        applicantDetails: action.payload,
        appState: action.appState,
      };
    }

    default:
      return state;
  }
};
