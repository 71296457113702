import { Occurrence, Parent, Signee, Ticket } from "../../interface";

export interface IFrontState {
  wizardStep: number;
  eventId: string;
  occurrenceId: string;
  userForm: object;
  storeOccurrences: Occurrence[];
  confirmationText: object;
  signees: Signee[];
  tickets: Ticket[];
  parents: Parent[];
  school: string;
  consentId: string;
  consentUrl: string;
  applyCode: number;
  filter: string;
  orderId: string;
  contactSignee: boolean;
  payLater: boolean;
  update: string;
  isAdmin: boolean;
  vippsFailedError: string;
}

export const frontActionTypes = {
  SET_VALUE: "SET_VALUE",
  SET_INITIAL: "SET_INITIAL",
};

export interface setValue {
  type: typeof frontActionTypes.SET_VALUE;
  payload: any;
  key: any[];
}

export type FrontActionTypes = setValue;
