import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  DeepPartial,
} from "redux";
import thunk from "redux-thunk";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { History } from "history";
import { ApplicationState, reducers } from "./";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";

export default function configureStore(
  history: History,
  initialState?: ApplicationState
) {
  const middleware = [thunk, routerMiddleware(history)];

  const appReducer = combineReducers({
    ...reducers,
    router: connectRouter(history),
  });

  // reset the state of redux store
  const rootReducer = (state: any, action: any) => {
    if (action.type === "RESET_STORE") {
      console.log("here");
      state = undefined;
      storage.removeItem("persist:root");
    }

    return appReducer(state, action);
  };

  const persistConfig = {
    key: "root",
    storage: storage,
    blacklist: ["router"],
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const enhancers = [];
  const windowIfDefined =
    typeof window === "undefined" ? null : (window as any);
  if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
  }
  /*
    return createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
    */

  let store = createStore(
    persistedReducer,
    initialState as any,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
  let persistor = persistStore(store);
  return { store, persistor };
}
