import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ApplicantType,
  EventSteps,
  IEvent,
  Parent,
  Signee,
  Ticket,
  WizardStep,
} from "../../interface";
import { AppState } from "../../store";
import { Article } from "../../components";
import changeFormText from "../shared/ChangeFormText";
import {
  frontActionCreators,
  FrontThunkDispatch,
} from "../../store/Front/front.actions";
import checkForm from "../shared/CheckForm";
import axios from "axios";
import { useCrossTabState } from "../../lib/hooks";
import { FormHelperText } from "@material-ui/core";
import ReCAPTCHA from "react-google-recaptcha";
import { Consent } from "../shared/Consent";

interface ITicketConfirmProps {
  ingress: string;
  content: string;
  title: string;
}

export const CourseConfirm: FC<ITicketConfirmProps> = ({
  ingress,
  content,
  title,
}) => {
  const frontDispatch = useDispatch<FrontThunkDispatch>();

  const event: IEvent = useSelector<AppState, IEvent>((s) => {
    return s.core.selectedEvent;
  });

  const eventId: string = useSelector<AppState, string>((s) => {
    return s.front.eventId;
  });

  const wizardStep: number = useSelector<AppState, number>((s) => {
    return s.front.wizardStep;
  });

  const storeOccurrences: any = useSelector<AppState, any>((s) => {
    return s.front.storeOccurrences;
  });

  const userForm: any = useSelector<AppState, any>((s) => {
    return s.front.userForm;
  });

  const parents: Parent[] = useSelector<AppState, Parent[]>((s) => {
    return s.front.parents;
  });

  const eventSteps: EventSteps = useSelector<AppState, EventSteps>((s) => {
    return s.core.selectedEventSteps;
  });

  const consentId: string = useSelector<AppState, string>((s) => {
    return s.front.consentId;
  });

  const consentUrl: string = useSelector<AppState, string>((s) => {
    return s.front.consentUrl;
  });

  let [error, setError] = useState<string>("");
  let [validation, setValidation] = useState<string>("");
  const [consentStatus, setConsentStatus] = useCrossTabState(
    "consentStatus",
    ""
  );
  let [buttonLoading, setButtonLoading] = useState<string>("");

  let [recaptchaEnabled, setRecaptchaEnabled] = useState<boolean>(false);
  //Recaptcha
  const recaptchaRef = React.createRef<ReCAPTCHA>();

  async function onSubmit() {
    if (recaptchaEnabled) {
      const token = await recaptchaRef.current?.executeAsync();
      submitForm(token);
    } else {
      submitForm();
    }
  }

  function submitDisabled(): boolean {
    if (validation) return true;

    return (
      buttonLoading === "button--loading" ||
      (eventSteps.consent !== null &&
        eventSteps.consent.consentType !== "" &&
        (consentStatus === "" || consentStatus === "-1"))
    );
  }

  function submitForm(token?: any) {
    let headers = {};
    if (token !== null) {
      headers = {
        headers: {
          Recaptcha: token,
        },
      };
    }
    console.log(headers);
    setButtonLoading("button--loading");
    const initialValue = {};
    const listOccurrences = storeOccurrences.reduce(
      (obj: any, item: { id: any }, index: number) => {
        return {
          ...obj,
          [item.id]: index + 1,
        };
      },
      initialValue
    );

    axios
      .post(
        `${API_BASE_URL}/registration/${eventId}/apply/course`,
        {
          values: userForm,
          occurrences: listOccurrences,
          parents: parents,
          consentId: consentId,
        },
        headers
      )
      .then((response) => {
        frontDispatch(
          frontActionCreators.setFrontValue(["applyCode"], response.data.code)
        );
        frontDispatch(
          frontActionCreators.setFrontValue(
            ["confirmationText"],
            response.data.message
          )
        );
        frontDispatch(
          frontActionCreators.setFrontValue(
            ["wizardStep"],
            WizardStep.Confirmation
          )
        );

        setButtonLoading("");
      })
      .catch((error) => {
        setButtonLoading("");
        alert(error.response.data.message);
      });
  }

  function getConsentURL() {
    let school: string = "";
    if (userForm["school"] !== undefined) {
      school = userForm["school"];
    }

    axios
      .post(`${API_BASE_URL}/consent/${event.type}/${eventId}/url`, {
        type: "student",
        firstName: userForm["firstName"],
        lastName: userForm["lastName"],
        emailAddress: userForm["email"],
        school: school,
        courseName: `${storeOccurrences[0].eventName} - ${storeOccurrences[0].name}`,
        reason: `du er foresatt for ${userForm["firstName"]} ${userForm["lastName"]}.`,
      })
      .then((response) => {
        frontDispatch(
          frontActionCreators.setFrontValue(
            ["consentUrl"],
            response.data.url + `&ReturnUrl=${window.location.href}`
          )
        );
        frontDispatch(
          frontActionCreators.setFrontValue(["consentId"], response.data.id)
        );
      });
  }
  console.log(consentUrl);
  function getRecaptcha() {
    axios.get(`${API_BASE_URL}/info/recaptcha`).then((response) => {
      setRecaptchaEnabled(response.data);
    });
  }
  useEffect(() => {
    const initialValue = {};
    const listOccurrences = storeOccurrences.reduce(
      (obj: any, item: { id: any }, index: number) => {
        return {
          ...obj,
          [item.id]: index + 1,
        };
      },
      initialValue
    );

    axios
      .post(`${API_BASE_URL}/registration/${eventId}/prevalidate/course`, {
        values: userForm,
        occurrences: listOccurrences,
        parents: parents,
        consentId: consentId,
      })
      .then((response) => {
        setButtonLoading("");
        setValidation("");
      })
      .catch((error) => {
        setButtonLoading("");
        setValidation(error.response.data.message);
      });
  }, [userForm]);

  useEffect(() => {
    if (
      consentUrl === "" &&
      eventSteps.consent !== null &&
      eventSteps.consent.consentType !== ""
    ) {
      getConsentURL();
      setError("Du må gi samtykke for å kunne melde deg på.");
    }
    getRecaptcha();
  }, []);

  console.log(consentUrl);

  useEffect(() => {
    if (consentStatus !== "" && consentStatus !== "-1") {
      setError("");
    }
  }, [consentStatus]);

  return (
    <div>
      <Article title={title} preamble={ingress}>
        <div dangerouslySetInnerHTML={{ __html: content }} />

        <div
          className="highlighted-block"
          style={{ backgroundColor: "#f0f5fc" }}
        >
          <div className="highlighted-block__content">
            <h3>{eventSteps.summary.title}</h3>
            <p>{eventSteps.summary.description}</p>
          </div>
        </div>

        <div className="grid">
          <div className="span-whole ticket">
            <div className="title">
              <h4 className="customText">Dine valgte kurs</h4>
            </div>
            <div className="content">
              {storeOccurrences.map((o: any, i: number) => {
                return (
                  <div className="contentMain">
                    <p style={{ paddingRight: "8px" }}>
                      <strong>{i + 1}.</strong>
                    </p>
                    <p>
                      {o.eventName}, {o.name.toLowerCase()}
                    </p>
                  </div>
                );
              })}
            </div>
            <div className="changeButtonDiv">
              <button
                className="button button--primary changeButton"
                onClick={() =>
                  frontDispatch(
                    frontActionCreators.setFrontValue(
                      ["wizardStep"],
                      WizardStep.ChooseEventOccurrence
                    )
                  )
                }
              >
                <span className="button__text">Endre</span>
              </button>
            </div>
          </div>

          <div className="span-whole ticket">
            <div className="title">
              {event.applicantType === ApplicantType.Student ? (
                <h4 className="customText">Elevens detaljer</h4>
              ) : (
                <h4 className="customText">Dine kontaktdetaljer</h4>
              )}
            </div>
            <div className="content">
              {Object.entries(userForm).map((s: any) => {
                return (
                  <>
                    {!(
                      s[0] === "notes" ||
                      (s[0] === "email" &&
                        event.applicantType === ApplicantType.Student) ||
                      (s[0] === "telephone" &&
                        event.applicantType === ApplicantType.Student)
                    ) && (
                      <div className="contentMain">
                        <div className="contentLeft">
                          <p>
                            <strong>
                              {
                                changeFormText(
                                  s[0],
                                  event.applicantType,
                                  wizardStep
                                ).text
                              }
                              :
                            </strong>
                          </p>
                        </div>
                        <div className="contentRight">
                          <p>{s[1]}</p>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
            <div className="changeButtonDiv">
              <button
                className="button button--primary changeButton"
                onClick={() =>
                  frontDispatch(
                    frontActionCreators.setFrontValue(
                      ["wizardStep"],
                      WizardStep.About
                    )
                  )
                }
              >
                <span className="button__text">Endre</span>
              </button>
            </div>
          </div>

          {event.applicantType === ApplicantType.Student && (
            <div className="span-whole ticket">
              <div className="title">
                <h4 className="customText">Oversikt over foresatte</h4>
              </div>
              <div className="content">
                {parents.map((s: Parent, index: number) => {
                  return (
                    <div className="spaceBottom">
                      <div className="contentMain">
                        <div className="contentLeft">
                          <p>
                            <strong>Fornavn:</strong>
                          </p>
                        </div>
                        <div className="contentRight">
                          <p>{s.firstName}</p>
                        </div>
                      </div>
                      <div className="contentMain">
                        <div className="contentLeft">
                          <p>
                            <strong>Etternavn:</strong>
                          </p>
                        </div>
                        <div className="contentRight">
                          <p>{s.lastName}</p>
                        </div>
                      </div>
                      <div className="contentMain">
                        <div className="contentLeft">
                          <p>
                            <strong>E-post:</strong>
                          </p>
                        </div>
                        <div className="contentRight">
                          <p>{s.email}</p>
                        </div>
                      </div>
                      <div className="contentMain">
                        <div className="contentLeft">
                          <p>
                            <strong>Telefonnummer:</strong>
                          </p>
                        </div>
                        <div className="contentRight">
                          <p>{s.telephone}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="changeButtonDiv">
                <button
                  className="button button--primary changeButton"
                  onClick={() =>
                    frontDispatch(
                      frontActionCreators.setFrontValue(
                        ["wizardStep"],
                        WizardStep.Parents
                      )
                    )
                  }
                >
                  <span className="button__text">Endre</span>
                </button>
              </div>
            </div>
          )}
        </div>
        <br></br>
        {!validation &&
          eventSteps.consent !== null &&
          eventSteps.consent.consentType !== "" && (
            <>
              <div>
                <Consent url={consentUrl} status={consentStatus}></Consent>
              </div>
            </>
          )}

        {validation && (
          <lk-banner banner-type="warning">
            <div slot="head">
              <p>{validation}</p>
            </div>
          </lk-banner>
        )}

        <div className="spacebetween">
          <button
            className="button button--secondary emptyButton"
            onClick={() => {
              if (event.applicantType === ApplicantType.Student) {
                frontDispatch(
                  frontActionCreators.setFrontValue(
                    ["wizardStep"],
                    WizardStep.Parents
                  )
                );
              } else {
                frontDispatch(
                  frontActionCreators.setFrontValue(
                    ["wizardStep"],
                    WizardStep.About
                  )
                );
              }
            }}
          >
            <span className="button__text">Tilbake</span>
          </button>
          <button
            className={"button button--primary " + buttonLoading}
            onClick={onSubmit}
            disabled={submitDisabled()}
          >
            <span className="button__text">{eventSteps.summary.action}</span>
          </button>
        </div>
        {recaptchaEnabled && (
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey="6LdBnqAaAAAAAGWTMICyKFK-k2Mly7iN5xe0q5et"
          ></ReCAPTCHA>
        )}
      </Article>
    </div>
  );
};
