import React, { FC, useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import {
  frontActionCreators,
  FrontThunkDispatch,
} from "../../store/Front/front.actions";
import changeFormText from "./ChangeFormText";
import "./ParentsForm.scss";
import {
  EventSteps,
  EventType,
  IEvent,
  Occurrence,
  Parent,
  WizardStep,
} from "../../interface";
import checkForm from "./CheckForm";
import checkFormStudentParents from "./CheckFormStudentParents";
import InputRequired from "./InputRequired";
import ErrorField from "./ErrorField";

interface ISingleFormProps {}

export const ParentsForm: FC<ISingleFormProps> = ({}) => {
  const frontDispatch = useDispatch<FrontThunkDispatch>();

  const event: IEvent = useSelector<AppState, IEvent>((s) => {
    return s.core.selectedEvent;
  });

  const wizardStep: number = useSelector<AppState, number>((s) => {
    return s.front.wizardStep;
  });

  const occurrences: Occurrence[] = useSelector<AppState, Occurrence[]>((s) => {
    return s.front.storeOccurrences;
  });

  const userForm: any = useSelector<AppState, any>((s) => {
    return s.front.userForm;
  });

  const storeParents: Parent[] = useSelector<AppState, Parent[]>((s) => {
    return s.front.parents;
  });

  const eventSteps: EventSteps = useSelector<AppState, EventSteps>((s) => {
    return s.core.selectedEventSteps;
  });

  const [form, setForm] = useState<any>(userForm);
  let [parents, setParents] = useState<Parent[]>(storeParents);
  let [error, setError] = useState<string>("");
  let [buttonLoading, setButtonLoading] = useState<string>("");

  function addParent() {
    const newArr: any = [...parents];
    newArr.push({ firstName: "", lastName: "", email: "", telephone: "" });
    setParents(newArr);
  }

  function updateParents(text: string, value: string, index: number) {
    const newArr: any = [...parents];
    newArr[index][text] = value;
    setParents(newArr);
  }

  function removeParent(index: number) {
    const newArr: any = [...parents];
    newArr.splice(index, 1);
    setParents(newArr);
  }

  function goToConfirm() {
    userForm["email"] = parents[0].email;
    userForm["telephone"] = parents[0].telephone;

    if(event.type === EventType.Tickets) {
      frontDispatch(
        frontActionCreators.setFrontValue(["wizardStep"], WizardStep.Signees)
      );
    } else {
      frontDispatch(
        frontActionCreators.setFrontValue(["wizardStep"], WizardStep.Confirm)
      );
    }
    frontDispatch(frontActionCreators.setFrontValue(["parents"], parents));
  }

  function updateError() {
    if (!checkFormStudentParents(parents)) {
      setError(
        "De obligatoriske feltene er ikke fylt ut, eller har feil format."
      );
    } else {
      setError("");
    }
  }

  useEffect(() => {
    if (parents.length === 0) {
      setParents([
        {
          firstName: "",
          lastName: "",
          email: "",
          telephone: "",
        },
      ]);
    }
  }, []);

  useEffect(() => {
    updateError();
  }, [parents]);
  return (
    <>
      <div className="parentPadding">
        <h1> {eventSteps.parents.title} </h1>
        <p>{eventSteps.parents.description}</p>
      </div>

      <div>
        {parents.map((p, index) => {
          return (
            <div className="parentPadding" key={index}>
              <div className="parentTitle">
                <h1>
                  Foresatt {index + 1} {index === 0 && `(hovedkontakt)`}
                </h1>
                {index > 0 && (
                  <button
                    className="button button--secondary removeButton"
                    onClick={() => removeParent(index)}
                  >
                    <span className="button__text">
                      <strong>&times;</strong>
                    </span>
                  </button>
                )}
              </div>
              <div className="input-block input-block--required">
                <label>
                  {
                    changeFormText(
                      Object.keys(p)[0],
                      event.applicantType,
                      wizardStep
                    ).text
                  }{" "}
                  {InputRequired(Object.keys(p)[0]) && (
                    <span className="title-required-sign"></span>
                  )}
                </label>
                <input
                  className={`${ErrorField(Object.keys(p)[0], p.firstName)}`}
                  placeholder={
                    changeFormText(
                      Object.keys(p)[0],
                      event.applicantType,
                      wizardStep
                    ).placeholder
                  }
                  type={
                    changeFormText(
                      Object.keys(p)[0],
                      event.applicantType,
                      wizardStep
                    ).type
                  }
                  id={Object.keys(p)[0] + index}
                  name={Object.keys(p)[0] + index}
                  value={p.firstName}
                  required
                  onChange={(s) =>
                    updateParents(Object.keys(p)[0], s.target.value, index)
                  }
                  autoComplete={"" + Math.random()}
                />
              </div>
              <div className="input-block input-block--required">
                <label>
                  {
                    changeFormText(
                      Object.keys(p)[1],
                      event.applicantType,
                      wizardStep
                    ).text
                  }{" "}
                  {InputRequired(Object.keys(p)[1]) && (
                    <span className="title-required-sign"></span>
                  )}
                </label>
                <input
                  className={`${ErrorField(Object.keys(p)[1], p.lastName)}`}
                  placeholder={
                    changeFormText(
                      Object.keys(p)[1],
                      event.applicantType,
                      wizardStep
                    ).placeholder
                  }
                  type={
                    changeFormText(
                      Object.keys(p)[1],
                      event.applicantType,
                      wizardStep
                    ).type
                  }
                  id={Object.keys(p)[1] + index}
                  name={Object.keys(p)[1] + index}
                  value={p.lastName}
                  required
                  onChange={(s) =>
                    updateParents(Object.keys(p)[1], s.target.value, index)
                  }
                  autoComplete={"" + Math.random()}
                />
              </div>
              <div className="input-block input-block--required">
                <label>
                  {
                    changeFormText(
                      Object.keys(p)[2],
                      event.applicantType,
                      wizardStep
                    ).text
                  }{" "}
                  {InputRequired(Object.keys(p)[2]) && (
                    <span className="title-required-sign"></span>
                  )}
                </label>
                <input
                  className={`${ErrorField(Object.keys(p)[2], p.email)}`}
                  placeholder={
                    changeFormText(
                      Object.keys(p)[2],
                      event.applicantType,
                      wizardStep
                    ).placeholder
                  }
                  type={
                    changeFormText(
                      Object.keys(p)[2],
                      event.applicantType,
                      wizardStep
                    ).type
                  }
                  id={Object.keys(p)[2] + index}
                  name={Object.keys(p)[2] + index}
                  value={p.email}
                  required
                  onChange={(s) =>
                    updateParents(Object.keys(p)[2], s.target.value, index)
                  }
                  autoComplete={"" + Math.random()}
                />
              </div>
              <div className="input-block input-block--required">
                <label>
                  {
                    changeFormText(
                      Object.keys(p)[3],
                      event.applicantType,
                      wizardStep
                    ).text
                  }{" "}
                  {InputRequired(Object.keys(p)[3]) && (
                    <span className="title-required-sign"></span>
                  )}
                </label>
                <input
                  className={`${ErrorField(Object.keys(p)[3], p.telephone)}`}
                  placeholder={
                    changeFormText(
                      Object.keys(p)[3],
                      event.applicantType,
                      wizardStep
                    ).placeholder
                  }
                  type={
                    changeFormText(
                      Object.keys(p)[3],
                      event.applicantType,
                      wizardStep
                    ).type
                  }
                  id={Object.keys(p)[3] + index}
                  name={Object.keys(p)[3] + index}
                  value={p.telephone}
                  required
                  onChange={(s) =>
                    updateParents(Object.keys(p)[3], s.target.value, index)
                  }
                  autoComplete={"" + Math.random()}
                />
              </div>
            </div>
          );
        })}
        <p>{eventSteps.parents.terms}</p>
        <p className="error">{error}</p>
        {parents.length < eventSteps.parents.max && (
          <button
            className="button button--secondary addParentButton"
            onClick={addParent}
          >
            <span className="button__text">Legg til en foresatt</span>
          </button>
        )}
        <div className="spacebetween">
          <button
            className="button button--secondary emptyButton"
            onClick={() =>
              frontDispatch(
                frontActionCreators.setFrontValue(
                  ["wizardStep"],
                  WizardStep.About
                )
              )
            }
          >
            <span className="button__text">Tilbake</span>
          </button>
          <button
            className={"button button--primary " + buttonLoading}
            onClick={goToConfirm}
            disabled={
              !checkFormStudentParents(parents) ||
              buttonLoading === "button--loading"
            }
          >
            <span className="button__text">Fortsett</span>
          </button>
        </div>
      </div>
    </>
  );
};
