import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ApplicantType,
  EventSteps,
  IEvent,
  Parent,
  Signee,
  Ticket,
  WizardStep,
} from "../../interface";
import { AppState } from "../../store";
import { Article } from "../../components";
import changeFormText from "../shared/ChangeFormText";
import { Consent } from "../shared/Consent";
import {
  frontActionCreators,
  FrontThunkDispatch,
} from "../../store/Front/front.actions";
import checkForm from "../shared/CheckForm";
import axios from "axios";
import { useCrossTabState } from "../../lib/hooks";
import ReCAPTCHA from "react-google-recaptcha";

interface ITicketConfirmProps {
  ingress: string;
  content: string;
  title: string;
}

export const BasicConfirm: FC<ITicketConfirmProps> = ({
  ingress,
  content,
  title,
}) => {
  const frontDispatch = useDispatch<FrontThunkDispatch>();

  const event: IEvent = useSelector<AppState, IEvent>((s) => {
    return s.core.selectedEvent;
  });

  const eventId: string = useSelector<AppState, string>((s) => {
    return s.front.eventId;
  });

  const possibleOccurrences: any = useSelector<AppState, any> ((s) => {
    return s.core.occurrences.filter((o) => {
      return o.capacity - o.booked > 0;
    });
  });

  const storeOccurrences: any = useSelector<AppState, any>((s) => {
    return s.front.storeOccurrences;
  });

  const signees: Signee[] = useSelector<AppState, Signee[]>((s) => {
    return s.front.signees;
  });

  const userForm: any = useSelector<AppState, any>((s) => {
    return s.front.userForm;
  });

  const wizardStep: number = useSelector<AppState, number>((s) => {
    return s.front.wizardStep;
  });

  const tickets: Ticket[] = useSelector<AppState, Ticket[]>((s) => {
    return s.front.tickets;
  });

  const parents: Parent[] = useSelector<AppState, Parent[]>((s) => {
    return s.front.parents;
  });

  const eventSteps: EventSteps = useSelector<AppState, EventSteps>((s) => {
    return s.core.selectedEventSteps;
  });

  const consentId: string = useSelector<AppState, string>((s) => {
    return s.front.consentId;
  });

  const consentUrl: string = useSelector<AppState, string>((s) => {
    return s.front.consentUrl;
  });

  const [consentStatus, setConsentStatus] = useCrossTabState(
    "consentStatus",
    ""
  );

  let [validation, setValidation] = useState<string>("");
  
  useEffect(() => {

    var url = `${API_BASE_URL}/registration/${eventId}/${storeOccurrences[0].id}/prevalidate`;
    var request = {
      consentId: consentId,
      values: userForm,
      parents : {}
    };

    if(event.applicantType === ApplicantType.Student) {
      url += "/student";
      request.parents = parents;
    }

    axios
    .post(url,request)
    .then((response) => {
      setButtonLoading("");
      setValidation("");
    })
    .catch((error) => {
      setButtonLoading("");
      setValidation(error.response.data.message);
    });
  }, [userForm]);

  let [error, setError] = useState<string>("");
  let [buttonLoading, setButtonLoading] = useState<string>("");

  let [recaptchaEnabled, setRecaptchaEnabled] = useState<boolean>(false);
  //Recaptcha
  const recaptchaRef = React.createRef<ReCAPTCHA>();

  async function onSubmit() {
    if (recaptchaEnabled) {
      const token = await recaptchaRef.current?.executeAsync();
      submitForm(token);
    } else {
      submitForm();
    }
  }
  function getTotalPrice() {
    let price = 0;
    tickets.map((value) => {
      price += value.price * value.amount;
    });
    return price;
  }

  function submitForm(token?: any) {
    let headers = {};
    if (token !== null) {
      headers = {
        headers: {
          Recaptcha: token,
        },
      };
    }
    setButtonLoading("button--loading");
    if (event.applicantType === ApplicantType.Student) {
      axios
        .post(
          `${API_BASE_URL}/registration/${eventId}/${storeOccurrences[0].id}/apply/student`,
          {
            consentId: consentId,
            values: userForm,
            parents: parents,
          },
          headers
        )
        .then((response) => {
          frontDispatch(
            frontActionCreators.setFrontValue(["applyCode"], response.data.code)
          );
          frontDispatch(
            frontActionCreators.setFrontValue(
              ["confirmationText"],
              response.data.message
            )
          );
          frontDispatch(
            frontActionCreators.setFrontValue(
              ["wizardStep"],
              WizardStep.Confirmation
            )
          );
        })
        .catch((error) => {
          frontDispatch(
            frontActionCreators.setFrontValue(
              ["applyCode"],
              error.response.data.code
            )
          );
          frontDispatch(
            frontActionCreators.setFrontValue(
              ["confirmationText"],
              error.response.data.message
            )
          );
          frontDispatch(
            frontActionCreators.setFrontValue(
              ["wizardStep"],
              WizardStep.Confirmation
            )
          );
        });
    } else {
      axios
        .post(
          `${API_BASE_URL}/registration/${eventId}/${storeOccurrences[0].id}/apply`,
          {
            consentId: consentId,
            values: userForm,
          }
        )
        .then((response) => {
          frontDispatch(
            frontActionCreators.setFrontValue(["applyCode"], response.data.code)
          );
          frontDispatch(
            frontActionCreators.setFrontValue(
              ["confirmationText"],
              response.data.message
            )
          );
          frontDispatch(
            frontActionCreators.setFrontValue(
              ["wizardStep"],
              WizardStep.Confirmation
            )
          );
        })
        .catch((error) => {
          frontDispatch(
            frontActionCreators.setFrontValue(
              ["applyCode"],
              error.response.data.code
            )
          );
          frontDispatch(
            frontActionCreators.setFrontValue(
              ["confirmationText"],
              error.response.data.message
            )
          );
          frontDispatch(
            frontActionCreators.setFrontValue(
              ["wizardStep"],
              WizardStep.Confirmation
            )
          );
        });
    }
  }

  function dateFormat(date: Date) {
    console.log(date);
    date = new Date(date);
    var options: any = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    let dateFormat = date.toLocaleDateString("nb-NO", options);
    return dateFormat.charAt(0).toUpperCase() + dateFormat.slice(1);
  }
  function getConsentURL() {
    let school: string = "";
    if (userForm["school"] !== undefined) {
      school = userForm["school"];
    }
    axios
      .post(`${API_BASE_URL}/consent/${event.type}/${eventId}/url`, {
        type: "student",
        firstName: userForm["firstName"],
        lastName: userForm["lastName"],
        emailAddress: userForm["email"],
        school: school,
        courseName: storeOccurrences[0].name,
        reason: `du er foresatt for ${userForm["firstName"]} ${userForm["lastName"]}.`
      })
      .then((response) => {
        frontDispatch(
          frontActionCreators.setFrontValue(
            ["consentUrl"],
            response.data.url + `&ReturnUrl=${window.location.href}`
          )
        );
        frontDispatch(
          frontActionCreators.setFrontValue(["consentId"], response.data.id)
        );
      });
  }

  function getRecaptcha() {
    axios.get(`${API_BASE_URL}/info/recaptcha`).then((response) => {
      setRecaptchaEnabled(response.data);
    });
  }

  useEffect(() => {
    if (
      consentUrl === "" &&
      eventSteps.consent !== null &&
      eventSteps.consent.consentType !== ""
    ) {
      getConsentURL();

      setError("Du må gi samtykke for å kunne melde deg på.");
    }

    getRecaptcha();
  }, []);

  useEffect(() => {
    if (consentStatus !== "" && consentStatus !== "-1") {
      setError("");
    }
  }, [consentStatus]);

  return (
    <div>
      <Article title={title} preamble={ingress}>
        <div dangerouslySetInnerHTML={{ __html: content }} />

        <div className="highlighted-block" style= {{ backgroundColor : "#f0f5fc" }}>
          <div className="highlighted-block__content">
              <h3>{eventSteps.summary.title}</h3>
              <p>{eventSteps.summary.description}</p>
          </div>
        </div>

        <div className="grid">
          <div className="span-whole ticket">
            <div className="title">
              <h4 className="customText">Dato</h4>
            </div>
            <div className="content">
              <p>{dateFormat(storeOccurrences[0].date)}</p>
            </div>

            { possibleOccurrences.length > 1 && (           
            <div className="changeButtonDiv">
              <button
                className="button button--primary changeButton"
                onClick={() =>
                  frontDispatch(
                    frontActionCreators.setFrontValue(
                      ["wizardStep"],
                      WizardStep.ChooseEventOccurrence
                    )
                  )
                }
              >
                <span className="button__text">Endre</span>
              </button>
            </div>
            )}
          </div>

          <div className="span-whole ticket">
            <div className="title">
              {event.applicantType === ApplicantType.Student ? (
                <h4 className="customText">Elevens detaljer</h4>
              ) : (
                <h4 className="customText">Dine kontaktdetaljer</h4>
              )}
            </div>
            <div className="content">
              {Object.entries(userForm).map((s: any) => {
                return (
                  <>
                    {!(
                      s[0] === "notes" ||
                      (s[0] === "email" &&
                        event.applicantType === ApplicantType.Student) ||
                      (s[0] === "telephone" &&
                        event.applicantType === ApplicantType.Student)
                    ) && (
                      <div className="contentMain">
                        <div className="contentLeft">
                          <p>
                            <strong>
                              {
                                changeFormText(
                                  s[0],
                                  event.applicantType,
                                  wizardStep
                                ).text
                              }
                              :
                            </strong>
                          </p>
                        </div>
                        <div className="contentRight">
                          <p>{s[1]}</p>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
            <div className="changeButtonDiv">
              <button
                className="button button--primary changeButton"
                onClick={() =>
                  frontDispatch(
                    frontActionCreators.setFrontValue(
                      ["wizardStep"],
                      WizardStep.About
                    )
                  )
                }
              >
                <span className="button__text">Endre</span>
              </button>
            </div>
          </div>

          {event.applicantType === ApplicantType.Student && (
            <div className="span-whole ticket">
              <div className="title">
                <h4 className="customText">Oversikt over foresatte</h4>
              </div>
              <div className="content">
                {parents.map((s: Parent, index: number) => {
                  return (
                    <div className="spaceBottom">
                      <div className="contentMain">
                        <div className="contentLeft">
                          <p>
                            <strong>Fornavn:</strong>
                          </p>
                        </div>
                        <div className="contentRight">
                          <p>{s.firstName}</p>
                        </div>
                      </div>
                      <div className="contentMain">
                        <div className="contentLeft">
                          <p>
                            <strong>Etternavn:</strong>
                          </p>
                        </div>
                        <div className="contentRight">
                          <p>{s.lastName}</p>
                        </div>
                      </div>
                      <div className="contentMain">
                        <div className="contentLeft">
                          <p>
                            <strong>E-post:</strong>
                          </p>
                        </div>
                        <div className="contentRight">
                          <p>{s.email}</p>
                        </div>
                      </div>
                      <div className="contentMain">
                        <div className="contentLeft">
                          <p>
                            <strong>Telefonnummer:</strong>
                          </p>
                        </div>
                        <div className="contentRight">
                          <p>{s.telephone}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="changeButtonDiv">
                <button
                  className="button button--primary changeButton"
                  onClick={() =>
                    frontDispatch(
                      frontActionCreators.setFrontValue(
                        ["wizardStep"],
                        WizardStep.Parents
                      )
                    )
                  }
                >
                  <span className="button__text">Endre</span>
                </button>
              </div>
            </div>
          )}
        </div>

        <br></br>

        {validation.length === 0 &&  eventSteps.consent !== null && eventSteps.consent.consentType !== "" && (
          <>
            <div>
              <Consent url={consentUrl} status={consentStatus}  ></Consent> 
            </div>
          </>
        )}


        {(validation && 
          <lk-banner banner-type="warning">
            <div slot="head">
              <p>
                {validation}
              </p>
            </div>
          </lk-banner>

        )}
        <div className="spacebetween">
          <button
            className="button button--secondary emptyButton"
            onClick={() =>
              frontDispatch(
                frontActionCreators.setFrontValue(
                  ["wizardStep"],
                  WizardStep.About
                )
              )
            }
          >
            <span className="button__text">Tilbake</span>
          </button>
          <button
            className={"button button--primary " + buttonLoading}
            onClick={onSubmit}
            disabled={
              validation.length > 0 || buttonLoading === "button--loading" ||
              (eventSteps.consent !== null &&
                eventSteps.consent.consentType !== "" &&
                (consentStatus === "" || consentStatus === "-1"))
            }
          >
            <span className="button__text">{eventSteps.summary.action}</span>
          </button>
        </div>
        {recaptchaEnabled && (
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey="6LdBnqAaAAAAAGWTMICyKFK-k2Mly7iN5xe0q5et"
          ></ReCAPTCHA>
        )}
      </Article>
    </div>
  );
};
