import * as React from "react";
import { Container } from "reactstrap";
import { Navbar, Footer } from "./";
import "./Layout.scss";

export default (props: { children?: React.ReactNode }) => (
  <React.Fragment>
    <div className="flex-wrapper">
      <Navbar />
      <Container>{props.children}</Container>
      <Footer />
    </div>
  </React.Fragment>
);
