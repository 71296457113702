import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Article } from "../../components";
import EventTicketsList from "../../components/EventTicketsList/EventTicketsList";
import OccurrenceList from "../../components/OccurrenceList/OccurrenceList";
import {
  IEvent,
  EventSteps,
  Occurrence,
  ApplicantType,
  WizardStep,
  SubEvent,
} from "../../interface";
import { AppState } from "../../store";
import { Confirmation } from "../shared/Confirmation";
import { TicketAbout } from "./TicketAbout";
import { TicketSignees } from "./TicketSignees";
import { TicketRegister } from "./TicketRegister";
import { TicketConfirm } from "./TicketConfirm";
import { Parents } from "../shared/Parents";

interface IWizardProps {
  event: IEvent;
  subEvents: SubEvent[];
  occurrences: Occurrence[];
  steps: EventSteps;
}

export const WizardTickets: FC<IWizardProps> = ({
  event,
  subEvents,
  occurrences,
  steps,
}) => {
  const wizardStep = useSelector<AppState, number>((s) => {
    return s.front.wizardStep;
  });

  const selectedOccurrences = useSelector<AppState, any>((s) => {
    return s.front.storeOccurrences;
  });

  return (
    <div>
      {wizardStep === WizardStep.ChooseEventOccurrence && (
        <Article title={event.name} preamble={event.ingress}>
          {event.photo !== null && event.photo !== "" && (
            <img src={event.photo} style={{ marginBottom: "1rem" }} />
          )}
          <div dangerouslySetInnerHTML={{ __html: event.about }} />

          <h2>
            {event.title}
          </h2>

          <div style={{ marginBottom: "1em" }}>
            <p>{event.description}</p>
          </div>
          
          <EventTicketsList
            event={event}
            subEvents={subEvents}
            occurrences={occurrences}
          />
        </Article>
      )}
      {wizardStep === WizardStep.About && (
        <TicketAbout
          title={event.name}
          content={event.about}
          ingress={event.ingress}
          applicantTitle={steps.applicant.title}
          applicantDescription={steps.applicant.description}
          applicantNotice={steps.applicant.notice}
          occurrences={selectedOccurrences}
        />
      )}
      {wizardStep === WizardStep.Parents && (
        <Parents
          title={event.name}
          content={event.about}
          ingress={event.ingress}
        />
      )}
      {wizardStep === WizardStep.Signees && (
        <TicketSignees
          title={event.name}
          content={event.about}
          ingress={event.ingress}
        />
      )}
      {wizardStep === WizardStep.Tickets && (
        <TicketRegister
          title={event.name}
          content={event.about}
          ingress={event.ingress}
          ticketsStep={steps.tickets.pricingGroups}
        />
      )}
      {wizardStep === WizardStep.Confirm && (
        <TicketConfirm
          title={event.name}
          content={event.about}
          ingress={event.ingress}
        />
      )}
      {wizardStep === WizardStep.Confirmation && (
        <Confirmation
          title={steps.confirmation.title}
          ingress={steps.confirmation.ingress}
          content={steps.confirmation.content}
        />
      )}
    </div>
  );
};
