import React from "react";
import { Route } from "react-router";
import Layout from "./components/Layout";
import Event from "./pages/Event";
import Confirm from "./pages/Confirm";
import RedirectUrl from "./pages/RedirectUrl";
import TermsOfSale from "./pages/TermsOfSale";

const App = () => {
  return (
    <Layout>
      <Route exact path="/arrangement/:slug" component={Event} />
      <Route
        exact
        path="/confirm/:eventType/:eventId/:occurrenceId/:applicantId"
        component={Confirm}
      />
      <Route exact path="/redirecturl" component={RedirectUrl}></Route>
      <Route exact path="/salgsbetingelser" component={TermsOfSale}></Route>
    </Layout>
  );
};

export default App;
