import React, { FC } from "react";
import { RouteComponentProps } from "react-router-dom";

import { Article } from "../components";

const TermsOfSale: FC<RouteComponentProps> = (props) => {
  return (
    <Article
      title="Salgsbetingelser"
      preamble="Her kan du gjøre deg kjent med salgsbetingelsene, som gjelder når du kjøper tjenester av Lillestrøm kommune over internett."
    >
      <p>
      Salgsbetingelsene utgjør sammen med din bestilling, bekreftet gjennom en ordrebekreftelse, det samlede avtalegrunnlaget for kjøpet.
      </p>
      <p>
      Ved kjøp av tjenester og bruk av Vipps, benytter kommunen seg av <a className="external-link external-link__underline" href="https://www.forbrukertilsynet.no/lov-og-rett/veiledninger-og-retningslinjer/standard-salgsbetingelser-for-forbrukerkjop-av-varer-over-internett" target="_blank">Forbrukertilsynets standard salgsbetingelser for forbrukerkjøp av varer over internett
        </a>. Forbrukerkjøp over internett reguleres hovedsakelig av avtaleloven, forbrukerkjøpsloven, markedsføringsloven, angrerettloven og ehandelsloven. Disse lovene er tilgjengelig på <a className="external-link external-link__underline" href="https://lovdata.no/" target="_blank">www.lovdata.no</a>.
      </p>
      <h2>1. Avtalen</h2>
      <p>Avtalen består av disse salgsbetingelsene, opplysninger gitt i bestillingsløsningen og eventuelt særskilt avtalte vilkår. Ved eventuell motstrid mellom opplysningene, går det som særskilt er avtalt mellom partene foran, så fremt det ikke strider mot ufravikelig lovgivning.</p>
      <p>Avtalen vil i tillegg bli utfylt av relevante lovbestemmelser som regulerer kjøp av varer mellom næringsdrivende og forbrukere.</p>
      <h2>2. Partene</h2>
      <p>Selger er Lillestrøm kommune, Postboks 313, 2001 Lillestrøm, <a href="mailto: postmottak@lillestrom.kommune.no">postmottak@lillestrom.kommune.no</a>, <a href="tel:66938000">66 93 80 00</a>, organisasjonsnummer 820710592, og betegnes i det følgende som selger/selgeren.</p>
      <p>Kjøper er den forbrukeren som foretar bestillingen, og betegnes i det følgende som kjøper/kjøperen.</p>
      <h2>3. Pris</h2>
      <p>Den oppgitte prisen for varen og tjenester er den totale prisen kjøper skal betale. Denne prisen inkluderer alle avgifter og tilleggskostnader. Ytterligere kostnader som selger før kjøpet ikke har informert om, skal kjøper ikke bære.</p>
      <h2>4. Avtaleinngåelse</h2>
      <p>Avtalen er bindende for begge parter når kjøperen har sendt sin bestilling til selgeren.</p>
      <p>Avtalen er likevel ikke bindende hvis det har forekommet skrive- eller tastefeil i tilbudet fra selgeren i bestillingsløsningen i nettbutikken eller i kjøperens bestilling, og den annen part innså eller burde ha innsett at det forelå en slik feil.</p>
      <h2>5. Betalingen</h2>
      <p>Selgeren kan kreve betaling for varen fra det tidspunkt den blir sendt fra selgeren til kjøperen. Betaling gjøres med Vipps.</p>
      <p>Dersom kjøperen bruker kredittkort eller debetkort ved betaling, kan selgeren reservere kjøpesummen på kortet ved bestilling. Kortet blir belastet samme dag som varen sendes.</p>
      <p>Ved betaling med faktura, blir fakturaen til kjøperen utstedt ved forsendelse av varen. Betalingsfristen fremgår av fakturaen og er på minimum 14 dager fra mottak.</p>
      <p>Kjøpere under 18 år kan ikke betale med etterfølgende faktura.</p>
      <h2>6. Levering</h2>
      <p>Levering er skjedd når kjøperen, eller hans representant, har mottatt bekreftelse for kjøp av billetter/tjenester. Det er ikke mulig å ombooke eller avbestille tjenesten etter kjøp.</p>
      <h2>7. Risikoen for varen</h2>
      <p>Risikoen for varen går over på kjøper når han, eller hans representant, har fått varene levert i tråd med punkt 6.</p>
      <h2>8. Angrerett</h2>
      <p>Avtalen er unntatt fra angrerett.</p>
      <p>Angrerettsloven kapittel 5 §19 tar for seg begrensninger i angrerettsloven. Punkt b sier at: "Ved annet fjernsalg enn telefonsalg gjelder angreretten ikke for enkeltstående tjenester dersom selgeren ved avtaleinngåelsen forplikter seg til å levere tjenesten på et bestemt tidspunkt eller innenfor et bestemt tidsrom". Enkeltstående tjenester som leveres på et bestemt tidspunkt eller innenfor et bestemt tidsrom er for eksempel bade-, kino-, konsert- og flybilletter. I slike tilfeller er man i stedet henvist til de avbestillingsregler som gjelder:</p>
      <p style={{fontWeight: 'bold'}}>Kjøpte billetter refunderes ikke. Ved avlyst arrangement refunderes billetten kun etter arrangørens bestemmelser, men ikke senere enn 60 dager etter arrangementsdato.</p>
      <h2>9. Forsinkelse og manglende levering - kjøpernes rettigheter og frist for å melde krav</h2>
      <p>Dersom selgeren ikke leverer varen eller leverer den for sent i henhold til avtalen mellom partene, og dette ikke skyldes kjøperen eller forhold på kjøperens side, kan kjøperen i henhold til reglene i forbrukerkjøpslovens kapittel 5 etter omstendighetene <span style={{fontStyle: 'italic'}}>holde kjøpesummen tilbake</span>, kreve <span style={{fontStyle: 'italic'}}>oppfyllelse</span>, <span style={{fontStyle: 'italic'}}>heve</span> avtalen og/eller kreve <span style={{fontStyle: 'italic'}}>erstatning</span> fra selgeren.</p>
      <h3>Oppfyllelse</h3>
      <p>Kjøper kan fastholde kjøpet og kreve oppfyllelse fra selger. Kjøper kan imidlertid ikke kreve oppfyllelse dersom det foreligger en hindring som selgeren ikke kan overvinne, eller dersom oppfyllelse vil medføre en så stor ulempe eller kostnad for selger at det står i vesentlig misforhold til kjøperens interesse i at selgeren oppfyller. Skulle vanskene falle bort innen rimelig tid, kan kjøper likevel kreve oppfyllelse.</p>
      <p>Kjøperen taper sin rett til å kreve oppfyllelse om han eller hun venter urimelig lenge med å fremme kravet.</p>
      <h3>Heving</h3>
      <p>Dersom selgeren ikke leverer varen på leveringstidspunktet, skal kjøperen oppfordre selger til å levere innen en rimelig tilleggsfrist for oppfyllelse. Dersom selger ikke leverer varen innen tilleggsfristen, kan kjøperen heve kjøpet.</p>
      <p>Kjøper kan imidlertid heve kjøpet umiddelbart hvis selger nekter å levere varen. Tilsvarende gjelder dersom levering til avtalt tid var avgjørende for inngåelsen av avtalen, eller dersom kjøperen har underrettet selger om at leveringstidspunktet er avgjørende.</p>
      <p>Leveres tingen etter tilleggsfristen forbrukeren har satt eller etter leveringstidspunktet som var avgjørende for inngåelsen av avtalen, må krav om heving gjøres gjeldende innen rimelig tid etter at kjøperen fikk vite om leveringen.</p>
      <h3>Erstatning</h3>
      <p>Kjøperen kan kreve erstatning for lidt tap som følge av forsinkelsen. Dette gjelder imidlertid ikke dersom selgeren godtgjør at forsinkelsen skyldes hindring utenfor selgers kontroll som ikke med rimelighet kunne blitt tatt i betraktning på avtaletiden, unngått, eller overvunnet følgene av.</p>
      <h2>10. Mangel ved varen - kjøperens rettigheter og reklamasjonsfrist</h2>
      <p>Hvis det foreligger en mangel ved varen må kjøper innen rimelig tid etter at den ble oppdaget eller burde ha blitt oppdaget, gi selger melding om at han eller hun vil påberope seg mangelen. Kjøper har alltid reklamert tidsnok dersom det skjer innen 2 mnd. fra mangelen ble oppdaget eller burde blitt oppdaget. Reklamasjon kan skje senest to år etter at kjøper overtok varen. Dersom varen eller deler av den er ment å vare vesentlig lenger enn to år, er reklamasjonsfristen fem år.</p>
      <p>Reklamasjon til selgeren skal skje skriftlig til <a href="mailto: postmottak@lillestrom.kommune.no">postmottak@lillestrom.kommune.no</a> eller til Lillestrøm kommune, Postboks 313, 2001 Lillestrøm.</p>
      <h2>11. Selgerens rettigheter ved kjøperens mislighold</h2>
      <p>Dersom kjøperen ikke betaler eller oppfyller de øvrige pliktene etter avtalen eller loven, og dette ikke skyldes selgeren eller forhold på selgerens side, kan selgeren i henhold til reglene i forbrukerkjøpsloven kapittel 9 etter omstendighetene <span style={{fontStyle: 'italic'}}>holde varen tilbake</span>, kreve <span style={{fontStyle: 'italic'}}>oppfyllelse</span> av avtalen, kreve avtalen <span style={{fontStyle: 'italic'}}>hevet</span> samt kreve <span style={{fontStyle: 'italic'}}>erstatning</span> fra kjøperen. Selgeren vil også etter omstendighetene kunne kreve <span style={{fontStyle: 'italic'}}>renter</span> ved <span style={{fontStyle: 'italic'}}>forsinket betaling</span>, <span style={{fontStyle: 'italic'}}>inkassogebyr</span> og et rimelig <span style={{fontStyle: 'italic'}}>gebyr ved uavhentede varer</span>.</p>
      <h3>Oppfyllelse</h3>
      <p>Selger kan fastholde kjøpet og kreve at kjøperen betaler kjøpesummen. Er varen ikke levert, taper selgeren sin rett dersom han venter urimelig lenge med å fremme kravet.</p>
      <h3>Heving</h3>
      <p>Selger kan heve avtalen dersom det foreligger vesentlig betalingsmislighold eller annet vesentlig mislighold fra kjøperens side. Selger kan likevel ikke heve dersom hele kjøpesummen er betalt. Fastsetter selger en rimelig tilleggsfrist for oppfyllelse og kjøperen ikke betaler innen denne fristen, kan selger heve kjøpet.</p>
      <h3>Renter ved forsinket betaling/inkassogebyr</h3>
      <p>Dersom kjøperen ikke betaler kjøpesummen i henhold til avtalen, kan selger kreve renter av kjøpesummen etter forsinkelsesrenteloven. Ved manglende betaling kan kravet, etter forutgående varsel, bli sendt til Kjøper kan da bli holdt ansvarlig for gebyr etter inkassoloven.</p>
      <h3>Gebyr ved uavhentede ikke-forskuddsbetalte varer</h3>
      <p>Dersom kjøperen unnlater å hente ubetalte varer, kan selger belaste kjøper med et gebyr. Gebyret skal maksimalt dekke selgerens faktiske utlegg for å levere varen til kjøperen. Et slikt gebyr kan ikke belastes kjøpere under 18 år.</p>
      <h2>12. Garanti</h2>
      <p>Garanti som gis av selgeren eller produsenten, gir kjøperen rettigheter i tillegg til de kjøperen allerede har etter ufravikelig lovgivning. En garanti innebærer dermed ingen begrensninger i kjøperens rett til reklamasjon og krav ved forsinkelse eller mangler etter punkt 9 og 10.</p>
      <h2>13. Personopplysninger</h2>
      <p>Behandlingsansvarlig for innsamlede personopplysninger er selger. Med mindre kjøperen samtykker til noe annet, kan selgeren, i tråd med personopplysningsloven, kun innhente og lagre de personopplysninger som er nødvendig for at selgeren skal kunne gjennomføre forpliktelsene etter avtalen. Kjøperens personopplysninger vil kun bli utlevert til andre hvis det er nødvendig for at selger skal få gjennomført avtalen med kjøperen, eller i lovbestemte tilfelle.</p>
      <h2>14. Konfliktløsning</h2>
      <p>Klager rettes til selger innen rimelig tid, jf. punkt 9 og 10. Partene skal forsøke å løse eventuelle tvister i minnelighet. Dersom dette ikke lykkes, kan kjøperen ta kontakt med Forbrukertilsynet for mekling. Forbrukertilsynet er tilgjengelig på telefon <a href="tel:23400600">23 400 600</a>.</p>
      <p>Europa-Kommisjonens klageportal kan også brukes hvis du ønsker å inngi en klage. Det er særlig relevant, hvis du er forbruker bosatt i et annet EU-land. Klagen inngis her: <a className="external-link external-link__underline" href="http://ec.europa.eu/odr" target="_blank">http://ec.europa.eu/odr</a>.</p>
    </Article>
  );
};

export default TermsOfSale;
