import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IEvent } from "../../interface";
import { AppState } from "../../store";
import { Article } from "../../components";

interface IConfirmationProps {
  ingress: string;
  content: string;
  title: string;
}

export const Confirmation: FC<IConfirmationProps> = ({
  ingress,
  content,
  title,
}) => {
  const globalDispatch = useDispatch<any>();

  const event: IEvent = useSelector<AppState, IEvent>((s) => {
    return s.core.selectedEvent;
  });

  const confirmationText: any = useSelector<AppState, object>((s) => {
    return s.front.confirmationText;
  });

  const applyCode: number = useSelector<AppState, number>((s) => {
    return s.front.applyCode;
  });

  function reload() {
    window.location.reload();
  }

  switch (applyCode) {
    case 0:
    case 1:
    case 6:
      return (
        <div>
          <Article
            title={confirmationText.Title}
            preamble={confirmationText.Ingress}
          >
            <div
              dangerouslySetInnerHTML={{ __html: confirmationText.Content }}
            />
          </Article>
        </div>
      );

    case 2:
      return (
        <div>
          <Article title={"Kurset er fullbooket"} preamble={""}>
            <p>
              {
                "Kurset du har valgt er desverre fult. Om du har betalt med Vipps vil denne bli kansellert. Gå gjennom påmeldingsprosessen igjen å velg et nytt kurs."
              }
            </p>

            <button className={"button button--primary"} onClick={reload}>
              <span className="button__text">Gå tilbake til kursvalg</span>
            </button>
          </Article>
        </div>
      );
    case 4:
      return (
        <div>
          <Article title={"Påmelding avsluttet"} preamble={""}>
            <p>{"Kurset du har valgt er desverre stengt for registrering."}</p>
          </Article>
        </div>
      );
    case 13:
      return (
        <div>
          <Article title={"Påmelding avsluttet"} preamble={""}>
            <p>{"Kurset du har valgt er ikke lenger tilgjengelig. Om du har betalt med Vipps vil denne bli kansellert. Gå gjennom påmeldingsprosessen igjen å velg et nytt kurs."}</p>
            <button className={"button button--primary"} onClick={reload}>
              <span className="button__text">Gå tilbake til kursvalg</span>
            </button>
          </Article>
        </div>
      );
    default:
      return (
        <div>
          <Article
            title={confirmationText?.Title}
            preamble={confirmationText?.Ingress}
          >
            <div
              dangerouslySetInnerHTML={{ __html: confirmationText?.Content }}
            />
          </Article>
        </div>
      );
  }
};
