import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { frontActionTypes, FrontActionTypes, IFrontState } from "./front.types";

type MyExtraArg = undefined;
type ThunkResult<R> = ThunkAction<R, IFrontState, MyExtraArg, FrontActionTypes>;
export type FrontThunkDispatch = ThunkDispatch<
  IFrontState,
  MyExtraArg,
  FrontActionTypes
>;

export const frontActionCreators = {
  setFrontValue:
    (key: any[], value: any): ThunkResult<void> =>
    (dispatch) => {
      dispatch({
        type: frontActionTypes.SET_VALUE,
        payload: value,
        key: key,
      });
    },

  setInitial: (): ThunkResult<void> => (dispatch) => {
    dispatch({
      type: frontActionTypes.SET_INITIAL,
      payload: "",
      key: [],
    });
  },
};
