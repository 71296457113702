import { Reducer, Action } from "redux";
import { frontActionTypes, IFrontState, setValue } from "./front.types";
import { PURGE, REHYDRATE } from "redux-persist";

const unloadedState: IFrontState = {
  wizardStep: 1,
  eventId: "",
  occurrenceId: "",
  userForm: {},
  storeOccurrences: [],
  confirmationText: {},
  signees: [],
  tickets: [],
  parents: [],
  school: "",
  consentId: "",
  consentUrl: "",
  applyCode: 0,
  filter: "",
  orderId: "",
  contactSignee: false,
  payLater: false,
  update: "",
  isAdmin: false,
  vippsFailedError: "",
};

export const reducer: Reducer<IFrontState> = (
  state: IFrontState = unloadedState,
  incomingAction: Action
): IFrontState => {
  let action;

  switch (incomingAction.type) {
    case frontActionTypes.SET_VALUE: {
      action = incomingAction as setValue;

      const key = typeof action.key === "string" ? [action.key] : action.key;

      if (key.length > 3 || key.length < 1) {
        return state;
      }

      const getKeyValue =
        <T extends object, U extends keyof T>(obj: T) =>
        (key: U) =>
          obj[key];
      let prevState: any = getKeyValue(state)(key[0]);

      if (key.length > 1) {
        return {
          ...state,
          [key[0]]: {
            ...prevState,
            [key[1]]:
              typeof action.payload === "object" &&
              !Array.isArray(action.payload)
                ? { ...prevState[key[1]], ...action.payload }
                : action.payload,
          },
        };
      }

      return {
        ...state,
        [key[0]]:
          typeof action.payload === "object" && !Array.isArray(action.payload)
            ? { ...prevState, ...action.payload }
            : action.payload,
      };
    }

    case frontActionTypes.SET_INITIAL: {
      return unloadedState;
    }
  }

  return state;
};
