import { ApplicantType } from "../../interface";

export default function checkForm(form: any, applicantType: number, controlField: string = "") {
  const regexPhone = new RegExp(/^((0047)?|(\+47)?)[4|9]\d{7}$/);
  const regexEmail = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  const regexZip = new RegExp(/^[0-9]{4}$/);

  // if(!form.hasOwnProperty(controlField)) {
  //   return false;
  // }


  if(form.hasOwnProperty("orgNumber") && form["orgNumber"].length > 0) {
    const NoValidation = require('novalidation');
    return NoValidation.orgNummer(form["orgNumber"]);
  }

  if(form.hasOwnProperty("zip") && form["zip"].length > 0) {
    return regexZip.test(form["zip"]);
  }

  if (
    form["firstName"] === "" ||
    form["lastName"] === "" ||
    (applicantType !== ApplicantType.Student &&
      !regexPhone.test(form["telephone"])) ||
    (applicantType !== ApplicantType.Student && !regexEmail.test(form["email"]))
  ) {
    return false;
  } else {
    return true;
  }
}
