import React, { FC } from "react";
import { useSelector } from "react-redux";
import { IEvent, Occurrence } from "../../interface";
import { AppState } from "../../store";
import { Article } from "../../components";
import { CourseForm } from "./CourseForm";

interface IAboutProps {
  ingress: string;
  content: string;
  title: string;
  applicantTitle: string;
  applicantDescription: string;
  applicantNotice: string;
  occurrences: any;
}

export const CourseAbout: FC<IAboutProps> = ({
  ingress,
  content,
  title,
  applicantTitle,
  applicantDescription,
  applicantNotice,
  occurrences,
}) => {
  const event: IEvent = useSelector<AppState, IEvent>((s) => {
    return s.core.selectedEvent;
  });

  return (
    <div>
      <Article title={title} preamble={ingress}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
        <div className="highlighted-block yellow">
          <div className="highlighted-block__content">
            <h3>Prioritert liste av kurs du har valgt:</h3>
            <ul className="emphasised-order-list container">
              {occurrences.map((o: any, index: number) => {
                return (
                  <li className="emphasised-order-list__item" key={index}>
                    <span className="emphasised-order-list__item__content">
                      <strong>
                        {o.eventName}, {o.name.toLowerCase()}
                      </strong>
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <br></br>
        <CourseForm
          registrationFields={event.registrationFields}
          title={applicantTitle}
          description={applicantDescription}
          notice={applicantNotice}
        />
      </Article>
    </div>
  );
};
