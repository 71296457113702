import React, { FC, useEffect, useState } from "react";
import { RouteComponentProps, useLocation } from "react-router";
import { useCrossTabState } from "../lib/hooks";
import { detect } from "detect-browser";
import { DateTime } from "luxon"; 

import "./Redirect.scss";

const RedirectUrl: FC<RouteComponentProps> = (props) => {
  let qs = require("qs");

  const [consentStatus, setConsentStatus] = useCrossTabState("consentStatus", "");
  const [consentTimestamp, setConsentTimestamp] = useCrossTabState("consentTimestamp", "");

  function addConsentStatusToStorage() {
    let cs = qs.parse(props.location.search, {
      parameterLimit: 2,
      ignoreQueryPrefix: true,
    });

    localStorage.setItem("consentStatus", cs.cs); 
    localStorage.setItem("consentTimestamp", Date.now().toString());
    try {
      window.close();
    } catch(ex) {
      console.error("Could not close browser", ex);
      
    }
    
  }

  function closeMe() {
    const win = window.open("","_self"); /* url = "" or "about:blank"; target="_self" */
    if(win) {
      win.close();
    }
    return false;
}

  useEffect(() => {
    addConsentStatusToStorage();
  }, []);

  return <>
    <div className="show-after-one-second">
      <section className="success">
        <h2 className="section-title">Vi har mottatt ditt samtykke</h2>
        <p>
          <a style= {{ cursor: "pointer" }} onClick={ () => { closeMe() } }>Trykk her for å fortsette påmelding</a>
        </p>
      </section>
    </div>
  </>;
};

export default RedirectUrl;
