import { ApplicantType, WizardStep } from "../../interface";

export default function changeFormText(
  text: string,
  applicantType: number,
  wizardStep: number,
  notesTitle: string = "",
) {
  switch (text) {
    case "firstName":
      return {
        id: "firstName",
        text: "Fornavn",
        type: "text",
        placeholder: "Fornavn",
        error: "",
        order: 1
      };
    case "lastName":
      return {
        id: "lastName",
        text: "Etternavn",
        type: "text",
        placeholder: "Etternavn",
        error: "",
        order: 2
      };
    case "telephone":
      if (
        applicantType === ApplicantType.Student &&
        (wizardStep === WizardStep.About || wizardStep === WizardStep.Confirm)
      ) {
        return {
          id: "telephone",
          text: "Telefonnummer foresatte",
          type: "tel",
          placeholder: "Telefonnummer foresatte",
          error: "",
          order: 7
        };
      } else {
        return {
          id: "telephone",
          text: "Telefonnummer",
          type: "tel",
          placeholder: "Telefonnummer",
          error: "",
          order: 7
        };
      }
    case "email":
      if (
        applicantType === ApplicantType.Student &&
        (wizardStep === WizardStep.About || wizardStep === WizardStep.Confirm)
      ) {
        return {
          id: "email",
          text: "E-post foresatte",
          type: "email",
          placeholder: "E-post foresatte",
          error: "",
          order: 6,
        };
      } else {
        return {
          id: "email",
          text: "E-post",
          type: "email",
          placeholder: "E-post",
          error: "",
          order: 6
        };
      }
    case "orgNumber":
      return {
        id: "orgNumber",
        text: "Organisasjonsnummer",
        type: "text",
        placeholder: "Organisasjonsnummer",
        error: "",
        order: 5
      };
    case "orgName":
      return {
        id: "orgName",
        text: "Organisasjonsnavn",
        type: "text",
        placeholder: "Organisasjonsnavn",
        error: "",
        order: 4
      };
    
    case "notes":
      console.log(notesTitle);
      var title = "";
      if(notesTitle !== "") {
        title = notesTitle;
      } else {
        title = "Tilleggsinformasjon";
      }
      return {
        id: "notes",
        text: title,
        type: "text",
        placeholder: "Skriv mer informasjon her",
        error: "",
        order: 100,
      };
    case "address":
      return {
        id: "address",
        text: "Adresse",
        type: "text",
        placeholder: "Adresse",
        error: "",
        order: 8,
      };
    case "zip":
      return {
        id: "zip",
        text: "Postnummer",
        type: "text",
        inputmode: "numeric" ,
        placeholder: "Postnummer",
        error: "",
        order: 9,
      };
    case "city":
      return {
        id: "city",
        text: "Poststed",
        type: "text",
        placeholder: "Poststed",
        error: "",
        order: 10
      };
    case "school":
      return {
        id: "school",
        text: "Skole",
        type: "text",
        placeholder: "Velg skole: ",
        error: "",
        order: 5
      };
    case "ssn":
      return {
        id: "ssn",
        text: "Fødselsnummer",
        type: "text",
        placeholder: "Fødselsnummer",
        error: "",
        order: 4
      };
    default:
      return {
        id: "text",
        text: text,
        type: text,
        placeholder: text,
        error: "",
        order: 10,
      };
  }
}
