export default function InputRequired(text: string, controlField: string = "") {
  if(text == controlField) return true;
  if (
    text === "firstName" ||
    text === "lastName" ||
    text === "telephone" ||
    text === "email" ||
    text === "school" ||
    text === "ssn"
  ) {
    return true;
  }
  return false;
}
