import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EventSteps,
  IEvent,
  Parent,
  Signee,
  Ticket,
  WizardStep,
} from "../../interface";
import { AppState } from "../../store";
import { Article } from "../../components";
import "./TicketConfirm.scss";
import changeFormText from "../shared/ChangeFormText";
import {
  frontActionCreators,
  FrontThunkDispatch,
} from "../../store/Front/front.actions";
import checkForm from "../shared/CheckForm";
import axios from "axios";
import { useCrossTabState } from "../../lib/hooks";
import ReCAPTCHA from "react-google-recaptcha";
import { Consent } from "../shared/Consent";

interface ITicketConfirmProps {
  ingress: string;
  content: string;
  title: string;
}

export const TicketConfirm: FC<ITicketConfirmProps> = ({
  ingress,
  content,
  title,
}) => {
  
  const frontDispatch = useDispatch<FrontThunkDispatch>();
  const dispatch = useDispatch();

  const event: IEvent = useSelector<AppState, IEvent>((s) => {
    return s.core.selectedEvent;
  });

  const eventId: string = useSelector<AppState, string>((s) => {
    return s.front.eventId;
  });

  const wizardStep: number = useSelector<AppState, number>((s) => {
    return s.front.wizardStep;
  });

  const storeOccurrences: any = useSelector<AppState, any>((s) => {
    return s.front.storeOccurrences;
  });

  const signees: Signee[] = useSelector<AppState, Signee[]>((s) => {
    return s.front.signees;
  });
  
  const parents: Parent[] = useSelector<AppState, Parent[]>((s) => {
    return s.front.parents;
  });

  const userForm: any = useSelector<AppState, any>((s) => {
    return s.front.userForm;
  });

  const tickets: Ticket[] = useSelector<AppState, Ticket[]>((s) => {
    return s.front.tickets;
  });

  const eventSteps: EventSteps = useSelector<AppState, EventSteps>((s) => {
    return s.core.selectedEventSteps;
  });

  const consentId: string = useSelector<AppState, string>((s) => {
    return s.front.consentId;
  });

  const consentUrl: string = useSelector<AppState, string>((s) => {
    return s.front.consentUrl;
  });

  const orderId: string = useSelector<AppState, string>((s) => {
    return s.front.orderId;
  });

  const payLater: boolean = useSelector<AppState, boolean>((s) => {
    return s.front.payLater;
  });

  const isAdmin: boolean = useSelector<AppState, boolean>((s) => {
    return s.front.isAdmin;
  });

  const vippsFailedError: string = useSelector<AppState, string>((s) => {
    return s.front.vippsFailedError;
  })

  const [consentStatus, setConsentStatus] = useCrossTabState(
    "consentStatus",
    ""
  );

  let [textError, setTextError] = useState<string>("");
  let [buttonLoading, setButtonLoading] = useState<string>("");

  let [recaptchaEnabled, setRecaptchaEnabled] = useState<boolean>(false);

  const _ = require("lodash");

  //Recaptcha
  const recaptchaRef = React.createRef<ReCAPTCHA>();

  async function onSubmit() {
    if (recaptchaEnabled) {
      const token = await recaptchaRef.current?.executeAsync();
      submitForm(token);
    } else {
      submitForm();
    }
  }
  function getTotalPrice() {
    let price = 0;
    tickets.map((value) => {
      if (!payLater) {
        price += value.price * value.amount;
      }
    });
    return price;
  }

  function shownRegistrations(capacity: number, booked: number) {
    if (capacity - booked < 0) {
      return 0;
    } else {
      return capacity - booked;
    }
  }

  function vippsPayment() {
    axios
      .get(
        `${API_BASE_URL}/events/${storeOccurrences[0].id}/applications/amount`
      )
      .then((response) => {
        let amount = 0;
        if (response.data < 0) {
          amount = 0;
        } else {
          amount = response.data;
        }
        if (amount >= signees.length || isAdmin) {
          if (eventSteps.tickets.paymentType === 1 && getTotalPrice() !== 0) {
            let metadata = {
              userForm: userForm,
              storeOccurrences: storeOccurrences,
              tickets: tickets,
              payLater: payLater,
              signees: signees,
              consentId: consentId,
              consentUrl: consentUrl,
            };

            var metadataString = JSON.stringify(metadata);
            axios
              .post(
                `${API_BASE_URL}/payments/initiate/${getTotalPrice() * 100}`,
                {
                  eventID: event.id,
                  mobileNumber: userForm["telephone"],
                  callbackUrl: window.location.href,
                  metadata: metadataString,
                }
              )
              .then((response) => {
                frontDispatch(
                  frontActionCreators.setFrontValue(
                    ["orderId"],
                    response.data.response.orderId
                  )
                );
                window.location.href = response.data.response.url;
              })
              .catch((response) => {
                setTextError("Noe gikk galt, prøv igjen senere");
              });
          } else {
            onSubmit();
          }
        } else {
          setTextError(
            `Du har registrert for mange(${signees.length}) enn det er plass til(${amount}), endre på antall plasser eller bytt hendelse.`
          );
        }
      });
  }

  function submitForm(token?: any) {
    let headers = {};
    if (token !== null) {
      headers = {
        headers: {
          Recaptcha: token,
        },
      };
    }
    setButtonLoading("button--loading");
    const initialValue = {};
    const formTickets = tickets.reduce((obj: any, { amount, name }, index) => {
      return {
        ...obj,
        [name]: amount,
      };
    }, initialValue);

    axios
      .post(
        `${API_BASE_URL}/registration/${eventId}/${storeOccurrences[0].id}/apply/group`,
        {
          consentId: consentId,
          values: userForm,
          applications: signees,
          parents: parents,
          tickets: formTickets,
          OrderId: orderId,
          payLater: payLater,
          isAdmin: isAdmin,
        },
        headers
      )
      .then((response) => {
        frontDispatch(
          frontActionCreators.setFrontValue(["applyCode"], response.data.code)
        );
        frontDispatch(
          frontActionCreators.setFrontValue(
            ["confirmationText"],
            response.data.message
          )
        );
        setButtonLoading("");
        frontDispatch(
          frontActionCreators.setFrontValue(
            ["wizardStep"],
            WizardStep.Confirmation
          )
        );
      })
      .catch((error) => {
        if (error.response.status === 400) {
          console.log(error.response);
          if (error.response.data.code !== undefined) {
            frontDispatch(
              frontActionCreators.setFrontValue(
                ["applyCode"],
                error.response.data.code
              )
            );
            frontDispatch(
              frontActionCreators.setFrontValue(
                ["wizardStep"],
                WizardStep.Confirmation
              )
            );
          }
        }

        frontDispatch(
          frontActionCreators.setFrontValue(
            ["vippsFailedError"],
            "Noe gikk galt. Du har ikke fått plass, om du har betalt på Vipps har denne ordren blitt kansellert. Vennligst prøv igjen."
          )
        );
        console.log("Noe gikk galt. Du har ikke fått plass, om du har betalt på Vipps har denne ordren blitt kansellert. Vennligst prøv igjen.");
        setButtonLoading("");
        // frontDispatch(
        //   frontActionCreators.setFrontValue(
        //     ["wizardStep"],
        //     WizardStep.Confirmation
        //   )
        // );
      });
  }

  function getConsentURL() {
    let school: string = "";
    if (userForm["school"] !== undefined) {
      school = userForm["school"];
    }

    axios
      .post(`${API_BASE_URL}/consent/${event.type}/${eventId}/url`, {
        type: "student",
        firstName: userForm["firstName"],
        lastName: userForm["lastName"],
        emailAddress: userForm["email"],
        school: school,
        courseName: `${storeOccurrences[0].eventName} - ${storeOccurrences[0].name}`,
        reason: `du er foresatt for ${userForm["firstName"]} ${userForm["lastName"]}.`,
      })
      .then((response) => {
        frontDispatch(
          frontActionCreators.setFrontValue(
            ["consentUrl"],
            response.data.url + `&ReturnUrl=${window.location.href}`
          )
        );
        frontDispatch(
          frontActionCreators.setFrontValue(["consentId"], response.data.id)
        );
      });
  }

  function getRecaptcha() {
    axios.get(`${API_BASE_URL}/info/recaptcha`).then((response) => {
      setRecaptchaEnabled(response.data);
    });
  }

  function getOrder() {
    if (orderId !== undefined && orderId !== null && orderId !== "") {
      axios
        .get(`${API_BASE_URL}/payments/${event.id}/${orderId}`)
        .then((response) => {
          if (
            response.data.response.transactionSummary !== undefined &&
            response.data.response.transactionSummary
              .remainingAmountToCapture ===
              getTotalPrice() * 100
          ) {
            onSubmit();
          } else if (
            response.data.response.transactionSummary !== undefined &&
            response.data.response.transactionSummary
              .remainingAmountToCapture === 0
          ) {
            dispatch({ type: "RESET_STORE" });
            localStorage.clear();
            frontDispatch(
              frontActionCreators.setFrontValue(["update"], "updated")
            );
          } else {
            setTextError("Du gjennomførte ikke betaling, vennligst prøv på nytt.");
          }
        });
    }
  }
  
  useEffect(() => {
    if (consentStatus !== "" && consentStatus !== "-1") {
      setTextError("");
    }
  }, [consentStatus]);

  useEffect(() => {
    if (
      consentUrl === "" &&
      eventSteps.consent !== null &&
      eventSteps.consent.consentType !== ""
    ) {
      getConsentURL();
      setTextError("Du må gi samtykke for å kunne melde deg på.");
    }
    getRecaptcha();
  }, []);

  useEffect(() => {
    if (
      eventSteps.tickets.paymentType == 1 &&
      orderId !== undefined &&
      orderId !== null &&
      orderId !== ""
    ) {
      getOrder();
    }
  }, []);

  return (
    <div>
      {buttonLoading == "button--loading" && (
        <lk-spinner text="Laster inn siden..."></lk-spinner>
      )}
      <Article title={title} preamble={ingress}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
        
        {vippsFailedError !== "" && (
          <lk-banner banner-type="warning">
          <div slot="head">
            <p>{vippsFailedError}</p>
          </div>
          </lk-banner>
        )}
        <div className="parentPadding">
          <h2>{eventSteps.summary.title}</h2>
          <p>{eventSteps.summary.description}</p>
          <p>
            <strong>Total pris: kr {getTotalPrice()}</strong>
          </p>
        </div>

        <div className="grid">
          <div className="span-whole ticket">
            <div className="title">
              <h4 className="customText">Reservert billett</h4>
            </div>
            <div className="content">
              <p>
                {storeOccurrences[0].eventName}, {storeOccurrences[0].name}
              </p>
            </div>
            <div className="changeButtonDiv">
              <button
                className="button button--primary changeButton"
                onClick={() =>
                  frontDispatch(
                    frontActionCreators.setFrontValue(
                      ["wizardStep"],
                      WizardStep.ChooseEventOccurrence
                    )
                  )
                }
              >
                <span className="button__text">Endre</span>
              </button>
            </div>
          </div>

          {!_.isEmpty(eventSteps.tickets.pricingGroups) && (
            <div className="span-whole ticket">
              <div className="title">
                <h4 className="customText">Prisgruppe(r)</h4>
              </div>
              <div className="content">
                {tickets.map((t: Ticket, index: number) => {
                  return (
                    <>
                      {t.amount > 0 && (
                        <div className="contentMain">
                          <div className="contentLeft">
                            <p>
                              <strong>
                                {t.name} (kr {t.price}):
                              </strong>
                            </p>
                          </div>
                          <div className="contentRight">
                            <p>{t.amount}</p>
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
              <div className="changeButtonDiv">
                <button
                  className="button button--primary changeButton"
                  onClick={() =>
                    frontDispatch(
                      frontActionCreators.setFrontValue(
                        ["wizardStep"],
                        WizardStep.Tickets
                      )
                    )
                  }
                >
                  <span className="button__text">Endre</span>
                </button>
              </div>
            </div>
          )}

          <div className="span-whole ticket">
            <div className="title">
              <h4 className="customText">Dine kontaktdetaljer</h4>
            </div>
            <div className="content">
              {Object.entries(userForm).map((s: any) => {
                return (
                  <>
                    {s[0] !== "notes" && (
                      <div className="contentMain">
                        <div className="contentLeft">
                          <p>
                            <strong>
                              {
                                changeFormText(
                                  s[0],
                                  event.applicantType,
                                  wizardStep,
                                ).text
                              }
                              :
                            </strong>
                          </p>
                        </div>
                        <div className="contentRight">
                          <p>{s[1]}</p>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
            <div className="changeButtonDiv">
              <button
                className="button button--primary changeButton"
                onClick={() =>
                  frontDispatch(
                    frontActionCreators.setFrontValue(
                      ["wizardStep"],
                      WizardStep.About
                    )
                  )
                }
              >
                <span className="button__text">Endre</span>
              </button>
            </div>
          </div>

          <div className="span-whole ticket">
            <div className="title">
              <h4 className="customText">Oversikt over påmeldte</h4>
            </div>
            <div className="content">
              {signees.map((s: any, index: number) => {
                return (
                  <>
                    {Object.keys(s).map((v: string) => {
                      return (
                        <div className="contentMain">
                          <div className="contentLeft">
                            <p>
                              <strong>{
                                changeFormText(
                                  v,
                                  event.applicantType,
                                  wizardStep
                                ).text
                              }: </strong>
                            </p>
                          </div>
                          <div className="contentRight">
                            <p>{s[v]}</p>
                          </div>
                       </div>
                       )
                      })}
                      <br></br>
                </>
                )
              })}
            </div>
            <div className="changeButtonDiv">
              <button
                className="button button--primary changeButton"
                onClick={() =>
                  frontDispatch(
                    frontActionCreators.setFrontValue(
                      ["wizardStep"],
                      WizardStep.Signees
                    )
                  )
                }
              >
                <span className="button__text">Endre</span>
              </button>
            </div>
          </div>
        </div>

        <br></br>

        {eventSteps.consent !== null && eventSteps.consent.consentType !== "" && (
          <>
            <div>
              <Consent url={consentUrl} status={consentStatus}></Consent>
            </div>
          </>
        )}

        <p className="error">{textError}</p>

        <div className="spacebetween">
          {!_.isEmpty(eventSteps.tickets.pricingGroups) ? (
            <button
              className="button button--secondary emptyButton"
              onClick={() =>
                frontDispatch(
                  frontActionCreators.setFrontValue(
                    ["wizardStep"],
                    WizardStep.Tickets
                  )
                )
              }
            >
              <span className="button__text">Tilbake</span>
            </button>
          ) : (
            <button
              className="button button--secondary emptyButton"
              onClick={() =>
                frontDispatch(
                  frontActionCreators.setFrontValue(
                    ["wizardStep"],
                    WizardStep.Signees
                  )
                )
              }
            >
              <span className="button__text">Tilbake</span>
            </button>
          )}

          <button
            className={"button button--primary " + buttonLoading}
            onClick={vippsPayment}
            disabled={
              buttonLoading === "button--loading" ||
              (eventSteps.consent !== null &&
                eventSteps.consent.consentType !== "" &&
                (consentStatus === "" || consentStatus === "-1"))
            }
          >
            <span className="button__text">{eventSteps.summary.action}</span>
          </button>
        </div>
        {recaptchaEnabled && (
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey="6LdBnqAaAAAAAGWTMICyKFK-k2Mly7iN5xe0q5et"
          ></ReCAPTCHA>
        )}
      </Article>
    </div>
  );
};
