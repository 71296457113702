import React, { FC, useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import {
  frontActionCreators,
  FrontThunkDispatch,
} from "../../store/Front/front.actions";
import {
  coreActionCreators,
  CoreThunkDispatch,
} from "../../store/Core/core.actions";
import {
  ApplicantType,
  IEvent,
  Occurrence,
  School,
  WizardStep,
} from "../../interface";
import changeFormText from "../shared/ChangeFormText";
import checkForm from "../shared/CheckForm";
import checkFormStudentSchool from "../shared/CheckFormStudentSchool";
import sortSchools from "../shared/SortSchools";
import InputRequired from "../shared/InputRequired";
import ErrorField from "../shared/ErrorField";

interface ITicketFormProps {
  registrationFields: string[];
  title: string;
  description: string;
  notice: string;
}

export const TicketForm: FC<ITicketFormProps> = ({
  registrationFields,
  title,
  description,
  notice,
}) => {
  const frontDispatch = useDispatch<FrontThunkDispatch>();
  const eventId: string = useSelector<AppState, string>((s) => {
    return s.front.eventId;
  });

  const occurrences: Occurrence[] = useSelector<AppState, Occurrence[]>((s) => {
    return s.front.storeOccurrences;
  });

  const wizardStep: number = useSelector<AppState, number>((s) => {
    return s.front.wizardStep;
  });

  const event: IEvent = useSelector<AppState, IEvent>((s) => {
    return s.core.selectedEvent;
  });

  const userForm: any = useSelector<AppState, any>((s) => {
    return s.front.userForm;
  });

  let [form, setForm] = useState<any>(userForm);
  let [error, setError] = useState<string>("");
  let [schools, setSchools] = useState<School[]>([]);
  let [buttonLoading, setButtonLoading] = useState<string>("");

  function updateForm(name: string, value: string) {
    setForm((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    setError("");
  }

  function goToSignee() {
    frontDispatch(frontActionCreators.setFrontValue(["userForm"], form));
    frontDispatch(
      frontActionCreators.setFrontValue(["wizardStep"], WizardStep.Signees)
    );
  }

  function goToParents() {
    frontDispatch(frontActionCreators.setFrontValue(["userForm"], form));
    frontDispatch(
      frontActionCreators.setFrontValue(["wizardStep"], WizardStep.Parents)
    );
  }

  function updateError() {
    if (event.applicantType === ApplicantType.Student) {
      if (
        !(checkForm(form, event.applicantType, event.controlField) && checkFormStudentSchool(form))
      ) {
        setError(
          "De obligatoriske feltene er ikke fylt ut, eller har feil format."
        );
      } else {
        setError("");
      }
    } else {
      if (!checkForm(form, event.applicantType, event.controlField)) {
        setError(
          "De obligatoriske feltene er ikke fylt ut, eller har feil format."
        );
      } else {
        setError("");
      }
    }
  }

  useEffect(() => {
    if (Object.keys(userForm).length === 0) {
      setForm(
        registrationFields.reduce(
          (acc: any, curr: any) => ((acc[curr] = ""), acc),
          {}
        )
      );
    }

    if (event.applicantType === ApplicantType.Student) {
      axios.get(`${API_BASE_URL}/Info/schools`).then((res) => {

        res.data.push({
          id: "0",
          name: "* Privat skole/skole utenfor Lillestrøm kommune",
        });

        setSchools(sortSchools(res.data));
      });
    }
  }, []);

  useEffect(() => {
    updateError();
  }, [form]);

  return (
    <>
      <div className="padding">
        <h1>{title}</h1>
        <p>{description}</p>
        {registrationFields.map((item: string, index: number) => {
          return (
            <div key={index}>
              {item === "school" ? (
                <div className="interactive-select interactive-select__blue">
                  <label htmlFor="options">
                    {changeFormText(item, event.applicantType, wizardStep).text}
                    {InputRequired(item, event.controlField) && (
                      <span className="title-required-sign"></span>
                    )}
                  </label>
                  <select
                    name="options"
                    id="options"
                    onChange={(s) => updateForm(item, s.target.value)}
                  >
                    <option value="" disabled selected={form[item] === ""}>
                      {
                        changeFormText(item, event.applicantType, wizardStep)
                          .placeholder
                      }
                    </option>
                    {schools.map(({ id, name }) => {
                      return (
                        <option
                          value={id}
                          key={id}
                          selected={form[item] === id}
                        >
                          {name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              ) : (
                <>
                {!(
                    event.applicantType === ApplicantType.Student &&
                    (item === "telephone" || item === "email")
                  ) && (
                    <div className="input-block input-block--required">
                      <label>
                        {
                          changeFormText(item, event.applicantType, wizardStep, event.notesTitle)
                            .text
                        }
                        {InputRequired(item, event.controlField) && (
                          <span className="title-required-sign"></span>
                        )}
                      </label>
                      <input
                        className={`${ErrorField(item, form[item])}`}
                        placeholder={
                          changeFormText(item, event.applicantType, wizardStep)
                            .placeholder
                        }
                        type={
                          changeFormText(item, event.applicantType, wizardStep)
                            .type
                        }
                        onKeyDown={ changeFormText(item, event.applicantType, wizardStep).type == "number" ? (evt) => {["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} : undefined}
                        id={item}
                        name={item}
                        value={form[item]}
                        required
                        onChange={(s) => updateForm(item, s.target.value)}
                        autoComplete={"" + Math.random()}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          );
        })}
        <p>{notice}</p>
        <p className="error">{error}</p>
      </div>
      <div className="spacebetween">
        <button
          className="button button--secondary emptyButton"
          onClick={() =>
            frontDispatch(
              frontActionCreators.setFrontValue(
                ["wizardStep"],
                WizardStep.ChooseEventOccurrence
              )
            )
          }
        >
          <span className="button__text">Tilbake</span>
        </button>

        {event.applicantType === ApplicantType.Student ? (
          <button
            className="button button--primary"
            onClick={goToParents}
            disabled={
              !(
                checkForm(form, event.applicantType, event.controlField) &&
                checkFormStudentSchool(form)
              )
            }
          >
            <span className="button__text">Fortsett</span>
          </button>
        ) : (
          <button
            className={"button button--primary " + buttonLoading}
            onClick={goToSignee}
            disabled={
              !checkForm(form, event.applicantType, event.controlField) ||
              buttonLoading === "button--loading"
            }
          >
            <span className="button__text">Fortsett</span>
          </button>
        )}
      </div>
    </>
  );
};
