import "./EventTicketsList.scss";

import React, { FC, useState, useEffect } from "react";
import { IEventTicketsListProps } from "./IEventTicketsList";
import { useDispatch, useSelector } from "react-redux";

import { AppState } from "../../store";
import {
  frontActionCreators,
  FrontThunkDispatch,
} from "../../store/Front/front.actions";
import { Occurrence, WizardStep } from "../../interface";

const EventList: FC<IEventTicketsListProps> = ({
  event,
  subEvents,
  occurrences,
}) => {
  const frontDispatch = useDispatch<FrontThunkDispatch>();

  const wizardStep = useSelector<AppState, number>((s) => {
    return s.front.wizardStep;
  });

  const storeOccurrences = useSelector<AppState, any>((s) => {
    return s.front.storeOccurrences;
  });

  const isAdmin = useSelector<AppState, boolean>((s) => {
    return s.front.isAdmin;
  })

  const _ = require("lodash");
  var color = -1;

  function randomColor() {
    var colors = ["#fad2e6", "#a0d2b4", "#f0c8a0"];
    color++;
    if (color === 3) {
      color = 0;
    }
    return colors[color];
  }

  let [selectedOccurrences, setSelectedOccurrences] =
    useState<any>(storeOccurrences);
  let [filter, setFilter] = useState<string>("");
  let [occurrencesShown, setOccurrencesShown] = useState<number>(0);

  function shownRegistrations(capacity: number, booked: number) {
    if(capacity - booked < 0) {
      return 0;
    } else {
      return capacity - booked;
    }
  }
  function updateSelectedOccurrences(occurrence: any, name: any) {
    const index = selectedOccurrences.findIndex(
      (e: any) => e.id === occurrence.id
    );
    if (index === -1) {
      if (!(selectedOccurrences.length >= 1)) {
        occurrence.eventName = name;
        setSelectedOccurrences([...selectedOccurrences, occurrence]);
      }
      return;
    }
    const newArr = [...selectedOccurrences];
    setSelectedOccurrences(newArr.filter((i) => i.id !== occurrence.id));
  }

  let listOccurrences: any = [];
  let groupOccurrences: any = [];

  useEffect(() => {
    if (
      event.shownOccurrences !== undefined &&
      event.shownOccurrences !== null &&
      event.shownOccurrences !== 0
    ) {
      setOccurrencesShown(event.shownOccurrences);
    } else {
      setOccurrencesShown(groupOccurrences.length);
    }
  }, [groupOccurrences, listOccurrences]);

  //Gruppert med kursnavn
  listOccurrences = Object.entries(_.groupBy(occurrences, "parentEventID"));
  subEvents.map(({ id, name, about, order, filters }) => {
    listOccurrences.map((e: any) => {
      if (e[1].some((el: any) => el.parentEventID === id)) {
        e.push({
          name: name,
          about: about,
          order: order,
          filters: filters,
        });
      }
    });
  });

  //Gruppert med dato/dag
  groupOccurrences = Object.entries(_.groupBy(occurrences, "name"));
  groupOccurrences.map((e: any) => {
    e[1].map((d: any) => {
      const sEvent = subEvents.find((se) => se.id === d.parentEventID);
      d["eventName"] = sEvent?.name;
      d["eventOrder"] = sEvent?.order;
      if (e[2] === undefined || null) {
        e.push(d.date);
      }
    });
  });

  function submitOccurrences() {
    frontDispatch(
      frontActionCreators.setFrontValue(
        ["storeOccurrences"],
        selectedOccurrences
      )
    );

    frontDispatch(
      frontActionCreators.setFrontValue(["wizardStep"], WizardStep.About)
    );
  }

  function updateFilter(selectedFilter: string) {
    if (selectedFilter === filter) {
      setFilter("");
    } else {
      setFilter(selectedFilter);
    }
  }

  function emptySelectedOccurrences() {
    frontDispatch(frontActionCreators.setFrontValue(["storeOccurrences"], []));
    setSelectedOccurrences([]);
  }

  function anyOccurrencesToShow(occurrences : Occurrence[]) : boolean {
    return occurrences.filter(i => !i.closed && (i.capacity - i.booked > 0)).length > 0;
  }

  function isOpen(o: Occurrence) {
    if (o.closed) {
      return false;
    }
    if(!event.hasWaitingList)
    if (o.capacity - o.booked == 0) {
      return false;
    }
    return true;
  }

  return (
    <>
      {event.filters !== undefined &&
        event.filters !== null &&
        event.filters.length > 0 && (
          <div className="buttonsDiv">
            <h3>Sorter etter: </h3>
            <div className="button-toggle">
              {event.filters.map((s, index) => {
                return (
                  <>
                    <input
                      type="radio"
                      className="button-toggle__radio"
                      id={`option-${index + 1}`}
                      name="option"
                      checked={s === filter}
                      onClick={() => updateFilter(s)}
                    />
                    <label
                      htmlFor={`option-${index + 1}`}
                      className="button-toggle__label"
                    >
                      {s}
                    </label>
                  </>
                );
              })}
            </div>
          </div>
        )}
      {event.dateView === undefined ||
      event.dateView === null ||
      !event.dateView ? (
        <>
          {listOccurrences
            .sort((c: any, d: any) => (c[2].order > d[2].order ? 1 : -1))
            .filter((s: any) =>
              filter === "" ? s[2] : s[2].filters.includes(filter)
            )
            .map((e: any, index: number) => {
              return (
                <lk-toggle background={randomColor()} key={index}>
                  <span slot="title" className="padding-right--small">
                    {e[2].name}
                  </span>
                  <div className="user-info">
                    <p>{e[2].about}</p>
                    {e[1]
                      .sort(
                        (a: Occurrence, b: Occurrence) =>
                          new Date(a.date).getTime() -
                          new Date(b.date).getTime()
                      )
                      .filter((a: any) => new Date(a.date) >= new Date())
                      .splice(0, occurrencesShown)
                      .map((o: Occurrence, index: number) => {
                        return (
                          <>
                            {(isOpen(o) && (o.capacity - o.booked > 0) || isAdmin) ? (
                              <label
                                className="interactive-checkbox interactive-checkbox-trigger"
                                htmlFor={o.id}
                                key={index}
                              >
                                <input
                                  type="checkbox"
                                  id={o.id}
                                  name={o.id}
                                  value={o.id}
                                  onChange={(value) =>
                                    updateSelectedOccurrences(o, e[2].name)
                                  }
                                  checked={selectedOccurrences.includes(o)}
                                />
                                <span className="interactive-checkbox__span">
                                  <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    role="presentation"
                                    version="1.1"
                                    id="Layer_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 27.4 27.4"
                                    xmlSpace="preserve"
                                  >
                                    <path
                                      className="interactive-checkbox__path"
                                      d="M24.6,25.5H2.7c-0.5,0-0.9-0.4-0.9-0.9V2.7c0-0.5,0.4-0.9,0.9-0.9h21.9c0.5,0,0.9,0.4,0.9,0.9v21.9
                     C25.5,25.1,25.1,25.5,24.6,25.5z"
                                    ></path>

                                    <path
                                      className="interactive-checkbox__path-checked path-checked"
                                      d="M24.6,25.5H2.7c-0.5,0-0.9-0.4-0.9-0.9V2.7c0-0.5,0.4-0.9,0.9-0.9h21.9c0.5,0,0.9,0.4,0.9,0.9v21.9
                     C25.5,25.1,25.1,25.5,24.6,25.5z"
                                    ></path>
                                    <path
                                      className="interactive-checkbox__path-checked-tick path-checked"
                                      d="M8,14.2l2.4,3.5c0.3,0.5,1,0.6,1.5,0.3c0.1-0.1,0.2-0.1,0.3-0.2L21,6.8"
                                    ></path>
                                  </svg>
                                  <div className="checkboxLabel">
                                    <div>{o.name}</div>
                                    {!o.hideRegisteredAmount && (
                                       <div>
                                       {shownRegistrations(o.capacity, o.booked)}/{o.capacity}{" "}
                                       ledige plasser
                                     </div>
                                    )}
                                  </div>
                                </span>
                              </label>
                            ) : (
                              <label
                                className="interactive-checkbox interactive-checkbox-trigger disabled_text"
                                htmlFor={o.id}
                                key={index}
                              >
                                <input
                                  type="checkbox"
                                  id={o.id}
                                  name={o.id}
                                  value={o.id}
                                  checked={false}
                                />
                                <span className="interactive-checkbox__span">
                                  <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    role="presentation"
                                    version="1.1"
                                    id="Layer_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 27.4 27.4"
                                    xmlSpace="preserve"
                                  >
                                    <path
                                      className="interactive-checkbox__path disabled_path"
                                      d="M24.6,25.5H2.7c-0.5,0-0.9-0.4-0.9-0.9V2.7c0-0.5,0.4-0.9,0.9-0.9h21.9c0.5,0,0.9,0.4,0.9,0.9v21.9
                     C25.5,25.1,25.1,25.5,24.6,25.5z"
                                    ></path>

                                    <path
                                      className="interactive-checkbox__path-checked path-checked"
                                      d="M24.6,25.5H2.7c-0.5,0-0.9-0.4-0.9-0.9V2.7c0-0.5,0.4-0.9,0.9-0.9h21.9c0.5,0,0.9,0.4,0.9,0.9v21.9
                     C25.5,25.1,25.1,25.5,24.6,25.5z"
                                    ></path>
                                    <path
                                      className="interactive-checkbox__path-checked-tick path-checked"
                                      d="M8,14.2l2.4,3.5c0.3,0.5,1,0.6,1.5,0.3c0.1-0.1,0.2-0.1,0.3-0.2L21,6.8"
                                    ></path>
                                  </svg>
                                  <div className="checkboxLabel">
                                    <div>{o.name}</div>
                                    {!o.hideRegisteredAmount && (
                                       <div>
                                       {shownRegistrations(o.capacity, o.booked)}/{o.capacity}{" "}
                                       ledige plasser
                                     </div>
                                    )}
                                  </div>
                                </span>
                              </label>
                            )}
                          </>
                        );
                      })}
                  </div>
                </lk-toggle>
              );
            })}
        </>
      ) : (
        <>
          {groupOccurrences
            .sort(
              (a: any, b: any) =>
                new Date(a[2]).getTime() - new Date(b[2]).getTime()
            )
            .filter(
              (a: any) =>
                new Date(new Date(a[2]).setHours(21, 0, 0, 0)) > new Date()
            )
            .splice(0, occurrencesShown)
            .map((e: any, index: number) => {
              return ( anyOccurrencesToShow(e[1]) &&
                <lk-toggle background={randomColor()} key={index}>
                  <span slot="title" className="padding-right--small">
                    {e[0]}
                  </span>
                  <div className="user-info">
                    {e[1]
                      .sort((a: any, b: any) =>
                        a.eventOrder > b.eventOrder ? 1 : -1
                      )
                      .map((o: any, index: number) => {
                        return (
                          <>
                            {(isOpen(o) && (o.capacity - o.booked > 0) || isAdmin) ? (
                              <label
                                className="interactive-checkbox interactive-checkbox-trigger"
                                htmlFor={o.id}
                                key={index}
                              >
                                <input
                                  type="checkbox"
                                  id={o.id}
                                  name={o.id}
                                  value={o.id}
                                  onChange={(value) =>
                                    updateSelectedOccurrences(o, e[2].name)
                                  }
                                  checked={selectedOccurrences.includes(o)}
                                />
                                <span className="interactive-checkbox__span">
                                  <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    role="presentation"
                                    version="1.1"
                                    id="Layer_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 27.4 27.4"
                                    xmlSpace="preserve"
                                  >
                                    <path
                                      className="interactive-checkbox__path"
                                      d="M24.6,25.5H2.7c-0.5,0-0.9-0.4-0.9-0.9V2.7c0-0.5,0.4-0.9,0.9-0.9h21.9c0.5,0,0.9,0.4,0.9,0.9v21.9
                   C25.5,25.1,25.1,25.5,24.6,25.5z"
                                    ></path>

                                    <path
                                      className="interactive-checkbox__path-checked path-checked"
                                      d="M24.6,25.5H2.7c-0.5,0-0.9-0.4-0.9-0.9V2.7c0-0.5,0.4-0.9,0.9-0.9h21.9c0.5,0,0.9,0.4,0.9,0.9v21.9
                   C25.5,25.1,25.1,25.5,24.6,25.5z"
                                    ></path>
                                    <path
                                      className="interactive-checkbox__path-checked-tick path-checked"
                                      d="M8,14.2l2.4,3.5c0.3,0.5,1,0.6,1.5,0.3c0.1-0.1,0.2-0.1,0.3-0.2L21,6.8"
                                    ></path>
                                  </svg>
                                  <div className="checkboxLabel">
                                    <div>{o.eventName}</div>
                                    {!o.hideRegisteredAmount && (
                                       <div>
                                       {shownRegistrations(o.capacity, o.booked)}/{o.capacity}{" "}
                                       ledige plasser
                                     </div>
                                    )}
                                  </div>
                                </span>
                              </label>
                            ) : (
                              <label
                                className="interactive-checkbox interactive-checkbox-trigger disabled_text"
                                htmlFor={o.id}
                                key={index}
                              >
                                <input
                                  type="checkbox"
                                  id={o.id}
                                  name={o.id}
                                  value={o.id}
                                  checked={false}
                                />
                                <span className="interactive-checkbox__span">
                                  <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    role="presentation"
                                    version="1.1"
                                    id="Layer_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 27.4 27.4"
                                    xmlSpace="preserve"
                                  >
                                    <path
                                      className="interactive-checkbox__path disabled_path"
                                      d="M24.6,25.5H2.7c-0.5,0-0.9-0.4-0.9-0.9V2.7c0-0.5,0.4-0.9,0.9-0.9h21.9c0.5,0,0.9,0.4,0.9,0.9v21.9
                     C25.5,25.1,25.1,25.5,24.6,25.5z"
                                    ></path>

                                    <path
                                      className="interactive-checkbox__path-checked path-checked"
                                      d="M24.6,25.5H2.7c-0.5,0-0.9-0.4-0.9-0.9V2.7c0-0.5,0.4-0.9,0.9-0.9h21.9c0.5,0,0.9,0.4,0.9,0.9v21.9
                     C25.5,25.1,25.1,25.5,24.6,25.5z"
                                    ></path>
                                    <path
                                      className="interactive-checkbox__path-checked-tick path-checked"
                                      d="M8,14.2l2.4,3.5c0.3,0.5,1,0.6,1.5,0.3c0.1-0.1,0.2-0.1,0.3-0.2L21,6.8"
                                    ></path>
                                  </svg>
                                  <div className="checkboxLabel">
                                    <div>{o.eventName}</div>
                                    {!o.hideRegisteredAmount && (
                                       <div>
                                       {shownRegistrations(o.capacity, o.booked)}/{o.capacity}{" "}
                                       ledige plasser
                                     </div>
                                    )}
                                  </div>
                                </span>
                              </label>
                            )}
                          </>
                        );
                      })}
                  </div>
                </lk-toggle>
              );
            })}
        </>
      )}

      {selectedOccurrences.length > 0 && (
        <div className="highlighted-block yellow">
          <div className="highlighted-block__content">
            <h3>Du har valgt:</h3>
            <ul className="emphasised-order-list container">
              {selectedOccurrences.map((o: any, index: number) => {
                return (
                  <li className="emphasised-order-list__item" key={index}>
                    <span className="emphasised-order-list__item__content">
                      <strong>
                        {o.eventName}, {o.name}
                      </strong>
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
      <div className="spacebetween">
        <div>
          <button
            className="button button--secondary emptyButton"
            onClick={() => emptySelectedOccurrences()}
          >
            <span className="button__text">Tøm</span>
          </button>
        </div>
        <button
          className="button button--primary"
          onClick={submitOccurrences}
          disabled={selectedOccurrences.length <= 1 - 1}
        >
          <span className="button__text">Fortsett</span>
        </button>
      </div>
    </>
  );
};

export default EventList;
