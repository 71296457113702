import "./Article.scss";

import React, { FC } from "react";
import { IArticleProps } from "./";

const Article: FC<IArticleProps> = ({ title, preamble, children }) => {
  // content.forEach(item => {
  //   if (typeof item.content === 'string' && item.content.startsWith('- ')) {
  //     if (typeof prev === 'string' && prev && prev.content.startsWith('- ')) {
  //       listRef.content.push(item.content.slice(2))
  //     } else {
  //       listRef = { type: 'list', content: [item.content.slice(2)] }
  //       updatedContent.push(listRef)
  //     }
  //   } else {
  //     updatedContent.push(item)
  //   }
  //   prev = item
  // })

  return (
    <div className={`margin-top--large`}>
      <article className={`article-text`}>
        <header
          className={`article__header article-text__mainheader margin-bottom--large`}
        >
          <h1 className="page-title article-text__mainheader">{title}</h1>
          {preamble && <p className={`article-text__lead`}>{preamble}</p>}
        </header>
        <section className="margin-bottom--large">{children}</section>
      </article>
    </div>
  );
};

export default Article;
