// import * as WeatherForecasts from './WeatherForecasts';
// import * as Counter from './Counter';
import * as CoreTypes from "./Core/core.types";
import * as CoreReducer from "./Core/core.reducer";
import * as FrontTypes from "./Front/front.types";
import * as FrontReducer from "./Front/front.reducers";
import { combineReducers } from "redux";
import storage from "redux-persist/es/storage";

// The top-level state object
export interface ApplicationState {
  core: CoreTypes.ICoreState | undefined;
  front: FrontTypes.IFrontState | undefined;
}

const appReducer = combineReducers({
  core: CoreReducer.reducer,
  front: FrontReducer.reducer,
});

export type AppState = ReturnType<typeof appReducer>;

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
  core: CoreReducer.reducer,
  front: FrontReducer.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
