import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store";
import { RouteComponentProps, useParams } from "react-router";
import {
  coreActionCreators,
  CoreThunkDispatch,
} from "../store/Core/core.actions";
import {
  frontActionCreators,
  FrontThunkDispatch,
} from "../store/Front/front.actions";
import {
  EventSteps,
  EventType,
  IEvent,
  Occurrence,
  SubEvent,
  WizardStep,
} from "../interface";
import { WizardCourse } from "../controllers/Wizard/WizardCourse";
import { WizardBasic } from "../controllers/WizardBasic/WizardBasic";
import { WizardTickets } from "../controllers/WizardTickets/WizardTickets";
import { Article } from "../components";
import axios from "axios";

interface MatchParams {
  slug: string;
}

const Event: FC<RouteComponentProps<MatchParams>> = ({ match }) => {
  const coreDispatch = useDispatch<CoreThunkDispatch>();
  const frontDispatch = useDispatch<FrontThunkDispatch>();
  const globalDispatch = useDispatch<any>();

  const dispatch = useDispatch();

  const event: IEvent = useSelector<AppState, IEvent>((s) => {
    return s.core.selectedEvent;
  });

  const appState: string = useSelector<AppState, string>((s) => {
    return s.core.appState;
  });

  const eventCourses: SubEvent[] = useSelector<AppState, SubEvent[]>((s) => {
    return s.core.SubEvents;
  });

  const eventOccurrences: Occurrence[] = useSelector<AppState, Occurrence[]>(
    (s) => {
      return s.core.occurrences;
    }
  );

  const subEventOccurrences: Occurrence[] = useSelector<AppState, Occurrence[]>(
    (s) => {
      return s.core.subEventOccurrences;
    }
  );

  const eventSteps: EventSteps = useSelector<AppState, EventSteps>((s) => {
    return s.core.selectedEventSteps;
  });

  const wizardStep = useSelector<AppState, number>((s) => {
    return s.front.wizardStep;
  });

  const update = useSelector<AppState, string>((s) => {
    return s.front.update;
  })

  const isAdmin = useSelector<AppState, boolean>((s) => {
    return s.front.isAdmin;
  })

  const [slug, setSlug] = useState<string>("");

  useEffect(() => {
    coreDispatch(coreActionCreators.setCurrentEventWithSlug(match.params.slug));
  }, [update]);

  useEffect(() => {
    if (event.slug !== "" && event.slug !== match.params.slug) {
      dispatch({ type: "RESET_STORE" });
      localStorage.clear();
      frontDispatch(frontActionCreators.setFrontValue(["update"], "updated event"))
      window.location.reload();
    } else if (wizardStep === WizardStep.Confirmation) {
      dispatch({ type: "RESET_STORE" });
      localStorage.clear();
      frontDispatch(frontActionCreators.setFrontValue(["update"], "updated event"))
    }
  }, [event]);

  function removeParam(key: string, sourceURL : string) {
    var rtn = sourceURL.split("?")[0],
        param,
        params_arr = [],
        queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
    if (queryString !== "") {
        params_arr = queryString.split("&");
        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
            param = params_arr[i].split("=")[0];
            if (param === key) {
                params_arr.splice(i, 1);
            }
        }
        if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
    }
    return rtn;
}

useEffect(() => {
  let qs = require("qs");
  let CryptoJS = require("crypto-js");

  let params = qs.parse(window.location.search, {
    parameterLimit: 2,
    ignoreQueryPrefix: true,
  });

  

  if(params.hasOwnProperty("admin")) {
    let key = CryptoJS.enc.Utf8.parse('lillestromkommun');//The key must be 16 bits and avoid using reserved characters
    let encryptedHexStr  = CryptoJS.enc.Hex.parse(params.admin);
    let encryptedBase64Str  = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    let decryptedData  = CryptoJS.AES.decrypt(encryptedBase64Str, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    let text = decryptedData.toString(CryptoJS.enc.Utf8);

    console.log(parseInt(text));
    console.log(new Date().getTime());
    if(parseInt(text) > new Date().getTime()) {
      console.log("here");
      frontDispatch(frontActionCreators.setFrontValue(["isAdmin"], true));
    } else {
      frontDispatch(frontActionCreators.setFrontValue(["isAdmin"], false));
    }
  } else {
    frontDispatch(frontActionCreators.setFrontValue(["isAdmin"], false));
  }

}, [])

  useEffect(() => {
    let qs = require("qs");

    let params = qs.parse(window.location.search, {
      parameterLimit: 2,
      ignoreQueryPrefix: true,
    });

    if(params.hasOwnProperty("orderId")) {
      console.log(window.location.reload);
      let location = removeParam("orderId", window.location.href);
      window.history.replaceState(null, "", location);
      axios.get(`${API_BASE_URL}/payments/user/${params.orderId}`).then((response) => {
        if(response.status === 200) {
          dispatch({ type: "RESET_STORE" });
          frontDispatch(frontActionCreators.setFrontValue(["update"], "updated event"))

          let data = JSON.parse(response.data.metadata);
          frontDispatch(frontActionCreators.setFrontValue(["orderId"], params.orderId));
          frontDispatch(frontActionCreators.setFrontValue(["userForm"], data.userForm));
          frontDispatch(frontActionCreators.setFrontValue(["storeOccurrences"], data.storeOccurrences));
          frontDispatch(frontActionCreators.setFrontValue(["tickets"], data.tickets));
          frontDispatch(frontActionCreators.setFrontValue(["signees"], data.signees));
          frontDispatch(frontActionCreators.setFrontValue(["payLater"], data.payLater));
          frontDispatch(frontActionCreators.setFrontValue(["consentId"], data.consentId));
          frontDispatch(frontActionCreators.setFrontValue(["consentUrl"], data.consentUrl));
          frontDispatch(frontActionCreators.setFrontValue(["wizardStep"], WizardStep.Confirm));
        } else {
          console.log(`Order id: ${params.orderId} does not exist`);
        }
      }).catch((e) => {
        console.log(e);
      });
    }
    
  }, []);

  useEffect(() => {
    if (event.id) {
      switch (event.type) {
        case EventType.Simple:
          coreDispatch(coreActionCreators.setCurrentEventOccurrences(event.id));

        case EventType.Course:
          coreDispatch(coreActionCreators.setCurrentEventSubEvents(event.id));
          coreDispatch(
            coreActionCreators.setAllOccurrencesForSubEvent(event.id)
          );

        case EventType.Tickets:
          coreDispatch(coreActionCreators.setCurrentEventSubEvents(event.id));
          coreDispatch(
            coreActionCreators.setAllOccurrencesForSubEvent(event.id)
          );
      }

      coreDispatch(coreActionCreators.loadCurrentEventSteps(event.id));
      frontDispatch(frontActionCreators.setFrontValue(["eventId"], event.id));
    }
  }, [event]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [wizardStep]);

  if (event.id == "") {
    return <lk-spinner text="Laster inn siden..."></lk-spinner>;
  }
  if (
    (event.closed || new Date(event.registrationTo).getTime() <= new Date().getTime()) && !isAdmin
  ) {
    return (
      <Article
        title={eventSteps.finish.title}
        preamble={eventSteps.finish.ingress}
      >
        <div dangerouslySetInnerHTML={{ __html: eventSteps.finish.body }} />
      </Article>
    );
  } else if (
    new Date(event.registrationFrom).getTime() >= new Date().getTime()
  ) {
    return (
      <Article title={"Arrangementet har ikke startet"} preamble={""}>
        <p>
          {"Arrangementet er ikke åpnet for registrering. Kom tilbake senere"}
        </p>
      </Article>
    );
  } else {
    switch (event.type) {
      case EventType.Simple:
        return (
          <>
            {appState !== "loadingSucceeded" && (
              <lk-spinner text="Laster inn siden..."></lk-spinner>
            )}
            <WizardBasic
              event={event}
              occurrences={eventOccurrences}
              steps={eventSteps}
            />
          </>
        );
      case EventType.Course:
        return (
          <>
            {appState !== "loadingSucceeded" && (
              <lk-spinner text="Laster inn siden..."></lk-spinner>
            )}
            <WizardCourse
              event={event}
              subEvents={eventCourses}
              occurrences={subEventOccurrences}
              steps={eventSteps}
            />
          </>
        );
      case EventType.Tickets:
        return (
          <>
            {appState !== "loadingSucceeded" && (
              <lk-spinner text="Laster inn siden..."></lk-spinner>
            )}
            <WizardTickets
              event={event}
              subEvents={eventCourses}
              occurrences={subEventOccurrences}
              steps={eventSteps}
            />
          </>
        );

      default:
        return <p>Her finnes ikke noe arrangement, sjekk om url er riktig.</p>;
    }
  }
};

export default Event;
