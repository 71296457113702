import {
  IEvent,
  Occurrence,
  SubEvent,
  ApplicantDetails,
} from "../../interface";

export interface ICoreState {
  events: IEvent[];
  appState: string;
  orgId: string;
  selectedEvent: IEvent;
  selectedEventSteps: any;
  occurrences: Occurrence[];
  SubEvents: SubEvent[];
  subEventOccurrences: Occurrence[];
  applicantDetails: ApplicantDetails;
}

export const coreActionTypes = {
  REQUEST_LOAD_EVENTS: "REQUEST_LOAD_EVENTS",
  CORE_SET_VALUE: "CORE_SET_VALUE",
  SET_LOADED_EVENTS: "SET_LOADED_EVENTS",
  SET_APP_STATE: "SET_APP_STATE",
  SET_CURRENT_EVENT: "SET_CURRENT_EVENT",
  LOAD_CURRENT_EVENT: "LOAD_CURRENT_EVENT",
  SET_CURRENT_EVENT_STEPS: "SET_CURRENT_EVENT_STEPS",
  SET_CURRENT_EVENT_OCCURRENCES: "SET_CURRENT_EVENT_OCCURRENCES",
  SET_CURRENT_EVENT_SUBEVENTS: "SET_CURRENT_EVENT_SUBEVENTS",
  SET_CURRENT_EVENT_SUBEVENTS_OCCURRENCES:
    "SET_CURRENT_EVENT_SUBEVENTS_OCCURRENCES",
  SET_APPLICANT_DETAILS: "SET_APPLICANT_DETAILS",
};

export interface loadCurrentEvent {
  type: typeof coreActionTypes.LOAD_CURRENT_EVENT;
}

export interface setCurrentEventSteps {
  type: typeof coreActionTypes.SET_CURRENT_EVENT;
  payload: any;
  appState: string;
}

export interface setApplicantDetails {
  type: typeof coreActionTypes.SET_APPLICANT_DETAILS;
  payload: ApplicantDetails;
  appState: string;
}

export interface setCurrentEventOccurrences {
  type: typeof coreActionTypes.SET_CURRENT_EVENT_OCCURRENCES;
  payload: Occurrence[];
  appState: string;
}

export interface setCurrentEventSubEvents {
  type: typeof coreActionTypes.SET_CURRENT_EVENT_SUBEVENTS;
  payload: SubEvent[];
  appState: string;
}

export interface setCurrentSubEventsOccurrences {
  type: typeof coreActionTypes.SET_CURRENT_EVENT_SUBEVENTS_OCCURRENCES;
  payload: Occurrence[];
  appState: string;
}

export interface setCurrentEvent {
  type: typeof coreActionTypes.SET_CURRENT_EVENT;
  payload: IEvent;
  appState: string;
}

export interface setAppState {
  type: typeof coreActionTypes.SET_APP_STATE;
  payload: string;
}

export interface setCoreValue {
  type: typeof coreActionTypes.CORE_SET_VALUE;
  payload: any;
  key: any[];
}

export interface requestLoadEvents {
  type: typeof coreActionTypes.REQUEST_LOAD_EVENTS;
  payload: any;
}

export interface setLoadedEvents {
  type: typeof coreActionTypes.SET_LOADED_EVENTS;
  payload: IEvent[];
  appState: string;
}

export type CoreActionTypes =
  | setLoadedEvents
  | requestLoadEvents
  | setCoreValue
  | setAppState
  | setCurrentEvent
  | loadCurrentEvent;
