import React, { FC } from "react";
import { useCrossTabState } from "../../lib/hooks";
import { DateTime } from "luxon"; 

interface IConsentProps {
    url: string;
    status: string;
}

export const Consent:FC<IConsentProps> = ({url, status})  => {
    const options: any = { dateStyle: "full", timeStyle: "short" };
    const [consentTimestamp, setConsentTimestamp] = useCrossTabState("consentTimestamp", "");

    let qs = require("qs");
    let params = qs.parse(window.location.search, {
      parameterLimit: 2,
      ignoreQueryPrefix: true,
    });

    if(params.hasOwnProperty("cs")) {
      let location = removeParam("cs",removeParam("ci", window.location.href));

      console.log("location", location);
      window.history.replaceState(null, "", location);
      localStorage.setItem("consentStatus", params.cs); 
      localStorage.setItem("consentTimestamp", Date.now().toString());
    }

    function removeParam(key: string, sourceURL : string) {
      var rtn = sourceURL.split("?")[0],
          param,
          params_arr = [],
          queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
      if (queryString !== "") {
          params_arr = queryString.split("&");
          for (var i = params_arr.length - 1; i >= 0; i -= 1) {
              param = params_arr[i].split("=")[0];
              if (param === key) {
                  params_arr.splice(i, 1);
              }
          }
          if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
      }
      return rtn;
  }

    function given(): boolean {
        return status !== "" && status !== "-1";
    }

    return (
    <>
        <div style={{ pointerEvents : given() ? "none" : "inherit" }} > 
            <label className="checkbox" htmlFor="confirm-consent">
              <input
                type="checkbox"
                id="confirm-consent"
                name="confirm-consent"
                onClick={() => {
                  if (!given()) {window.open(url, "_self")}
                }}
                checked={ given() }
                disabled={ given() }
                aria-disabled={given()}
                tabIndex={-1}
              />
              <span className="checkbox__label">
                <a className="external-link" tabIndex={0}>
                  Gi samtykke &nbsp;

                  { !given() && (
                      <>
                      (innlogging via ID-porten)
                    <svg
                        aria-hidden="true"
                        focusable="false"
                        role="presentation"
                        width="11"
                        height="11"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                        d="M1 10l9-9m0 8.357V1H1.643"
                        strokeWidth="1.5"
                        fill="none"
                        fillRule="evenodd"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        />
                    </svg>
                  </>
                  )}
                </a>
              </span>
            </label>
            <br></br>

            { !given() && (
            <p className="error">Du må gi samtykke for å kunne melde deg på.</p>
            )}

            {given() && (
                <label>Vi har mottatt ditt samtykke, { DateTime.fromMillis(Number.parseInt(consentTimestamp)).setLocale("nb").toLocaleString(DateTime.DATETIME_MED) }.</label>
            )}
        </div>
    </>
    );

};