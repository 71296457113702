import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Article } from "../../components";
import OccurrenceList from "../../components/OccurrenceList/OccurrenceList";
import {
  IEvent,
  EventSteps,
  Occurrence,
  ApplicantType,
  WizardStep,
} from "../../interface";
import { AppState } from "../../store";
import { BasicAbout } from "./BasicAbout";
import { Confirmation } from "../shared/Confirmation";
import { Parents } from "../shared/Parents";
import { BasicConfirm } from "../WizardBasic/BasicConfirm";

interface IWizardProps {
  event: IEvent;
  steps: EventSteps;
  occurrences: Occurrence[];
}

export const WizardBasic: FC<IWizardProps> = ({
  event,
  steps,
  occurrences,
}) => {
  const wizardStep = useSelector<AppState, number>((s) => {
    return s.front.wizardStep;
  });

  return (
    <div>
      {wizardStep === WizardStep.ChooseEventOccurrence && (
        <Article title={event.name} preamble={event.ingress}>
          {event.photo !== null && event.photo !== "" && (
            <img src={event.photo} style={{ marginBottom: "1rem" }} />
          )}
          <div dangerouslySetInnerHTML={{ __html: event.about }} />
          <OccurrenceList occurrences={occurrences} event={event} />
        </Article>
      )}
      {wizardStep === WizardStep.About && (
        <BasicAbout
          title={event.name}
          content={event.about}
          ingress={event.ingress}
          applicantTitle={steps.applicant.title}
          applicantDescription={steps.applicant.description}
          applicantNotice={steps.applicant.notice}
        />
      )}
      {wizardStep === WizardStep.Parents && (
        <Parents
          title={event.name}
          content={event.about}
          ingress={event.ingress}
        />
      )}
      {wizardStep === WizardStep.Confirm && (
        <BasicConfirm
          title={event.name}
          content={event.about}
          ingress={event.ingress}
        />
      )}
      {wizardStep === WizardStep.Confirmation && (
        <Confirmation
          title={steps.confirmation.title}
          ingress={steps.confirmation.ingress}
          content={steps.confirmation.content}
        />
      )}
    </div>
  );
};
