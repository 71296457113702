import React, { FC, useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import {
  frontActionCreators,
  FrontThunkDispatch,
} from "../../store/Front/front.actions";
import changeFormText from "../shared/ChangeFormText";
import {
  EventSteps,
  IEvent,
  Occurrence,
  Parent,
  Signee,
  WizardStep,
} from "../../interface";
import checkForm from "../shared/CheckForm";
import checkFormStudentParents from "../shared/CheckFormStudentParents";
import checkFormTicketSignees from "./CheckFormTicketSignees";
import InputRequired from "../shared/InputRequired";
import ErrorField from "./ErrorFieldTickets";

interface ISingleFormProps {}

export const SigneesForm: FC<ISingleFormProps> = ({}) => {
  const frontDispatch = useDispatch<FrontThunkDispatch>();
  const eventId: string = useSelector<AppState, string>((s) => {
    return s.front.eventId;
  });

  const wizardStep: number = useSelector<AppState, number>((s) => {
    return s.front.wizardStep;
  });

  const occurrences: Occurrence[] = useSelector<AppState, Occurrence[]>((s) => {
    return s.front.storeOccurrences;
  });

  const userForm: any = useSelector<AppState, any>((s) => {
    return s.front.userForm;
  });

  const storeSignees: Signee[] = useSelector<AppState, Signee[]>((s) => {
    return s.front.signees;
  });

  const event: IEvent = useSelector<AppState, IEvent>((s) => {
    return s.core.selectedEvent;
  });

  const steps: EventSteps = useSelector<AppState, EventSteps>((s) => {
    return s.core.selectedEventSteps;
  });

  const storeContactSignee: boolean = useSelector<AppState, boolean>((s) => {
    return s.front.contactSignee;
  });

  const isAdmin: boolean = useSelector<AppState, boolean>((s) => {
    return s.front.isAdmin;
  })

  const [form, setForm] = useState<any>(userForm);
  let [signees, setSignees] = useState<Signee[]>(storeSignees);
  let [error, setError] = useState<string>("");
  let [max, setMax] = useState<number>(10);
  let [buttonLoading, setButtonLoading] = useState<string>("");

  let [contactSignee, setContactSignee] = useState<boolean>(storeContactSignee);
  let arrayOfStrings = ["firstName", "lastName"].concat(steps.tickets.contactsRegistrationFields);

  const _ = require("lodash");

  function addSignee() {
    if (signees.length >= max) {
      return;
    }

    const newArr: any = [...signees];
    newArr.push(signeesInfo());
    setSignees(newArr);
    setError("");
  }

  function updateSignees(text: string, value: string, index: number) {
    const newArr: any = [...signees];
    newArr[index][text] = value;
    setSignees(newArr);
  }

  function removeSignee(index: number) {
    const newArr: any = [...signees];
    newArr.splice(index, 1);
    setSignees(newArr);
    setError("");
  }

  function goToRegisterTickets() {
    axios
      .get(`${API_BASE_URL}/events/${occurrences[0].id}/applications/amount`)
      .then((response) => {
        if (response.data >= signees.length || isAdmin) {
          frontDispatch(
            frontActionCreators.setFrontValue(["signees"], signees)
          );
          frontDispatch(
            frontActionCreators.setFrontValue(["contactSignee"], contactSignee)
          );
          if (_.isEmpty(steps.tickets.pricingGroups)) {
            frontDispatch(
              frontActionCreators.setFrontValue(
                ["wizardStep"],
                WizardStep.Confirm
              )
            );
          } else {
            frontDispatch(
              frontActionCreators.setFrontValue(
                ["wizardStep"],
                WizardStep.Tickets
              )
            );
          }
        } else {
          setMax(response.data);
          setError(
            `Du har registrert for mange(${signees.length}) enn det er plass til(${response.data}), endre på antall plasser eller bytt hendelse.`
          );
        }
      });
  }

  function signeesInfo(withInfo: boolean = false) {
    let signees: any = {};
    
    if(withInfo) {
      arrayOfStrings.forEach((s: any) => {
        if(form[s] === undefined) {
          
          signees[s] = "";
        } else {
          signees[s] = form[s];
        }
      })
  
      return signees;
    } else {
      arrayOfStrings.forEach((s: any) => {
          signees[s] = "";
      })
  
      return signees;
    }
  }

  function contactInSignees() {
    if (!contactSignee) {
      let newSignees = signees;
      if (
        signees.length <= 1 &&
        signees[0].firstName == "" &&
        signees[0].lastName == ""
      ) {
        setSignees([signeesInfo(true)]);
      } else {
        newSignees.unshift(signeesInfo(true));
        setSignees(newSignees);
      }
    } else {
      if (signees.length === 1) {
        setSignees([signeesInfo()]);
      } else {
        let newSignees = signees;
        newSignees.shift();
        setSignees(newSignees);
      }
    }

    setContactSignee(!contactSignee);
  }

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/events/${occurrences[0].id}/applications/amount`)
      .then((response) => {
        if (response.data > steps.tickets.maximumTicketsPerApplication || isAdmin) {
          setMax(steps.tickets.maximumTicketsPerApplication);
        } else {
          setMax(response.data);
        }
      });
  }, []);

  useEffect(() => {
    if (signees.length === 0) {
      setSignees([
        signeesInfo()
      ]);
    }
  }, [arrayOfStrings]);
  return (
    <>
      <div>
        <div className="parentPadding">
          <h2>
            {steps.tickets.applicantsFormTitle} (maks {max})
          </h2>
        </div>
        <label
          className="interactive-checkbox interactive-checkbox-trigger"
          htmlFor={"user"}
          style={{
            backgroundColor: "#f0f5fc",
            borderStyle: "solid",
            borderColor: "#e4e6ec",
          }}
        >
          <input
            type="checkbox"
            id={"user"}
            name={"user"}
            checked={contactSignee}
            onChange={() => contactInSignees()}
          />
          <span className="interactive-checkbox__span">
            <svg
              aria-hidden="true"
              focusable="false"
              role="presentation"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 27.4 27.4"
              xmlSpace="preserve"
            >
              <path
                className="interactive-checkbox__path"
                d="M24.6,25.5H2.7c-0.5,0-0.9-0.4-0.9-0.9V2.7c0-0.5,0.4-0.9,0.9-0.9h21.9c0.5,0,0.9,0.4,0.9,0.9v21.9
                     C25.5,25.1,25.1,25.5,24.6,25.5z"
              ></path>

              <path
                className="interactive-checkbox__path-checked path-checked"
                d="M24.6,25.5H2.7c-0.5,0-0.9-0.4-0.9-0.9V2.7c0-0.5,0.4-0.9,0.9-0.9h21.9c0.5,0,0.9,0.4,0.9,0.9v21.9
                     C25.5,25.1,25.1,25.5,24.6,25.5z"
              ></path>
              <path
                className="interactive-checkbox__path-checked-tick path-checked"
                d="M8,14.2l2.4,3.5c0.3,0.5,1,0.6,1.5,0.3c0.1-0.1,0.2-0.1,0.3-0.2L21,6.8"
              ></path>
            </svg>
            Inkluder kontaktperson
          </span>
        </label>
        {signees.map((p: any, index) => {
          return (
            <div className="parentPadding" key={index}>
              <div className="parentTitle">
                <h3>Person {index + 1}</h3>
                {index > 0 && (
                  <button
                    className="button button--secondary removeButton"
                    onClick={() => removeSignee(index)}
                  >
                    <span className="button__text">
                      <strong>&times;</strong>
                    </span>
                  </button>
                )}
              </div>
              {Object.keys(p).map((o) => changeFormText(o, event.applicantType, wizardStep)).sort((a, b) => (a.order > b.order) ? 1 : -1).map((field) => {
                return (
                  <div className="input-block input-block--required">
                  <label>
                      { field.text }
                      {field.id !== "notes" &&
                        <span className="title-required-sign"></span>
                      }
                  </label>
                  <input
                    className={`${ErrorField(field.id, p[field.id])}`}
                    placeholder={field.placeholder}
                    type={field.type}
                    id={field.id + index}
                    name={field.id + index}
                    required={field.id !== "notes"}
                    value={p[field.id]}
                    onChange={(s) =>
                      updateSignees(field.id, s.target.value, index)
                    }
                    autoComplete={"" + Math.random()}
                  />
                </div>
                );
              })}
            </div>
          );
        })}
        <p className="error">{error}</p>

        <p>{steps.applicant.notice}</p>

        <button
          className="button button--secondary addParentButton"
          onClick={addSignee}
          disabled={signees.length >= max}
        >
          <span className="button__text">Legg til person</span>
        </button>
        <div className="spacebetween">
          <button
            className="button button--secondary emptyButton"
            onClick={() =>
              frontDispatch(
                frontActionCreators.setFrontValue(
                  ["wizardStep"],
                  WizardStep.About
                )
              )
            }
          >
            <span className="button__text">Tilbake</span>
          </button>
          <button
            className={"button button--primary " + buttonLoading}
            onClick={goToRegisterTickets}
            disabled={
              !checkFormTicketSignees(signees) ||
              buttonLoading === "button--loading"
            }
          >
            <span className="button__text">Fortsett</span>
          </button>
        </div>
      </div>
    </>
  );
};
