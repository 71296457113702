export default function checkFormStudentSchool(form: any) {
  let regex = /^(0[1-9]|[1-2][0-9]|31(?!(?:0[2469]|11))|30(?!02))(0[1-9]|1[0-2])\d{7}$/;
  let regexSSN = new RegExp(regex);

  if (
    (form["school"] !== undefined && form["school"] === "") ||
    (form["ssn"] !== undefined && !regexSSN.test(form["ssn"]))
  ) {
    return false;
  } else {
    return true;
  }
}
