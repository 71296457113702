export default function ErrorField(item: string, value: string) {
  const regexPhone = new RegExp(/^((0047)?|(\+47)?)[4|9]\d{7}$/);
  const regexEmail = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  const regexSSN = new RegExp(
    /^(0[1-9]|[1-2][0-9]|31(?!(?:0[2469]|11))|30(?!02))(0[1-9]|1[0-2])\d{7}$/
  );
  const regexZip = new RegExp(/^[0-9]{4}$/);

  switch (item) {
    case "telephone":
      if (!regexPhone.test(value)) {
        return "errorBorder";
      }
      return "";
    case "email":
      if (!regexEmail.test(value)) {
        return "errorBorder";
      }
      return "";
    case "firstName":
      if (value === "") {
        return "errorBorder";
      }
      return "";
    case "lastName":
      if (value === "") {
        return "errorBorder";
      }
      return "";
    case "ssn":
      if (!regexSSN.test(value)) {
        return "errorBorder";
      }
      return "";

    case "zip":
      if(value && value.length > 0) {
        if (!regexZip.test(value)) {
          return "errorBorder";
        }
      }
      return "";
      
    case "orgNumber":
      if(value && value.length > 0) {
        const NoValidation = require('novalidation');
        var valid = NoValidation.orgNummer(value);

        if(!valid) {
          return "errorBorder";
        }

      }
      return "";

    default:
      return "";
  }
}
