export interface IEvent {
  id: string;
  name: string;
  slug: string;
  type: EventType;
  groupFilters: boolean;
  applicantType: ApplicantType;
  registrationFields: string[];
  startDate?: Date;
  ingress: string;
  about: string;
  closed: Boolean;
  automaticApproval: Boolean;
  hasSubEvents: Boolean;
  minimumOccurrencesRegistration: number;
  maximumOccurrencesRegistration: number;
  title: string;
  description: string;
  filters: string[];
  registrationFrom: Date;
  registrationTo: Date;
  photo: string;
  hasWaitingList: Boolean;
  dateView: Boolean;
  shownOccurrences: number;
  fullyBookedMessage: string;
  controlField: string;
  notesTitle: string;
}

export interface SubEvent {
  id: string;
  name: string;
  about: string;
  order: number;
  capacity: number;
  type: EventType;
  parentEventID: string;
  filters: string[];
}

export enum ApplicantType {
  Basic = 1,
  Organization = 2,
  Student = 3,
  Tickets = 4,
}

export interface ApplicantDetails {
  application: Application;
  occurrence: Occurrence;
  event: IEvent;
  sub: SubEvent;
}

export interface Application {
  firstName: string;
  lastName: string;
  email: string;
  telephone: string;
  status: ApplicantStatus;
  ssn: string;
  lastSentEmail: Date;
}

export interface Message {
  Title: string;
  Ingress: string;
  Content: string;
}

export enum ApplicantStatus {
  Applied = 0,
  Accepted = 1,
  WaitingList = 2,
  Enrolled = 3,
  WillReceiveOffer = 4,
  OfferedEnrollment = 5,
  OfferedWaitingList = 6,
  ApplicantAcceptedOffer = 7,
  ApplicantRejectedOffer = 8,
  MissedOffer = 10,
}

export interface Parent {
  firstName: string;
  lastName: string;
  email: string;
  telephone: string;
}

export interface Signee {
  firstName: string;
  lastName: string;
  telephone?: string;
  email?: string;
  zip?: string;
  address?: string;
  city?: string;
  notes?: string;
}

export enum EventType {
  Simple = 1,
  Course = 4,
  Tickets = 5,
}

export interface EventSteps {
  applicant: {
    title: string;
    description: string;
    notice: string;
  };
  confirmation: {
    title: string;
    ingress: string;
    content: string;
  };
  summary: {
    description: string;
    action: string;
    title: string;
  };
  tickets: {
    maximumTicketsPerApplication: number;
    pricingGroups: object;
    paymentType: PaymentType;
    paymentNotRequired: boolean;
    applicantsFormTitle: string;
    pricingGroupsTitle: string;
    contactsRegistrationFields: string[];
  };
  parents: {
    max: 0;
    terms: string;
    description: string;
    addToButtonText: string;
    formOverview: string;
    formConfirmation: string;
    title: string;
  };
  consent: {
    consentType: string;
    title: string;
  };
  finish: {
    title: string;
    body: string;
    ingress: string;
  };
}

export enum PaymentType {
  None = 0,
  Vipps = 1,
}

export interface Occurrence {
  parentEventID: string;
  date: Date;
  capacity: number;
  booked: number;
  id: string;
  name: string;
  closed: Boolean;
  isInternal: boolean;
  hideRegisteredAmount: boolean;
}

export interface School {
  id: string;
  name: string;
  administrativeUnit: Boolean;
}

export enum WizardStep {
  ChooseEventOccurrence = 1,
  About = 2,
  Parents = 3,
  Signees = 4,
  Tickets = 5,
  Confirm = 6,
  Confirmation = 7,
}

export interface Ticket {
  name: string;
  price: number;
  amount: number;
}
