import React, { FC } from "react";
import { useSelector } from "react-redux";
import { IEvent, Occurrence } from "../../interface";
import { AppState } from "../../store";
import { Article } from "../../components";
import { TicketForm } from "./TicketForm";

interface IAboutProps {
  ingress: string;
  content: string;
  title: string;
  applicantTitle: string;
  applicantDescription: string;
  applicantNotice: string;
  occurrences: any;
}

export const TicketAbout: FC<IAboutProps> = ({
  ingress,
  content,
  title,
  applicantTitle,
  applicantDescription,
  applicantNotice,
  occurrences,
}) => {
  const event: IEvent = useSelector<AppState, IEvent>((s) => {
    return s.core.selectedEvent;
  });

  return (
    <div>
      <Article title={title} preamble={ingress}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
        <TicketForm
          registrationFields={event.registrationFields}
          title={applicantTitle}
          description={applicantDescription}
          notice={applicantNotice}
        />
      </Article>
    </div>
  );
};
