import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EventSteps,
  IEvent,
  Signee,
  Ticket,
  WizardStep,
} from "../../interface";
import { AppState } from "../../store";
import { Article } from "../../components";
import { SigneesForm } from "./SigneesForm";
import {
  frontActionCreators,
  FrontThunkDispatch,
} from "../../store/Front/front.actions";
import "./TicketRegister.scss";
import { Link } from "react-router-dom";

interface IRegisterProps {
  ingress: string;
  content: string;
  title: string;
  ticketsStep: object;
}

export const TicketRegister: FC<IRegisterProps> = ({
  ingress,
  content,
  title,
  ticketsStep,
}) => {
  const frontDispatch = useDispatch<FrontThunkDispatch>();

  const signees: Signee[] = useSelector<AppState, Signee[]>((s) => {
    return s.front.signees;
  });

  const storeTickets: Ticket[] = useSelector<AppState, Ticket[]>((s) => {
    return s.front.tickets;
  });

  const steps: EventSteps = useSelector<AppState, EventSteps>((s) => {
    return s.core.selectedEventSteps;
  });

  const storePayLater: boolean = useSelector<AppState, boolean>((s) => {
    return s.front.payLater;
  });

  const [tickets, setTickets] = useState<Ticket[]>(storeTickets);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [payLater, setPayLater] = useState<boolean>(storePayLater);

  useEffect(() => {
    if (tickets.length === 0) {
      const newTickets: Ticket[] = [];
      Object.entries(ticketsStep).map((value) => {
        newTickets.push({
          name: value[0],
          price: value[1],
          amount: 0,
        });
      });
      setTickets(newTickets);
    }
    updateTotalPriceAndAmount();
  }, [tickets]);

  function updateTickets(index: number, value: number) {
    const newArr: Ticket[] = [...tickets];
    newArr[index].amount = value;
    setTickets(newArr);
    updateTotalPriceAndAmount();
  }

  function updateTotalPriceAndAmount() {
    let price = 0;
    let amount = 0;
    tickets.map((value) => {
      if (value.amount) {
        if (!payLater) {
          price += value.price * value.amount;
        }
        amount += value.amount;
      }
    });
    setTotalPrice(price);
    setTotalAmount(amount);
  }

  function checkPayLater() {
    setPayLater(!payLater);
  }

  useEffect(() => {
    updateTotalPriceAndAmount();
  }, [payLater]);

  function goToConfirmTicket() {
    let confirmTickets = tickets;
    confirmTickets.forEach((v) => {
      if (!v.amount) {
        v.amount = 0;
      }
    });
    frontDispatch(frontActionCreators.setFrontValue(["payLater"], payLater));

    frontDispatch(
      frontActionCreators.setFrontValue(["tickets"], confirmTickets)
    );
    frontDispatch(
      frontActionCreators.setFrontValue(["wizardStep"], WizardStep.Confirm)
    );
  }

  return (
    <div>
      <Article title={title} preamble={ingress}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
        <div className="parentPadding">
          <h2>{steps.tickets.pricingGroupsTitle}</h2>
          <p>Antall påmeldte: {signees.length}</p>
          <p>
            <a
              className="external-link external-link__underline"
              href={`${CLIENT_BASE_URL}/salgsbetingelser`}
              target="_blank"
            >
              Salgsbetingelser
            </a>
          </p>
        </div>
        {tickets.sort((a, b) => b.price - a.price).map((value, index) => {
          return (
            <div className="grid prices">
              <div className="span-three-quarters priceText">
                <p>
                  {value.name} (kr {value.price})
                </p>
              </div>
              <div className="span-quarter priceValue">
                <div className="input-block input-block--required" key={index}>
                  <input
                    placeholder={"0"}
                    type="number"
                    onKeyDown={(evt) => {
                      ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault();
                    }}
                    min="0"
                    id={`${index}`}
                    name={value.name}
                    value={value.amount}
                    required
                    onChange={(s) =>
                      updateTickets(index, parseInt(s.target.value))
                    }
                  />
                </div>
              </div>
            </div>
          );
        })}
        {steps.tickets.paymentNotRequired && (
          <label
            className="interactive-checkbox interactive-checkbox-trigger"
            htmlFor={"paymentNotRequired"}
            style={{ backgroundColor: "#f0f5fc" }}
          >
            <input
              type="checkbox"
              id={"paymentNotRequired"}
              name={"paymentNotRequired"}
              checked={payLater}
              onChange={() => checkPayLater()}
            />
            <span className="interactive-checkbox__span">
              <svg
                aria-hidden="true"
                focusable="false"
                role="presentation"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 27.4 27.4"
                xmlSpace="preserve"
              >
                <path
                  className="interactive-checkbox__path"
                  d="M24.6,25.5H2.7c-0.5,0-0.9-0.4-0.9-0.9V2.7c0-0.5,0.4-0.9,0.9-0.9h21.9c0.5,0,0.9,0.4,0.9,0.9v21.9
                   C25.5,25.1,25.1,25.5,24.6,25.5z"
                ></path>

                <path
                  className="interactive-checkbox__path-checked path-checked"
                  d="M24.6,25.5H2.7c-0.5,0-0.9-0.4-0.9-0.9V2.7c0-0.5,0.4-0.9,0.9-0.9h21.9c0.5,0,0.9,0.4,0.9,0.9v21.9
                   C25.5,25.1,25.1,25.5,24.6,25.5z"
                ></path>
                <path
                  className="interactive-checkbox__path-checked-tick path-checked"
                  d="M8,14.2l2.4,3.5c0.3,0.5,1,0.6,1.5,0.3c0.1-0.1,0.2-0.1,0.3-0.2L21,6.8"
                ></path>
              </svg>
              Ønsker ikke å betale med Vipps
            </span>
          </label>
        )}
        <br></br>
        <br></br>
        <p>
          <strong>Total pris: kr {totalPrice}</strong>
        </p>
        {!(signees.length === totalAmount) && (
          <p className="error">
            Antall personer stemmer ikke med antall billetter
          </p>
        )}
        <br></br>
        <div className="spacebetween">
          <button
            className="button button--secondary emptyButton"
            onClick={() =>
              frontDispatch(
                frontActionCreators.setFrontValue(
                  ["wizardStep"],
                  WizardStep.Signees
                )
              )
            }
          >
            <span className="button__text">Tilbake</span>
          </button>

          <button
            className="button button--primary"
            onClick={goToConfirmTicket}
            disabled={!(signees.length === totalAmount)}
          >
            <span className="button__text">Fortsett</span>
          </button>
        </div>
      </Article>
    </div>
  );
};
