import "./Navbar.scss";

import React from "react";

const Navbar = () => {
  return (
    <header className="site-header">
      <nav className="site-header__wrapper container">
        <div className="site-header__logo">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 750 205"
            aria-hidden="true"
            role="presentation"
            className="site-header__logo__icon"
          >
            <title>Lillestrøm kommune</title>
            <path d="M238.1-0.6h11.3v70.7H286v10.1h-47.9V-0.6z" />
            <path d="M308.9,10.6h-11.2V-0.6h11.2V10.6z M298,80.2V19.6h10.7v60.6H298z" />
            <path d="M328.7,80.2V-0.6h10.7v80.8H328.7z" />
            <path d="M359.5,80.2V-0.6h10.7v80.8H359.5z" />
            <path d="M413.1,18.2c17.3,0,25.9,12.9,25.9,29v5.7h-40.7v6.4c0,8.8,7.4,13.8,15,13.8s13.9-3.8,14.6-12.1h10.7 c-1.1,13-12.5,20.7-25.4,20.7c-17.5,0-26.1-13.2-26.1-31.7C387,31.5,396.5,18.2,413.1,18.2z M398.3,44.3H428V41 c0-8.9-6.9-14.3-14.8-14.3S398.4,32,398.4,41v3.3H398.3z" />
            <path d="M482.1,45.9c12,2.5,17.8,8.1,17.8,17.8c0,10-9.7,17.9-24,17.9c-13.7,0-24.4-7-24.6-20.6H462c0.2,8.1,6.7,12,14,12 c7.1,0,13.2-3.3,13.2-9.3c0-4.9-2.7-7.7-10.8-9.3l-9.9-2c-9.9-2-15.6-7.6-15.6-17.1s9-17.2,22.2-17.2c11.4,0,22.7,5.8,22.9,19.5 h-10.7c-0.2-8-5.7-10.9-12.1-10.9c-6.7,0-11.9,3.6-11.9,8.6c0,4.8,2.6,7.2,8.6,8.4L482.1,45.9L482.1,45.9z" />
            <path d="M530.7,71.1h13.9v9.1H520V28.8h-10.1v-9.1H520V-0.6h10.6v20.3h13.9v9.1h-13.9L530.7,71.1L530.7,71.1z" />
            <path d="M559.5,19.7h29.1V29h-18.4v51.3h-10.7L559.5,19.7L559.5,19.7z" />
            <path d="M607.8,76.4c-6.9-5.5-10.2-14.8-10.2-26.4c0-18.5,8.8-31.7,27.2-31.7c3.6,0,7.2,0.6,10.6,1.7l4.3-8.8l6.7,3.2l-4.5,9.3 c6.9,5.5,10.2,14.8,10.2,26.3c0,18.5-8.8,31.7-27.2,31.7c-3.6,0-7.1-0.5-10.5-1.7l-4.3,8.7l-6.7-3.2L607.8,76.4z M631.4,28.1 c-2.1-0.8-4.4-1.2-6.7-1.2c-8.6,0-15.9,5.5-15.9,13.8v18.8c0,3.1,1.1,6.1,3.1,8.5L631.4,28.1L631.4,28.1z M618.3,72.1 c2.1,0.7,4.3,1.1,6.5,1.1c8.6,0,15.9-5.5,15.9-13.8V40.6c0-3.1-1.1-6-3.1-8.3L618.3,72.1L618.3,72.1z" />
            <path d="M731.2,18.2c10.6,0,18.8,6.1,18.8,21.6v40.4h-10.7V41c0-8.8-3.2-14.3-11.1-14.3c-7.5,0-13.7,7.5-13.7,18.1v35.4h-10.7V41 c0-8.8-3.2-14.3-11-14.3c-7.5,0-13.7,7.5-13.7,18.1v35.4h-10.7V19.6h10.7v10c2.6-7,9.4-11.6,16.9-11.4c8.1,0,14.7,3.9,17.3,12.5 C715.9,23.1,723.2,18,731.2,18.2z" />
            <path d="M248.8,199.2h-10.7v-80.6h10.7V199.2z M248.9,165.5l23.7-26.6h12.9l-24.3,26.6l25.9,33.8h-12.2L248.9,165.5z" />
            <path d="M320.9,200.7c-18.4,0-27.1-13.2-27.1-31.7s8.8-31.7,27.1-31.7c18.4,0,27.1,13.2,27.1,31.7 C348.1,187.5,339.3,200.7,320.9,200.7z M336.8,159.7c0-8.3-7.3-13.7-15.9-13.7s-15.9,5.4-15.9,13.7v18.7c0,8.3,7.3,13.7,15.9,13.7 s15.9-5.4,15.9-13.7V159.7z" />
            <path d="M427.1,137.4c10.5,0,18.7,6,18.7,21.5v40.3h-10.7v-39.1c0-8.8-3.2-14.2-11-14.2c-7.5,0-13.6,7.5-13.6,18v35.3h-10.7v-39.1 c0-8.8-3.2-14.2-11-14.2c-7.5,0-13.6,7.5-13.6,18v35.3h-10.7v-60.4h10.7v9.9c2.6-7,9.3-11.6,16.8-11.4c8.1,0,14.7,3.9,17.3,12.4 C411.9,142.2,419.1,137.1,427.1,137.4z" />
            <path d="M527.6,137.4c10.5,0,18.7,6,18.7,21.5v40.3h-10.7v-39.1c0-8.8-3.2-14.2-11-14.2c-7.5,0-13.6,7.5-13.6,18v35.3h-10.7v-39.1 c0-8.8-3.2-14.2-11-14.2c-7.5,0-13.6,7.5-13.6,18v35.3H465v-60.4h10.7v9.9c2.6-7,9.3-11.6,16.8-11.4c8.1,0,14.7,3.9,17.3,12.4 C512.4,142.2,519.6,137.1,527.6,137.4z" />
            <path d="M602.2,189.4c-3.3,7.1-8.5,11.4-17.7,11.4c-11.1,0-19.6-6-19.6-21.5V139h10.7v39.1c0,8.7,2.8,14.2,11.8,14.2 s14.7-7.5,14.7-18v-35.4h10.7v60.4h-10.7L602.2,189.4L602.2,189.4z" />
            <path d="M643.3,148.7c3.3-7,8.9-11.4,18-11.4c11.7,0,19.7,6.2,19.7,21.5v40.3h-10.7v-39c0-8.8-2.9-14.2-12-14.2c-8.8,0-15,7.5-15,18 v35.3h-10.6v-60.4h10.7L643.3,148.7L643.3,148.7z" />
            <path d="M723,137.4c17.3,0,25.8,12.8,25.8,28.9v5.7h-40.6v6.4c0,8.8,7.3,13.7,14.9,13.7s13.9-3.8,14.6-12.1h10.7 c-1.1,12.9-12.4,20.6-25.3,20.6c-17.4,0-26.1-13.2-26.1-31.7C696.9,150.6,706.4,137.5,723,137.4L723,137.4z M708.2,163.4h29.6v-3.3 c0-8.9-6.9-14.2-14.8-14.2s-14.8,5.3-14.8,14.2V163.4z" />
            <path
              fill="#00ADE9"
              d="M0-0.6c0.4,11.4,0.8,22.9,1.6,34.3h131.8v7.6h-14.7l-14.8,15.3H3.5c0.8,7.7,1.8,15.4,3,22.9h112.1v7.6h-14.8 l-14.7,15.3H11.2c2,7.8,4.4,15.5,7.4,22.9h85.2v7.6H89.3l-14.8,15.3H30.3c2.2,3.4,4.6,6.7,7.2,9.9c4,4.9,8.2,9.8,12.7,14.5l7.8,8.1 c7.9,7.6,16.3,14.5,25,20.9c1.9,1.4,3.9,2.8,5.9,3.9c2-1.1,4-2.5,5.9-3.9c8.7-6.4,17.2-13.2,25-20.9l7.8-8.1 c4.6-4.7,8.7-9.6,12.7-14.5c15.6-19.3,23.8-42,28.8-65.8c6.1-29.8,7.8-61.8,8.8-92.9L0-0.6L0-0.6z"
            />
            <path
              fill="#FFFFFF"
              d="M118.6,41.4h14.7v-7.6H1.6c0.5,7.7,1.1,15.3,1.9,22.9h100.4L118.6,41.4z M103.9,87.1h14.8v-7.6H6.5 c0.7,4.3,1.5,8.5,2.3,12.8c0.7,3.4,1.5,6.8,2.4,10.1h77.9L103.9,87.1z M89.4,132.9h14.5v-7.6H18.7c3.2,7.9,7.1,15.6,11.8,22.9h44.2 L89.4,132.9L89.4,132.9z"
            />
          </svg>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
