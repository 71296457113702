import "./EventList.scss";

import React, { FC, useEffect, useState } from "react";
import { IEventListProps } from "./";
import { useDispatch, useSelector } from "react-redux";

import { AppState } from "../../store";
import { frontActionCreators, FrontThunkDispatch } from "../../store/Front/front.actions";
import { Occurrence, WizardStep } from "../../interface";
import { IFrontState } from "../../store/Front/front.types";

const EventList: FC<IEventListProps> = ({ event, subEvents, occurrences }) => {
  const frontDispatch = useDispatch<FrontThunkDispatch>();
  const { filter, wizardStep } = useSelector<AppState, IFrontState>((s) => {
    return s.front;
  });

  const storeOccurrences = useSelector<AppState, any>((s) => {
    return s.front.storeOccurrences;
  });

  const isAdmin = useSelector<AppState, boolean>((s) => {
    return s.front.isAdmin;
  })

  const _ = require("lodash");
  var color = -1;

  const capitalize = (s: string) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  function randomColor() {
    var colors = ["#fad2e6", "#a0d2b4", "#f0c8a0"];
    color++;
    if (color === 3) {
      color = 0;
    }
    return colors[color];
  }

  function shownRegistrations(capacity: number, booked: number) {
    if(capacity - booked < 0) {
      return 0;
    } else {
      return capacity - booked;
    }
  }

  let [selectedOccurrences, setSelectedOccurrences] = useState<any>(storeOccurrences);
  let [occurrencesShown, setOccurrencesShown] = useState<number>(0);

  function updateSelectedOccurrences(occurrence: any, name: any) {
    const index = selectedOccurrences.findIndex(
      (e: any) => e.id === occurrence.id
    );
    if (index === -1) {
      if (
        !(selectedOccurrences.length >= event.maximumOccurrencesRegistration)
      ) {
        occurrence.eventName = name;
        setSelectedOccurrences([...selectedOccurrences, occurrence]);
      }
      return;
    }
    const newArr = [...selectedOccurrences];
    setSelectedOccurrences(newArr.filter((i) => i.id !== occurrence.id));
  }

  let listOccurrences: any = [];
  let groupOccurrences: any = [];

  useEffect(() => {
    if (
      event.shownOccurrences !== undefined &&
      event.shownOccurrences !== null &&
      event.shownOccurrences !== 0
    ) {
      setOccurrencesShown(event.shownOccurrences);
    } else {
      setOccurrencesShown(groupOccurrences.length);
    }
  }, [groupOccurrences, listOccurrences]);

  listOccurrences = Object.entries(_.groupBy(occurrences, "parentEventID"));
  subEvents.map(({ id, name, about, order, filters }) => {
    listOccurrences.map((e: any) => {
      if (e[1].some((el: any) => el.parentEventID === id)) {
        e.push({
          name: name,
          about: about,
          order: order,
          filters: filters,
        });
      }
    });
  });

  groupOccurrences = Object.entries(_.groupBy(occurrences, "name"));
  groupOccurrences.map((e: any) => {
    e[1].map((d: any) => {
      const sEvent = subEvents.find((se) => se.id === d.parentEventID);
      d["eventName"] = sEvent?.name;
      d["eventOrder"] = sEvent?.order;
      d["filters"] = sEvent?.filters;
      if (e[2] === undefined || null) {
        e.push(d.date);
      }
    });
  });

  function submitOccurrences() {
    frontDispatch(
      frontActionCreators.setFrontValue(
        ["storeOccurrences"],
        selectedOccurrences
      )
    );

    frontDispatch(
      frontActionCreators.setFrontValue(["wizardStep"], WizardStep.About)
    );
  }

  function updateFilter(selectedFilter: string) {
    if (selectedFilter === filter) {
      frontDispatch(frontActionCreators.setFrontValue(["filter"], ""));
      setSelectedOccurrences([]);
    } else {
      frontDispatch(
        frontActionCreators.setFrontValue(["filter"], selectedFilter)
      );
      if (event.groupFilters) {
        setSelectedOccurrences([]);
      }
    }
  }

  function arraymove(fromIndex: number, toIndex: number) {
    const newArr = [...selectedOccurrences];
    var element = newArr[fromIndex];
    newArr.splice(fromIndex, 1);
    newArr.splice(toIndex, 0, element);
    setSelectedOccurrences(newArr);
  }

  function isOpen(o: Occurrence) {
    if (o.closed) {
      return false;
    }
    if(!event.hasWaitingList)
    if (o.capacity - o.booked == 0) {
      return false;
    }

    if (o.isInternal && !isAdmin) {
      return false;
    }
    
    return true;
  }

  function anyOccurrencesToShow(occurrences : Occurrence[]) : boolean {
    return occurrences.filter(i => !i.closed && (i.capacity - i.booked > 0)).length > 0;
  }

  function emptySelectedOccurrences() {
    frontDispatch(frontActionCreators.setFrontValue(["storeOccurrences"], []));
    setSelectedOccurrences([]);
  }

  return (
    <>
      {event.filters !== undefined &&
        event.filters !== null &&
        event.filters.length > 0 && (
          <div className="buttonsDiv">
            <h3>{event.groupFilters ? "Velg gruppe:" : "Sorter etter:"}</h3>
            <div className="button-toggle">
              {event.filters.map((s, index) => {
                return (
                  <>
                    <input
                      type="radio"
                      className="button-toggle__radio"
                      id={`option-${index + 1}`}
                      name="option"
                      checked={s === filter}
                      onClick={() => updateFilter(s)}
                    />
                    <label
                      htmlFor={`option-${index + 1}`}
                      className="button-toggle__label"
                    >
                      {s}
                    </label>
                  </>
                );
              })}
            </div>
          </div>
        )}
      {event.dateView === undefined ||
      event.dateView === null ||
      !event.dateView ? (
        <>
          {event.groupFilters && filter === ""
            ? ""
            : listOccurrences
                .sort((c: any, d: any) => (c[2].order > d[2].order ? 1 : -1))
                .filter((s: any) =>
                  filter === "" ? s[2] : s[2].filters.includes(filter)
                )
                .map((e: any, index: number) => {
                  return (
                    <lk-toggle background={randomColor()} key={index}>
                      <span slot="title" className="padding-right--small">
                        {e[2].name}
                      </span>
                      <div className="user-info">
                        <p>{e[2].about}</p>
                        {e[1]
                          .sort(
                            (a: Occurrence, b: Occurrence) =>
                              new Date(a.date).getTime() -
                              new Date(b.date).getTime()
                          )
                          .splice(0, occurrencesShown)
                          .map((o: Occurrence, index: number) => {
                            return (
                              <>
                                {(isOpen(o) && (o.capacity - o.booked > 0) || isAdmin) ? (
                                  <label
                                    className="interactive-checkbox interactive-checkbox-trigger"
                                    htmlFor={o.id}
                                    key={index}
                                  >
                                    <input
                                      type="checkbox"
                                      id={o.id}
                                      name={o.id}
                                      value={o.id}
                                      onChange={(value) =>
                                        updateSelectedOccurrences(o, e[2].name)
                                      }
                                      checked={selectedOccurrences.includes(o)}
                                    />
                                    <span className="interactive-checkbox__span">
                                      <svg
                                        aria-hidden="true"
                                        focusable="false"
                                        role="presentation"
                                        version="1.1"
                                        id="Layer_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 27.4 27.4"
                                        xmlSpace="preserve"
                                      >
                                        <path
                                          className="interactive-checkbox__path"
                                          d="M24.6,25.5H2.7c-0.5,0-0.9-0.4-0.9-0.9V2.7c0-0.5,0.4-0.9,0.9-0.9h21.9c0.5,0,0.9,0.4,0.9,0.9v21.9
                   C25.5,25.1,25.1,25.5,24.6,25.5z"
                                        ></path>

                                        <path
                                          className="interactive-checkbox__path-checked path-checked"
                                          d="M24.6,25.5H2.7c-0.5,0-0.9-0.4-0.9-0.9V2.7c0-0.5,0.4-0.9,0.9-0.9h21.9c0.5,0,0.9,0.4,0.9,0.9v21.9
                   C25.5,25.1,25.1,25.5,24.6,25.5z"
                                        ></path>
                                        <path
                                          className="interactive-checkbox__path-checked-tick path-checked"
                                          d="M8,14.2l2.4,3.5c0.3,0.5,1,0.6,1.5,0.3c0.1-0.1,0.2-0.1,0.3-0.2L21,6.8"
                                        ></path>
                                      </svg>
                                      <div className="checkboxLabel">
                                        <div>{o.name}</div>
                                        {(!event.hasWaitingList) && (
                                          <div>
                                            {shownRegistrations(o.capacity, o.booked)}/{o.capacity}{" "}
                                            ledige plasser
                                          </div>
                                        )}
                                      </div>
                                    </span>
                                  </label>
                                ) : (
                                  <label
                                    className="interactive-checkbox interactive-checkbox-trigger disabled_text"
                                    htmlFor={o.id}
                                    key={index}
                                  >
                                    <input
                                      type="checkbox"
                                      id={o.id}
                                      name={o.id}
                                      value={o.id}
                                      checked={false}
                                    />
                                    <span className="interactive-checkbox__span">
                                      <svg
                                        aria-hidden="true"
                                        focusable="false"
                                        role="presentation"
                                        version="1.1"
                                        id="Layer_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 27.4 27.4"
                                        xmlSpace="preserve"
                                      >
                                        <path
                                          className="interactive-checkbox__path disabled_path"
                                          d="M24.6,25.5H2.7c-0.5,0-0.9-0.4-0.9-0.9V2.7c0-0.5,0.4-0.9,0.9-0.9h21.9c0.5,0,0.9,0.4,0.9,0.9v21.9
                   C25.5,25.1,25.1,25.5,24.6,25.5z"
                                        ></path>

                                        <path
                                          className="interactive-checkbox__path-checked path-checked"
                                          d="M24.6,25.5H2.7c-0.5,0-0.9-0.4-0.9-0.9V2.7c0-0.5,0.4-0.9,0.9-0.9h21.9c0.5,0,0.9,0.4,0.9,0.9v21.9
                   C25.5,25.1,25.1,25.5,24.6,25.5z"
                                        ></path>
                                        <path
                                          className="interactive-checkbox__path-checked-tick path-checked"
                                          d="M8,14.2l2.4,3.5c0.3,0.5,1,0.6,1.5,0.3c0.1-0.1,0.2-0.1,0.3-0.2L21,6.8"
                                        ></path>
                                      </svg>
                                      <div className="checkboxLabel">
                                        <div>{o.name}</div>
                                        {(!event.hasWaitingList) && (
                                          <div>
                                            {shownRegistrations(o.capacity, o.booked)}/{o.capacity}{" "}
                                            ledige plasser
                                          </div>
                                        )}
                                      </div>
                                    </span>
                                  </label>
                                )}
                              </>
                            );
                          })}
                      </div>
                    </lk-toggle>
                  );
                })}
        </>
      ) : (
        <>
          {groupOccurrences
            .sort(
              (a: any, b: any) =>
                new Date(a[2]).getTime() - new Date(b[2]).getTime()
            )
            .filter((a: any) => new Date(a[2]) > new Date())
            .splice(0, occurrencesShown)
            .map((e: any, index: number) => {
              return ( anyOccurrencesToShow(e[1]) &&
                <lk-toggle background={randomColor()} key={index}>
                  <span slot="title" className="padding-right--small">
                    {e[0]}
                  </span>
                  <div className="user-info">
                    {event.groupFilters && filter === ""
                      ? ""
                      : e[1]
                          .sort((a: any, b: any) =>
                            a.eventOrder > b.eventOrder ? 1 : -1
                          )
                          .filter((s: any) =>
                            filter === "" ? s : s.filters.includes(filter)
                          )
                          .map((o: any, index: number) => {
                            return (
                              <>
                                {(isOpen(o) && (o.capacity - o.booked > 0) || isAdmin) ? (
                                  <label
                                    className="interactive-checkbox interactive-checkbox-trigger"
                                    htmlFor={o.id}
                                    key={index}
                                  >
                                    <input
                                      type="checkbox"
                                      id={o.id}
                                      name={o.id}
                                      value={o.id}
                                      onChange={(value) =>
                                        updateSelectedOccurrences(o, e[2].name)
                                      }
                                      checked={selectedOccurrences.includes(o)}
                                    />
                                    <span className="interactive-checkbox__span">
                                      <svg
                                        aria-hidden="true"
                                        focusable="false"
                                        role="presentation"
                                        version="1.1"
                                        id="Layer_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 27.4 27.4"
                                        xmlSpace="preserve"
                                      >
                                        <path
                                          className="interactive-checkbox__path"
                                          d="M24.6,25.5H2.7c-0.5,0-0.9-0.4-0.9-0.9V2.7c0-0.5,0.4-0.9,0.9-0.9h21.9c0.5,0,0.9,0.4,0.9,0.9v21.9
                     C25.5,25.1,25.1,25.5,24.6,25.5z"
                                        ></path>

                                        <path
                                          className="interactive-checkbox__path-checked path-checked"
                                          d="M24.6,25.5H2.7c-0.5,0-0.9-0.4-0.9-0.9V2.7c0-0.5,0.4-0.9,0.9-0.9h21.9c0.5,0,0.9,0.4,0.9,0.9v21.9
                     C25.5,25.1,25.1,25.5,24.6,25.5z"
                                        ></path>
                                        <path
                                          className="interactive-checkbox__path-checked-tick path-checked"
                                          d="M8,14.2l2.4,3.5c0.3,0.5,1,0.6,1.5,0.3c0.1-0.1,0.2-0.1,0.3-0.2L21,6.8"
                                        ></path>
                                      </svg>
                                      <div className="checkboxLabel">
                                        <div>{o.eventName}</div>
                                        {(!event.hasWaitingList) && (
                                          <div>
                                            {shownRegistrations(o.capacity, o.booked)}/{o.capacity}{" "}
                                            ledige plasser
                                          </div>
                                        )}
                                      </div>
                                    </span>
                                  </label>
                                ) : (
                                  <label
                                    className="interactive-checkbox interactive-checkbox-trigger disabled_text"
                                    htmlFor={o.id}
                                    key={index}
                                  >
                                    <input
                                      type="checkbox"
                                      id={o.id}
                                      name={o.id}
                                      value={o.id}
                                      onChange={(value) =>
                                        updateSelectedOccurrences(o, e[2].name)
                                      }
                                      checked={selectedOccurrences.includes(o)}
                                    />
                                    <span className="interactive-checkbox__span">
                                      <svg
                                        aria-hidden="true"
                                        focusable="false"
                                        role="presentation"
                                        version="1.1"
                                        id="Layer_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 27.4 27.4"
                                        xmlSpace="preserve"
                                      >
                                        <path
                                          className="interactive-checkbox__path disabled_path"
                                          d="M24.6,25.5H2.7c-0.5,0-0.9-0.4-0.9-0.9V2.7c0-0.5,0.4-0.9,0.9-0.9h21.9c0.5,0,0.9,0.4,0.9,0.9v21.9
                     C25.5,25.1,25.1,25.5,24.6,25.5z"
                                        ></path>

                                        <path
                                          className="interactive-checkbox__path-checked path-checked"
                                          d="M24.6,25.5H2.7c-0.5,0-0.9-0.4-0.9-0.9V2.7c0-0.5,0.4-0.9,0.9-0.9h21.9c0.5,0,0.9,0.4,0.9,0.9v21.9
                     C25.5,25.1,25.1,25.5,24.6,25.5z"
                                        ></path>
                                        <path
                                          className="interactive-checkbox__path-checked-tick path-checked"
                                          d="M8,14.2l2.4,3.5c0.3,0.5,1,0.6,1.5,0.3c0.1-0.1,0.2-0.1,0.3-0.2L21,6.8"
                                        ></path>
                                      </svg>
                                      <div className="checkboxLabel">
                                        <div>{o.eventName}</div>
                                        {(!event.hasWaitingList) && (
                                          <div>
                                            {shownRegistrations(o.capacity, o.booked)}/{o.capacity}{" "}
                                            ledige plasser
                                          </div>
                                        )}
                                      </div>
                                    </span>
                                  </label>
                                )}
                              </>
                            );
                          })}
                  </div>
                </lk-toggle>
              );
            })}
        </>
      )}

      {selectedOccurrences.length > 0 && (
        <div className="highlighted-block yellow">
          <div className="highlighted-block__content">
            <h3>Prioritert liste av kurs du har valgt:</h3>
            <ul className="emphasised-order-list container">
              {selectedOccurrences.map((o: any, index: number) => {
                return (
                  <li className="emphasised-order-list__item" key={index}>
                    <span
                      className="emphasised-order-list__item__content"
                      style={{ width: "100%" }}
                    >
                      <div className="listItem">
                        <div>
                          <strong>
                            {o.eventName}, {o.name.toLowerCase()}
                          </strong>
                        </div>
                        {index !== 0 && (
                          <div
                            onClick={() => arraymove(index, index - 1)}
                            style={{ cursor: "pointer" }}
                          >
                            <svg
                              aria-hidden="true"
                              focusable="false"
                              enable-background="new 0 0 96 96"
                              height="20px"
                              id="arrow_up"
                              version="1.1"
                              viewBox="0 0 96 96"
                              width="20px"
                              xmlSpace="preserve"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                            >
                              <path d="M52,84V21.656l21.457,21.456c1.561,1.562,4.095,1.562,5.656,0.001c1.562-1.562,1.562-4.096,0-5.658L50.829,9.172l0,0  c-0.186-0.186-0.391-0.352-0.609-0.498c-0.101-0.067-0.21-0.114-0.315-0.172c-0.124-0.066-0.242-0.142-0.373-0.195  c-0.135-0.057-0.275-0.089-0.415-0.129c-0.111-0.033-0.216-0.076-0.331-0.099C48.527,8.027,48.264,8,48.001,8l0,0  c-0.003,0-0.006,0.001-0.009,0.001c-0.259,0.001-0.519,0.027-0.774,0.078c-0.12,0.024-0.231,0.069-0.349,0.104  c-0.133,0.039-0.268,0.069-0.397,0.123c-0.139,0.058-0.265,0.136-0.396,0.208c-0.098,0.054-0.198,0.097-0.292,0.159  c-0.221,0.146-0.427,0.314-0.614,0.501L16.889,37.456c-1.562,1.562-1.562,4.095-0.001,5.657c1.562,1.562,4.094,1.562,5.658,0  L44,21.657V84c0,2.209,1.791,4,4,4S52,86.209,52,84z" />
                            </svg>
                          </div>
                        )}
                      </div>
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
      <div className="spacebetween">
        <div>
          <button
            className="button button--secondary emptyButton"
            onClick={() => emptySelectedOccurrences()}
          >
            <span className="button__text">Tøm</span>
          </button>
          Antall valgte hendelser: {selectedOccurrences.length}
        </div>
        <button
          className="button button--primary"
          onClick={submitOccurrences}
          disabled={
            selectedOccurrences.length <=
            event.minimumOccurrencesRegistration - 1
          }
        >
          <span className="button__text">Fortsett</span>
        </button>
      </div>
    </>
  );
};

export default EventList;
