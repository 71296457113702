import React from "react";

const Footer = () => {
  return (
    <footer id="footer" className="site-footer">
      <h2 className="visually-hidden">Kontakt Lillestrøm kommune</h2>
      <div className="container">
        <div className="grid">
          <div className="span-third">
            <h3>Snakk med oss</h3>
            <p>
              Telefon:&nbsp;<a href="tel:66938000">66 93 80 00</a><span> (sentralbord)</span>
              <br />
              <a href="https://www.lillestrom.kommune.no/om-lillestrom-kommune/finn-ansatt/" target="_blank">Søk etter ansatte</a>
              <br />
              <a href="https://www.lillestrom.kommune.no/politikk-og-administrasjon/kontakt-oss/" target="_blank">Kontakt oss</a>
            </p>
            <h3>Besøk oss</h3>
            <p>Rådhuset, Jonas Lies gate 18, 2000 Lillestrøm</p>
            <h3>Servicesenteret</h3>
            <p><a href="https://www.lillestrom.kommune.no/politikk-og-administrasjon/administrasjon/servicesenteret/" target="_blank">Få hjelp av servicesenteret</a></p>
          </div>

          <div className="span-third">
            <h3>Skriv til oss</h3>
            <p><a href="https://www.lillestrom.kommune.no/politikk-og-administrasjon/kontakt-oss/" target="_blank">Kontakt oss</a></p>  
            <h3 className="site-footer__header">Postadresse</h3>
            <p>Postboks 313, 2001 Lillestrøm</p>
            <p style={{fontWeight: 'bold'}}>Organisasjonsnummer: 820 710 592</p>
            
            <p style={{fontWeight: 'bold'}}>Kommunenummer: {MUNICIPALITY_NUMBER}</p>
          </div>

          <div className="span-third">
            <h3>Om kommunen</h3>
            <ul className="site-footer__list">
              <li className="site-footer__list__item">
                <a href="https://www.lillestrom.kommune.no/om-lillestrom-kommune/jobbe-i-lillestrom/" target="_blank">Stilling ledig</a>
              </li>
              <li className="site-footer__list__item">
                <a href="https://www.lillestrom.kommune.no/personvern/informasjonskapsler/" target="_blank">Informasjonskapsler</a>
              </li>
              <li className="site-footer__list__item">
                <a href="https://www.lillestrom.kommune.no/personvern/" target="_blank">Personvern</a>
              </li>
              <li className="site-footer__list__item">
                <a href="https://uustatus.no/nb/erklaringer/publisert/b7e69778-a4b3-42da-9e75-a2e731f9c513" target="_blank">Tilgjengelighetserklæring</a>
              </li>
              
              <li className="site-footer__list__item">
                <a href="https://www.lillestrom.kommune.no/politikk-og-administrasjon/budsjett-regnskap-og-rapporter/fakturabehandling/" target="_blank">Fakturabehandling</a>
              </li>
              
              <li className="site-footer__list__item">
                <a href="https://www.lillestrom.kommune.no/politikk-og-administrasjon/innsyn/" target="_blank">Innsyn og postliste</a>
              </li>
              
              <li className="site-footer__list__item">
                <a href="https://www.lillestrom.kommune.no/om-lillestrom-kommune/" target="_blank">Om Lillestrøm kommune</a>
              </li>
              <li className="site-footer__list__item">
                <a href={`${CLIENT_BASE_URL}/salgsbetingelser/`} target="_blank">Salgsbetingelser</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container margin-top--base">
        <a
          className="site-footer__icon-link"
          href="https://twitter.com/LillestromKom"
          aria-label="lenk for twitter"
        >
          <svg
            aria-hidden="true"
            focusable="false"
            role="presentation"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 22.642 22.642"
          >
            <defs>
              <style>
                {`
            .facebook-icon {
              stroke: white;
              stroke-width: 0px;
            }`}
              </style>
            </defs>
            <path
              className="facebook-icon"
              d="M22.642,34.426v17.79a2.427,2.427,0,0,1-2.426,2.426H15.9V45.686h3.063l.44-3.417H15.9V40.086c0-.991.273-1.663,1.693-1.663h1.809V35.371a24.326,24.326,0,0,0-2.638-.136c-2.608,0-4.4,1.592-4.4,4.518v2.522H9.3v3.417h3.078v8.951H2.426A2.427,2.427,0,0,1,0,52.216V34.426A2.427,2.427,0,0,1,2.426,32h17.79A2.427,2.427,0,0,1,22.642,34.426Z"
              transform="translate(0 -32)"
              fill="currentColor"
            ></path>
          </svg>
        </a>

        <a
          className="site-footer__icon-link"
          href="https://www.facebook.com/lillestrom.kommune.no/"
          aria-label="lenk fpr facebook"
        >
          <svg
            aria-hidden="true"
            focusable="false"
            role="presentation"
            id="Regular"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <defs>
              <style>
                {`
            .twitter-icon {
              stroke-linecap: round;
              stroke-linejoin: round;
            }`}
              </style>
            </defs>
            <path
              className="twitter-icon"
              d="M23.18,6.71l-2.1-.9,1.1-2.4-2.56.85a4.47,4.47,0,0,0-3-1.2,4.51,4.51,0,0,0-4.5,4.5v1c-3.54.73-6.63-1.2-9.5-4.5q-.75,4,1.5,6L.83,9.56a4.55,4.55,0,0,0,4.25,4l-2.75,1c1,2,2.82,2.31,5.25,2.5a11.52,11.52,0,0,1-6.75,2c12.76,5.67,20.25-2.66,20.25-10V8.23Z"
            ></path>
          </svg>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
